import useSWR from "swr";
import useDebounce from "./useDebounce";
import { BASE_API_URL } from "../routes";
import api from "../api/api";

export const hotelsFetcher = ({
  url,
  params,
}: {
  url: string;
  params: any;
}) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const hotelsPostFetcher = ({
  url,
  params,
}: {
  url: string;
  params: any;
}) => {
  return api
    .post(url, params)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

// Custom hook for cities search
export function useHotelsSearch(searchQuery: string) {
  const debouncedSearch = useDebounce(searchQuery, 500);

  const { data, error, isLoading, mutate } = useSWR(
    () => {
      return debouncedSearch.length > 2 && debouncedSearch
        ? {
            url: `${BASE_API_URL}/mappings/cities`,
            params: { searchQuery: debouncedSearch },
          }
        : null;
    },
    hotelsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return {
    data: data?.data || [],
    mutate,
    isLoading,
    isError: error,
  };
}
