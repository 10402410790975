"use client";

import { createContext, useContext, useState, type ReactNode } from "react";
import type {
  IFlightSelectionPayloadContext,
  IFlightSelectionData,
} from "@/app/lib/types/interfaces";
import type { FindFlightOffersOffer } from "@/app/lib/types/codegen";
import { useNavigate } from "@tanstack/react-router";

const defaultContextValue: IFlightSelectionPayloadContext = {
  selectedFlightPayload: null,
  selectedFlightFromOfferList: null,
  handleFlightSelection: () => {},
};

const FlightSelectionPayloadContext =
  createContext<IFlightSelectionPayloadContext>(defaultContextValue);

export const FlightSelectionPayloadProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const [selectedFlightFromOfferList, setSelectedFlightFromOfferList] =
    useState<FindFlightOffersOffer | null>(null);
  const [selectedFlightPayload, setSelectedFlightPayload] =
    useState<IFlightSelectionData | null>(null);

  const handleFlightSelection = (
    dataPaylaod: IFlightSelectionData,
    itiernaryFromList: FindFlightOffersOffer,
  ) => {
    setSelectedFlightPayload(dataPaylaod);
    setSelectedFlightFromOfferList(itiernaryFromList);
    navigate({
      to: "/flights/offer/$id/step/1",
      params: { id: dataPaylaod.offerId },
    });
  };

  return (
    <>
      <FlightSelectionPayloadContext.Provider
        value={{
          selectedFlightPayload,
          handleFlightSelection,
          selectedFlightFromOfferList,
        }}
      >
        {children}
      </FlightSelectionPayloadContext.Provider>
    </>
  );
};

export const useFlightSelectionPayloadContext = () =>
  useContext(FlightSelectionPayloadContext);
