import { useState } from "react";

const useDrawerStates = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const [cancelFlightOpen, setCancelFlightOpen] = useState(false);

  return {
    drawerOpen,
    setDrawerOpen,
    moreDetailsOpen,
    setMoreDetailsOpen,
    cancelFlightOpen,
    setCancelFlightOpen,
  };
};

export default useDrawerStates;
