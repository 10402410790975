import { useState } from "react";
import { useTranslations } from "use-intl";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import {
  SwipeableDrawer,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  CaretDown,
  AirplaneTakeoff,
  AirplaneLanding,
  CaretUpDown,
  X,
} from "@phosphor-icons/react/dist/ssr";
import { Button, TextField, Puller } from "../StyledTz";
import { useFormik } from "formik";
import { TripType, CabinClass } from "@/app/lib/types/enums";
import { initialFlightsValues } from "@/app/lib/initialFlightsValues";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";
import { string, object, date, number } from "yup";
import { FLIGHT_LIST } from "@/app/lib/routes";
import {
  useFlightDetailsProvider,
  useTravellersContext,
} from "@/app/lib/context";
import clsx from "clsx";
import AirportSearch from "./AirportSearch";
import TravellersSelection from "./TravellersSelection";
import DatesSelection from "./DatesSelection";
import dayjs from "dayjs";
import { useNavigate } from "@tanstack/react-router";

enum EDrawerComponent {
  AirportSearch = "AirportSearch",
  DatesSelection = "DatesSelection",
  PassengersSelection = "PassengersSelection",
}

const cabinClassLabels = {
  [CabinClass.ECONOMY]: "economy",
  [CabinClass.BUSINESS]: "business",
  [CabinClass.PREMIUM_ECONOMY]: "premium",
  [CabinClass.FIRST_CLASS]: "firstClass",
};

type InitialFlightsValuesKeys = keyof typeof initialFlightsValues;

const validationSchema = object()
  .shape({
    dates: object().shape({
      departure: date().test(
        "departure",
        "flights.validation.dates.minDeparture",
        (d: Date | undefined): boolean => {
          if (!d) return true;
          return !dayjs(d).endOf("day").isBefore(dayjs());
        },
      ),
      return: date().optional(),
    }),
    from: object({
      name: string()
        .required("flights.validation.from")
        .max(30, "flights.validation.from"),
    }),
    to: object({
      name: string()
        .required("flights.validation.to")
        .max(30, "flights.validation.from"),
    }),
    tripType: string().required("flights.validation.tripType"),
    cabinClass: string().required("flights.validation.cabinClass"),
    // number of adults (??)
    adult: number().min(1),
    child: number().min(0),
  })
  .test({
    name: "min-return",
    message: "flights.validation.dates.minReturn",
    test: (values) => {
      if (TripType.ROUND_TRIP === values.tripType) {
        if (!values.dates.return) return true;
        return dayjs(values.dates.return).isAfter(
          dayjs(values.dates?.departure),
        );
      }
      return true;
    },
  });

function FlightCalculator() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState<string>("");
  const { locale, dir } = useLocaleInfo();

  const { updateSearchData } = useFlightDetailsProvider();
  const showSnackbar = useSnackbar();
  const t = useTranslations();

  const handleDrawerOpen = (component: EDrawerComponent) => {
    setDrawerOpen(true);
    setDrawerComponent(component);
  };

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: initialFlightsValues,
    onSubmit: (values) => {
      updateSearchData(values);
      navigate({ to: "/flights/list" });
    },
  });

  const passengerCount =
    formik.values.adult + formik.values.child + formik.values.infantWithoutSeat;

  formik.values.travellers = ` ${passengerCount > 1 ? t("persons") : t("person")} ${passengerCount}, ${t(
    `flights.cabinClass.${cabinClassLabels[formik.values.cabinClass]}`,
  )}`;

  const changeAirportValues = () => {
    formik.setFieldValue("from", formik.values.to);
    formik.setFieldValue("to", formik.values.from);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res, t)[0], 90);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const onMouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement>,
    type: InitialFlightsValuesKeys,
    component: EDrawerComponent,
  ) => {
    if (
      // TODO: Quick solution, find better solution for this post mvp
      (e.target as HTMLElement).namespaceURI === "http://www.w3.org/2000/svg"
    ) {
      e.preventDefault();
      formik.setFieldValue(type, initialFlightsValues[type]);
    } else {
      e.preventDefault();
      handleDrawerOpen(component);
    }
  };

  const departureValue = dayjs(formik.values.dates.departure).format(
    "ddd, D MMM",
  );

  const returnValue = dayjs(formik.values.dates.return).format("ddd, D MMM");

  return (
    <div className="w-full rounded-3xl p-4">
      <form noValidate onSubmit={onSubmit} acceptCharset="ISO-8859-1">
        <RadioGroup
          row
          aria-labelledby="trip-type"
          name="tripType"
          className="mb-5 flex justify-center"
          dir={dir}
          value={formik.values.tripType}
          onChange={formik.handleChange}
        >
          <FormControlLabel
            value="one-way"
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 20,
                  },
                }}
              />
            }
            label={t("flights.search.oneWay")}
            sx={{
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
          />
          <FormControlLabel
            value="round-trip"
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 20,
                  },
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: 14,
              },
            }}
            label={t("flights.search.roundTrip")}
          />
        </RadioGroup>
        <div className="mb-4 flex flex-col gap-4">
          <div className="relative flex flex-col gap-4">
            <TextField
              fullWidth
              onMouseDown={(e) => {
                onMouseDownHandler(e, "from", EDrawerComponent.AirportSearch);
              }}
              InputProps={{
                endAdornment: formik.values.from.name && (
                  <>
                    <button className="absolute left-3">
                      <X size={20} color="#6A778A" />
                    </button>
                    {!formik.values.from?.airports && (
                      <span className="text-placeholder absolute left-9 text-sm">
                        {formik.values.from.code}
                      </span>
                    )}
                  </>
                ),
              }}
              sx={{
                input: {
                  paddingLeft: "70px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  userSelect: "none",
                },
              }}
              value={formik.values.from.city}
              variant="filled"
              dir={dir}
              label={
                <div className="flex items-center gap-2">
                  <AirplaneTakeoff size={24} />
                  <span>{t("flights.search.from")}</span>
                </div>
              }
            />
            <CaretUpDown
              onClick={changeAirportValues}
              height={40}
              width={26}
              color="white"
              className={clsx(
                "absolute top-11 z-10 rounded-2xl bg-amber-500 hover:cursor-pointer",
                dir === "ltr" ? "right-5" : "left-5",
              )}
            />
            <TextField
              fullWidth
              value={formik.values.to.city}
              variant="filled"
              dir={dir}
              onMouseDown={(e) => {
                onMouseDownHandler(e, "to", EDrawerComponent.AirportSearch);
              }}
              InputProps={{
                endAdornment: formik.values.to.name && (
                  <>
                    <button className="absolute left-3 z-10">
                      <X size={20} color="#6A778A" />
                    </button>
                    {!formik.values.to?.airports && (
                      <span className="text-placeholder absolute left-9 text-sm">
                        {formik.values.to.code}
                      </span>
                    )}
                  </>
                ),
              }}
              label={
                <div className="flex items-center gap-2">
                  <AirplaneLanding size={24} />
                  <span>{t("flights.search.to")}</span>
                </div>
              }
              sx={{
                input: {
                  paddingLeft: "70px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  userSelect: "none",
                },
              }}
            />
          </div>
          <TextField
            onMouseDown={(e) => {
              onMouseDownHandler(
                e,
                "dates.departure" as InitialFlightsValuesKeys,
                EDrawerComponent.DatesSelection,
              );
            }}
            fullWidth
            variant="filled"
            label={t("flights.search.departureDate")}
            InputProps={{
              endAdornment: formik.values.dates.departure && (
                <button type="button" className="absolute left-3 z-10">
                  <X size={20} color="#6A778A" />
                </button>
              ),
            }}
            aria-label="departure-date"
            dir={dir}
            value={
              formik.values.tripType === TripType.ROUND_TRIP
                ? locale === "ar"
                  ? `${departureValue} - ${returnValue}`
                  : `${returnValue} - ${departureValue}`
                : departureValue
            }
          />
          <TextField
            fullWidth
            id="passengersNo"
            label={t("flights.search.travellers")}
            className="w-full p-0"
            dir={dir}
            InputProps={{
              endAdornment: (
                <CaretDown
                  className={clsx(
                    "absolute",
                    dir === "ltr" ? "right-5" : "left-5",
                  )}
                  color="black"
                  size={24}
                />
              ),
            }}
            variant="filled"
            value={formik.values.travellers}
            onMouseDown={(e) => {
              e.preventDefault();
              handleDrawerOpen(EDrawerComponent.PassengersSelection);
            }}
            aria-label="passengers-field"
          />
        </div>
        <Button
          fullWidth
          variant="contained"
          aria-label="search-flight"
          type="submit"
          sx={{
            borderRadius: "16px",
          }}
        >
          {t("flights.search.searchFlight")}
        </Button>
        <SwipeableDrawer
          anchor="bottom"
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          open={drawerOpen}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "32px 32px 0 0",
              height: "100vh",
              paddingBottom: "env(safe-area-inset-bottom)",
              overflow: "hidden",
            },
          }}
        >
          <Puller />
          {drawerComponent === EDrawerComponent.AirportSearch && drawerOpen && (
            <AirportSearch
              formProps={formik}
              onDrawerClose={() => setDrawerOpen(false)}
            />
          )}
          {drawerComponent === EDrawerComponent.DatesSelection &&
            drawerOpen && (
              <DatesSelection
                formProps={formik}
                onDrawerClose={() => setDrawerOpen(false)}
              />
            )}
          {drawerComponent === EDrawerComponent.PassengersSelection &&
            drawerOpen && (
              <TravellersSelection
                formProps={formik}
                onDrawerClose={() => setDrawerOpen(false)}
              />
            )}
        </SwipeableDrawer>
      </form>
    </div>
  );
}

export default FlightCalculator;
