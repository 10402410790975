import useSWR from "swr";
import api from "../api/api";
import { useState } from "react";

const fetcher = ({ url, params }: any) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

export const useReservationDocuments = (
  orderId?: string,
  interval?: number,
) => {
  const [reservationInterval, setReservationInterval] = useState<number>(
    interval || 0,
  );
  const { data, error, isLoading, mutate, isValidating } = useSWR(
    () => {
      return orderId
        ? {
            url: `/reservations/${orderId}/documents`,
          }
        : null;
    },
    fetcher,
    {
      onSuccess: (data) => {
        if (data.data.documents.length > 0) {
          setReservationInterval(0);
        }
      },
      revalidateIfStale: true,
      revalidateOnFocus: true,
      refreshInterval: reservationInterval || 0,
    },
  );

  return {
    data: data?.data,
    isLoading,
    isValidating,
    isError: error,
    mutate,
  };
};
