import { useState } from "react";
import { TFlightFormValues, THotelFormValues } from "@/app/lib/types/types";
import { FormikErrors } from "formik";
import { DateRange, DayPicker } from "react-day-picker";
import type { Matcher } from "react-day-picker";
import { TextField } from "./StyledTz";
import clsx from "clsx";
import dayjs from "dayjs";
import "react-day-picker/style.css";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

const months = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];

export type CalendarProps = {
  startField: {
    path: string;
    value: string;
    label: string;
  };
  endField?: {
    path: string;
    value: string;
    label: string;
  };
  hasRange?: boolean;
  setFieldValue: (
    field: string,
    value: string,
  ) => Promise<void | FormikErrors<THotelFormValues | TFlightFormValues | any>>;
  dropdown?: boolean;
  disabledDates?: Matcher;
};

export const Calendar = ({
  hasRange,
  startField,
  endField,
  setFieldValue,
  dropdown,
  disabledDates,
}: CalendarProps) => {
  const { dir } = useLocaleInfo();
  const [selectedDay, setSelectedDay] = useState<Date>(
    dayjs(startField.value).isValid()
      ? dayjs(startField.value).toDate()
      : dayjs().toDate(),
  );
  const [selectedRange, setSelectedRange] = useState<DateRange>({
    from: dayjs(startField.value).isValid()
      ? dayjs(startField.value).toDate()
      : undefined,
    to: dayjs(endField?.value).isValid()
      ? dayjs(endField?.value).toDate()
      : undefined,
  });

  const handleSingleDateSelection = (date: Date) => {
    setSelectedDay(date);
    setFieldValue(startField.path, dayjs(date).format("YYYY-MM-DD"));
  };

  const handleDateRangeSelection = (date: DateRange) => {
    if (selectedRange.from && selectedRange.to) {
      const selectDate = dayjs(date.from).isBefore(selectedRange.from)
        ? date.from
        : date.to;
      setSelectedRange({
        from: selectDate,
        to: undefined,
      });
    } else {
      setSelectedRange(date);
    }
    if (date.from) {
      setFieldValue(startField.path, dayjs(date.from).format("YYYY-MM-DD"));
    }
    if (date.to && endField) {
      setFieldValue(endField.path, dayjs(date.to).format("YYYY-MM-DD"));
    }
  };

  const departureValue =
    hasRange && endField?.value
      ? dayjs(selectedRange.from).format("ddd, D MMM")
      : dayjs(selectedDay).format("ddd, D MMM");

  const returnValue = dayjs(selectedRange.to).format("ddd, D MMM");

  const formatWeekdayName = (date: Date) => {
    return dayjs(date).format("ddd");
  };

  const formatMonthCaption = (date: Date) => {
    return dayjs(date).format("MMMM YYYY");
  };

  const formatMonthDropdown = (date: number) => {
    return months[date];
  };

  return (
    <div className={clsx("flex flex-col items-center justify-center")}>
      <div className="flex w-full gap-6" dir={dir}>
        <TextField
          value={departureValue}
          variant="filled"
          className="pointer-events-none w-[50%]"
          dir={dir}
          sx={{
            input: {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            },
            "& .MuiInputLabel-shrink": {
              pointerEvents: "none",
            },
          }}
          label={startField.label}
        />
        {hasRange && endField ? (
          <TextField
            value={returnValue}
            variant="filled"
            aria-readonly
            className="pointer-events-none w-[50%]"
            dir={dir}
            sx={{
              input: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              },
              "& .MuiInputLabel-shrink": {
                pointerEvents: "none",
              },
            }}
            label={endField.label}
          />
        ) : (
          <div className="w-[50%]"></div>
        )}
      </div>
      {hasRange && endField ? (
        <DayPicker
          className="mt-8"
          dir={dir}
          formatters={{
            formatWeekdayName,
            formatMonthCaption,
            formatMonthDropdown,
          }}
          startMonth={new Date(1940, 1)}
          endMonth={new Date(2050, 1)}
          captionLayout={dropdown ? "dropdown" : "label"}
          styles={{
            today: {
              boxShadow: "0 0 0 1px #CFD4DD !important",
              color: "#222930",
            },
            day_button: {
              width: "32px",
              height: "32px",
              margin: "0 8px",
            },
          }}
          classNames={{
            day: "text-primary",
            chevron: "fill-interactive",
            button_previous: "fill-interactive rotate-180 mb-4",
            button_next: "fill-interactive rotate-180 mb-4",
            month_caption: "text-interactive text-lg font-bold mb-2",
            selected: "selected-day",
          }}
          disabled={disabledDates}
          mode="range"
          selected={selectedRange}
          onSelect={handleDateRangeSelection}
          required={true}
          defaultMonth={selectedDay}
        />
      ) : (
        <DayPicker
          className="mt-8"
          dir={dir}
          mode="single"
          formatters={{
            formatWeekdayName,
            formatMonthCaption,
            formatMonthDropdown,
          }}
          startMonth={new Date(1940, 1)}
          endMonth={new Date(2050, 1)}
          captionLayout={dropdown ? "dropdown" : "label"}
          styles={{
            day_button: {
              width: "32px",
              height: "32px",
              margin: "0 8px",
            },
          }}
          defaultMonth={selectedDay}
          classNames={{
            day: "text-primary",
            chevron: "fill-interactive",
            button_previous: "fill-interactive rotate-180 mb-4",
            button_next: "fill-interactive rotate-180 mb-4",
            today: "text-primary",
            month_caption: "text-interactive text-lg font-bold mb-2",
            selected: "selected-day",
          }}
          disabled={disabledDates}
          selected={selectedDay}
          onSelect={handleSingleDateSelection}
          required={true}
        />
      )}
    </div>
  );
};
