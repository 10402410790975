import { fetcher } from "@/app/lib/fetcher";
import { BASE_API_URL } from "../routes";
import useSWRInfinite from "swr/infinite";
import {
  FindUserFlightsReservationsFlight,
  FindUserFlightsReservationsOutputData,
} from "../types/codegen";

export const mapFlightReservation = (
  flight: FindUserFlightsReservationsFlight,
) => ({
  stops: flight.stops,
  segments: flight.segments.map((segment) => ({
    duration: segment.flightDuration,
    marketingCarrier: {
      flightNumber: segment.flightNumber,
      airline: segment.marketingCarrier,
    },
    departure: {
      date: segment.origin.date,
      time: segment.origin.time,
      code: segment.origin.code,
      airport: {
        code: segment.origin.code,
        name: segment.origin.code,
      },
    },
    arrival: {
      date: segment.destination.date,
      time: segment.destination.time,
      code: segment.destination.code,
      airport: {
        code: segment.destination.code,
        name: segment.destination.code,
      },
    },
  })),
});

const PAGE_SIZE = 20;

export const useFlightsReservations = (page: number) => {
  const { data, error, isLoading, setSize, size } = useSWRInfinite<{
    data: FindUserFlightsReservationsOutputData;
  }>(
    () => ({
      url: `${BASE_API_URL}/flights/reservations`,
      params: { page, perPage: PAGE_SIZE },
    }),
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    isLoading,
    setSize,
    size,
    error,
    hasMore:
      (data?.[data.length - 1].data.reservations.length || 0) === PAGE_SIZE,
    count: data?.[0].data.count,
  };
};
