import { useState } from "react";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Check, CoinVertical, Star } from "@phosphor-icons/react";
import { HotelsSortOptions } from "@/app/lib/types/enums";
import clsx from "clsx";
import { useTranslations } from "use-intl";

function HotelsListsSort({
  sortValue,
  setSortValue,
  handleDrawerClose,
}: {
  sortValue: HotelsSortOptions;
  setSortValue: (value: HotelsSortOptions) => void;
  handleDrawerClose: () => void;
}) {
  const t = useTranslations();
  const [currentSort, setCurrentSort] = useState(sortValue);

  const handleDoneClick = () => {
    setSortValue(currentSort);
    handleDrawerClose();
  };

  const sortOptionsLabels = {
    [HotelsSortOptions.PRICE]: (
      <span className="text-primary inline-flex items-center gap-4 text-base">
        {t("hotels.filters.cheapestFirst")}
        <CoinVertical size={16} />
      </span>
    ),
    [HotelsSortOptions.RATING]: (
      <span className="text-primary inline-flex items-center gap-4 text-base">
        {t("hotels.filters.ratingFirst")}
        <Star size={16} />
      </span>
    ),
  };

  return (
    <div className="relative w-full">
      <ToggleButtonGroup
        className="flex w-full flex-col"
        exclusive
        size="small"
        color="primary"
        aria-label="sort options"
      >
        {Object.values(HotelsSortOptions).map((option) => (
          <ToggleButton
            onChange={() => setCurrentSort(option)}
            sx={{
              borderTop: "none",
              justifyContent: "flex-end",
              textTransform: "unset",
              padding: "16px",
            }}
            key={option}
            value={option}
            aria-label={option}
          >
            <div
              className={clsx(
                "flex w-full",
                option === currentSort ? "justify-between" : "justify-end",
              )}
            >
              {option === currentSort && <Check size={20} color="#0966D7" />}
              {sortOptionsLabels[option]}
            </div>
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <div className="m-4">
        <Button
          onClick={handleDoneClick}
          sx={{
            padding: "12px",
            borderRadius: "16px",
            textTransform: "unset",
            backgroundColor: "#0966D7",
          }}
          variant="contained"
          className="w-full rounded-2xl"
        >
          {t("hotels.filters.done")}
        </Button>
      </div>
    </div>
  );
}

export default HotelsListsSort;
