import { HotelBookingStepper } from "@/app/ui/hotel/HotelBookingStepper";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/$step",
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { step: currentStep } = Route.useParams();
  // FIXME: Separate this huge component into smaller components
  return <HotelBookingStepper step={Number.parseInt(currentStep, 10)} />;
}
