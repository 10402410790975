"use client";

import { MyFlightsList } from "./MyFlightsList";
import { CategorySwitcher } from "./CategorySwitcher";
import { MyHotelsList } from "./MyHotelsList";
import { useNavigate } from "@tanstack/react-router";

interface MyTripsProps {
  category: "flights" | "hotels";
  page?: number;
}

export const MyTrips = ({ category, page }: MyTripsProps) => {
  const navigate = useNavigate();
  if (category === "flights") {
    return (
      <>
        <CategorySwitcher current={category} />
        <MyFlightsList
          page={page}
          onReservationClick={(reservation) => {
            navigate({
              to: "/trips/$scope/$id",
              params: { scope: "flights", id: reservation.id },
            });
          }}
        />
      </>
    );
  }

  if (category === "hotels") {
    return (
      <>
        <CategorySwitcher current={category} />
        <MyHotelsList
          onReservationClick={(reservation) => {
            navigate({
              to: "/trips/$scope/$id",
              params: { scope: "hotels", id: reservation.id },
            });
          }}
        />
      </>
    );
  }
  return null;
};
