import { Button, SwipeableDrawer } from "@mui/material";
import { Puller } from "../StyledTz";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

function CancelFlightPurchase({
  open,
  closeDrawer,
}: {
  open: boolean;
  closeDrawer: () => void;
}) {
  const t = useTranslations();
  const navigate = useNavigate();
  return (
    <SwipeableDrawer
      anchor="bottom"
      onClose={closeDrawer}
      onOpen={() => {}}
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "32px 32px 0 0",
          height: "40vh",
          paddingBottom: "env(safe-area-inset-bottom)",
          zIndex: 99,
        },
      }}
    >
      <Puller />
      <div className="mt-6 flex h-full w-full flex-col items-center px-4">
        <h5 className="text-title mb-3 text-base font-medium">
          {t("flights.cancelTicketPurchase")}
        </h5>
        <span className="text-primary text-center text-sm font-normal">
          {t("flights.cancelTicketPurchaseDesc")}
        </span>
        <div className="mt-auto mb-5 flex w-full flex-col gap-2">
          <Button
            onClick={() => {
              navigate({ to: "/", search: { category: "flights" } });
            }}
            sx={{
              textTransform: "none",
              backgroundColor: "#FF6661 !important",
              fontWeight: "600",
              boxShadow: "none",
              width: "100%",
              borderRadius: "12px",
              padding: "12px 24px",
            }}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={closeDrawer}
            sx={{
              textTransform: "none",
              color: "#222930",
              fontWeight: "600",
              boxShadow: "none",
              width: "100%",
              borderRadius: "12px",
              padding: "12px 24px",
            }}
            variant="text"
          >
            {t("back")}
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default CancelFlightPurchase;
