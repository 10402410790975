import { getTravelerType } from "@/app/lib/utils/transformTraveler";
import { useTranslations } from "use-intl";

export function TravelerTypeLabel(props: { birthDate: string }) {
  const type = getTravelerType(props);
  const t = useTranslations();

  if (type === "adult") {
    return t("travellers.adult");
  }

  if (type === "child") {
    return t("travellers.child");
  }

  return t("travellers.infant");
}
