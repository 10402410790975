import Footer from "@/app/ui/Footer";
import { MyTrips } from "@/app/ui/my-trips/MyTrips";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { motion } from "motion/react";

export const Route = createFileRoute("/_authenticated/trips")({
  validateSearch: (
    search: Record<string, unknown>,
  ): { category?: "flights" | "hotels" } => {
    return {
      category: search.category as "flights" | "hotels",
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const searchParams = Route.useSearch();
  return (
    <>
      <motion.div
        layoutId="hero"
        className={clsx(
          "bg-hero-pattern absolute top-0 h-6 w-full rounded-b-2xl bg-cover bg-no-repeat",
        )}
      />
      <main className="flex h-screen w-full flex-col overflow-auto bg-slate-100 pb-14">
        <div className="mt-6 w-full bg-slate-100 p-4">
          <MyTrips category={searchParams.category ?? "flights"} />
        </div>
        <Footer />
      </main>
    </>
  );
}
