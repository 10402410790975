import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Fragment } from "react";
import { useTranslations } from "use-intl";

import { useHotelRoomAvailaility } from "@/app/lib/hooks/useHotelRoomAvailaility";
import type { THotelRoom, THotelSearchResult } from "@/app/lib/types/types";

import { HotelRoomTitle } from "./HotelRoomTitle";
import type { THotelBookingData } from "./HotelBookingStepper";
import { useNavigate } from "@tanstack/react-router";

interface HotelRoomSelectionProps {
  hotel: THotelSearchResult;
  offerData: any;
  hotelBookingData: THotelBookingData;
  setHotelBookingData: (arg: THotelBookingData) => void;
  onRoomGalleryClick: (room: THotelRoom) => void;
}

export const HotelRoomSelection = ({
  hotel,
  offerData,
  hotelBookingData,
  setHotelBookingData,
}: HotelRoomSelectionProps) => {
  useHotelRoomAvailaility(
    hotelBookingData.offerId,
    hotelBookingData.packageToken,
    hotelBookingData.rooms.map((room) => room.roomToken),
    `${hotelBookingData.hotelId}`,
  );

  const navigate = useNavigate();
  const t = useTranslations();

  return (
    <div className="flex w-full flex-col p-4">
      {offerData?.data?.map((offer: any, offerIndex: number) => (
        <Fragment key={offerIndex}>
          <div className="text-tertiary mb-1 flex flex-row-reverse text-sm">
            {t("hotels.search.option")} #{offerIndex + 1}
          </div>
          {offer.rooms.map((room: any, index: number) => (
            <div
              className="mb-2 flex flex-col justify-between gap-4 rounded-2xl bg-white p-2"
              key={index}
            >
              <div className="flex flex-row-reverse">
                <div className="text-tertiary text-sm">
                  {t("hotels.search.room")} {index + 1} (
                  {t("hotels.search.selectOne")})
                </div>
              </div>
              <FormControl>
                <RadioGroup
                  dir="rtl"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={hotelBookingData.rooms}
                  onChange={(e) => {
                    const isCurrentOffer =
                      offer.offerId === hotelBookingData.offerId;
                    const rooms = isCurrentOffer ? hotelBookingData.rooms : [];
                    const target = JSON.parse(e.target.value);
                    rooms.splice(isCurrentOffer ? index : 0, 1, target);

                    setHotelBookingData({
                      ...hotelBookingData,
                      rooms,
                      offerId: offer.offerId,
                      offerPrice: rooms.reduce(
                        (acc, room) => acc + room.price.value,
                        0,
                      ),
                      packageToken: offer.packageToken,
                    });
                  }}
                >
                  {room.options.map(
                    (option: {
                      name: string;
                      roomToken: string;
                      board: string;
                      price: {
                        value: string;
                        currency: string;
                      };
                    }) => (
                      <div
                        className="mb-2 rounded-2xl bg-[#F3F4F7] py-4 pr-1 pl-2"
                        key={option.roomToken}
                      >
                        <div className="flex flex-row-reverse justify-between gap-4">
                          <button
                            type="button"
                            className="relative h-[48px] min-h-[48px] w-[60px]"
                            onClick={() => {
                              navigate({
                                to: "/hotels/$id/images",
                                params: { id: `${hotel.id}` },
                              });
                            }}
                          >
                            {hotel.mainImageUrl && (
                              <img
                                src={hotel.mainImageUrl}
                                alt={hotel.name}
                                width={60}
                                height={48}
                                style={{
                                  minHeight: "48px",
                                  minWidth: "60px",
                                  borderRadius: "8px",
                                  zIndex: 10,
                                }}
                              />
                            )}
                            <div className="absolute bottom-0 left-0 z-1 h-[17px] w-[36px] rounded-bl-lg bg-black">
                              <div className="flex items-center justify-center">
                                <div className="text-xs text-white">
                                  {hotel.imagesUrls.length}+
                                </div>
                                <img
                                  src={"/image.svg"}
                                  width={9}
                                  height={9}
                                  alt="image"
                                  style={{
                                    marginRight: "1px",
                                  }}
                                />
                              </div>
                            </div>
                          </button>
                          <FormControlLabel
                            key={option.roomToken}
                            name={option.roomToken}
                            value={JSON.stringify(option)}
                            control={<Radio />}
                            disabled={
                              offer.offerId !== hotelBookingData.offerId &&
                              index > 0
                            }
                            checked={
                              option.roomToken ===
                              hotelBookingData.rooms[index]?.roomToken
                            }
                            label={
                              <div>
                                <HotelRoomTitle
                                  name={option.name}
                                  board={option.board}
                                  occupancy={room.occupancy}
                                />
                                {option?.price && (
                                  <div className="text-primary mt-2">
                                    {option.price.value} {option.price.currency}
                                  </div>
                                )}
                              </div>
                            }
                            sx={{
                              m: 0,
                              "& .MuiTypography-root": {
                                fontSize: 14,
                                color: "#111317",
                                pr: 1,
                              },
                              "& .MuiSvgIcon-root": {
                                pl: "5px",
                              },
                            }}
                          />
                        </div>
                      </div>
                    ),
                  )}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
};
