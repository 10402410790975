import { useState } from "react";

import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import { TRoomOutput } from "@/app/lib/types/types";
import { useTranslations } from "use-intl";

export const HotelRoomTravelers = ({
  setRooms,
  rooms,
  roomIndex,
  availableTravelers,
}: {
  setRooms: ([]) => void;
  rooms: TRoomOutput[];
  roomIndex: number;
  availableTravelers: FindUserTravelersOutputData[];
}) => {
  const [roomTravellers, assignRoomTravellers] = useState([]);
  const t = useTranslations();

  const handleRoomTravellers = (roomIndex: number) => (e: any) => {
    const {
      target: { value },
    } = e;

    const roomsArr = rooms.map((room, index) => ({
      ...room,
      travelers:
        roomIndex === index
          ? value.map((person: string) => {
              return JSON.parse(person) as FindUserTravelersOutputData;
            })
          : room.travelers,
    }));

    assignRoomTravellers(typeof value === "string" ? value.split(",") : value);
    setRooms(roomsArr);
  };

  const assignedTravelers = rooms.flatMap((r) =>
    r.travelers.length ? r.travelers.map((tr) => tr.id) : [],
  );

  const renderValue = (selected: string[]) => {
    return selected
      .map((p) => `${JSON.parse(p).firstName} ${JSON.parse(p).lastName}`)
      .join(", ");
  };

  return (
    <div className="mb-2 flex w-full">
      <FormControl size="small" className="w-full">
        <InputLabel id="assign-travellers-small-label">
          {t("reservations.assignRoom")}
        </InputLabel>
        <Select
          multiple
          className="w-full"
          labelId="assign-travellers-small-label"
          id="assign-travellers-small"
          value={roomTravellers as unknown as string[]}
          label={t("reservations.assignRoom")}
          onChange={handleRoomTravellers(roomIndex)}
          renderValue={renderValue}
          sx={{ borderRadius: "12px" }}
          required
        >
          {availableTravelers.map((traveller) => (
            <MenuItem
              key={traveller.id}
              value={JSON.stringify(traveller)}
              disabled={
                assignedTravelers.includes(traveller.id) &&
                !rooms[roomIndex].travelers.some((tr) => traveller.id === tr.id)
              }
            >
              <Checkbox checked={assignedTravelers.includes(traveller.id)} />
              {traveller.firstName} {traveller.lastName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
