import { Fragment, useState } from "react";
import { capitalizeHotelNames } from "@/app/lib/capitalizeHotelNames";
import { Star } from "@phosphor-icons/react/dist/ssr";
import { Button, Checkbox, Divider, Drawer } from "@mui/material";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { getAgeFromDateOfBirth } from "@/app/lib/getAgefromDateOfBirth";
import { useHotelBooking } from "@/app/lib/hooks/useHotelBooking";
import Loader from "../AnimatedLoader";
import PageLoader from "../PageLoader";
import { THotelSearchResult, TRoomOutput } from "@/app/lib/types/types";
import { THotelBookingData } from "./HotelBookingStepper";
import { useTranslations } from "use-intl";
import { boardIcon } from "../hotels-list/HotelCard";
import { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import { transformHotelTraveler } from "@/app/lib/utils/transformTraveler";
import MoreDetailsDrawer from "../flight-purchase/MoreDetailsDrawer";
import { HotelRoomTravelers } from "./HotelRoomTravelers";
import BookingError from "../BookingError";
import { HotelRoom } from "./HotelRoom";
import { CaretLeft } from "@phosphor-icons/react";
import clsx from "clsx";

export const HotelBookingSummary = ({
  hotel,
  hotelBookingData,
  setStep,
  setOrderId,
  setIsBooking,
}: {
  hotel: THotelSearchResult;
  hotelBookingData: THotelBookingData;
  setStep: (arg: number) => void;
  setOrderId: (arg?: number) => void;
  setIsBooking: (arg: boolean) => void;
}) => {
  const t = useTranslations();
  const {
    getSelectedTravellers,
    selectedTravellers,
    handleLeadTraveller,
    leadTraveller,
  } = useTravellersContext();
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [rooms, setRooms] = useState<TRoomOutput[]>(() =>
    hotelBookingData.rooms.map((room) => ({
      packageRoomToken: room.roomToken,
      travelers: [] as FindUserTravelersOutputData[],
    })),
  );

  const { isPending, isError, error, mutateAsync } = useHotelBooking();

  const assignedTravelers = rooms.flatMap((r) =>
    r.travelers.length ? r.travelers.map((tr) => tr.id) : [],
  );

  if (isError) return <BookingError error={error} type="hotels" />;
  if (isPending)
    return (
      <div className="my-20">
        <PageLoader>
          <Loader icon="buildings" />
        </PageLoader>
      </div>
    );

  return (
    <div className="flex w-full flex-col px-4">
      <div className="mb-4 flex flex-row-reverse text-base">
        {t("hotels.bookingSummary")}
      </div>
      <div className="text-tertiary mb-2 flex flex-row-reverse text-sm">
        {t("hotels.overview.hotelDetails")}
      </div>
      <div className="mb-2 flex justify-end gap-4 rounded-2xl bg-white p-4 text-end">
        <div className="flex flex-col gap-2">
          <span className="font-semibol text-title text-end">
            {capitalizeHotelNames(hotel.name)}
          </span>
          {!!hotel.stars && (
            <div className="flex items-center justify-end gap-2">
              <div className="flex">
                {Array.from(Array(Number(hotel.stars))).map((_, index) => (
                  <Star key={index} size={12} color="#EF9C43" weight="fill" />
                ))}
              </div>
              <span className="text-tertiary text-sm">
                {t("hotels.card.stars", { stars: hotel.stars })}
              </span>
            </div>
          )}
          {hotel?.boardBasis?.map((bb) => (
            <div key={bb} className="flex items-center justify-end">
              <div className="text-tertiary flex items-center gap-2 text-sm">
                {t(`hotels.boardBasis.${bb}`)}
                {boardIcon[bb]}
              </div>
            </div>
          ))}
        </div>
        <div>
          {hotel.mainImageUrl && (
            <div className="flex-end flex w-full">
              <img
                className="min-h-32 min-w-24 rounded-2xl object-cover"
                width="90"
                height="130"
                src={hotel.mainImageUrl}
                alt={hotel.name}
              />
            </div>
          )}
        </div>
      </div>
      <div className="text-tertiary mb-2 flex flex-row-reverse text-sm">
        {t("hotels.roomDetails")}
      </div>
      <div className="text-primary mb-4 flex flex-col justify-end gap-4 rounded-2xl bg-white p-4 text-end">
        {hotelBookingData.rooms.map((room, roomIndex) => (
          <div className="flex w-full flex-col" key={room.roomToken}>
            <HotelRoom
              room={room}
              hotel={hotel}
              visitorsCount={getSelectedTravellers.length}
            />
            <HotelRoomTravelers
              setRooms={setRooms}
              rooms={rooms}
              roomIndex={roomIndex}
              availableTravelers={getSelectedTravellers}
            />
            <Divider />
          </div>
        ))}
      </div>

      <div className="text-tertiary mb-2 flex flex-row-reverse text-sm">
        {t("hotels.markMainTraveller")}
      </div>
      <div className="text-primary mb-2 flex flex-col justify-end rounded-2xl bg-white p-4 text-end">
        {getSelectedTravellers.map((traveller) => (
          <Fragment key={traveller.passportNumber}>
            <div className="flex items-start justify-between gap-2">
              {getAgeFromDateOfBirth(traveller.birthDate) < 12 ? (
                <div />
              ) : (
                <label
                  className={clsx(
                    "main-traveller-form-control flex-shrink-0",
                    traveller.id === leadTraveller?.id && "checked",
                  )}
                >
                  <input
                    className="main-traveller-checkbox"
                    onChange={() => handleLeadTraveller(traveller)}
                    type="radio"
                    disabled={getAgeFromDateOfBirth(traveller.birthDate) < 12}
                    value={traveller.id}
                    checked={traveller.id === leadTraveller?.id}
                  />
                  {traveller.id === leadTraveller?.id
                    ? t("travellers.markedMainTraveller")
                    : t("travellers.mainTraveller")}
                </label>
              )}
              <div>
                {traveller.firstName} {traveller.lastName}
              </div>
            </div>

            <div className="text-tertiary mb-4 text-xs">
              {traveller.birthDate && (
                <>
                  {t("hotels.overview.age")}{" "}
                  {getAgeFromDateOfBirth(traveller.birthDate)}{" "}
                </>
              )}
            </div>
          </Fragment>
        ))}
      </div>
      <div className="mb-2 flex flex-col justify-end gap-4 rounded-2xl bg-white p-4 text-end">
        <div className="text-primary text-[14px] font-semibold">
          {t("hotels.markMainTraveller")}
        </div>
        <div className="text-title text-[14px]">
          {t("hotels.bookingPolicy")}
        </div>
      </div>
      <div className="text-tertiary mb-2 flex flex-row-reverse text-sm">
        {t("summary")}
      </div>
      <div className="mb-2 flex flex-col justify-end rounded-2xl bg-white p-4 text-end">
        <div className="flex justify-end">
          <div className="flex flex-col content-start">
            <Button
              onClick={() => setTermsAndConditions(!termsAndConditions)}
              className="flex items-start p-0 text-right"
            >
              <div className="flex flex-col">
                <div className="text-primary text-[14px] font-semibold">
                  {t("hotels.acceptGeneralTerms")}
                </div>
                <div className="text-tertiary text-[14px]">
                  {t("hotels.acceptGeneralTermsWarning")}
                </div>
              </div>
              <Checkbox sx={{ pl: 1, pr: 0 }} checked={termsAndConditions} />
            </Button>
            <Button
              variant="text"
              onClick={() => setMoreDetailsOpen(true)}
              sx={{
                textTransform: "none",
                padding: 0,
                marginRight: "32px",
                textDecoration: "underline",
                justifyContent: "end",
              }}
            >
              {t("moreDetails")}
            </Button>
          </div>
        </div>
      </div>

      <div className="mb-2 flex flex-col justify-end gap-4 rounded-2xl bg-white p-4 text-end">
        <div className="flex flex-row-reverse justify-between">
          <div className="text-tertiary text-sm">{t("total")}</div>
          <div className="text-primary text-sm font-semibold">
            {hotelBookingData?.offerPrice} {hotel?.minPrice?.currency}
          </div>
        </div>
        <div className="flex flex-row-reverse justify-between">
          <div className="text-tertiary text-sm">{t("paymentMethod")}</div>
          <div className="text-primary flex gap-2 text-sm font-semibold">
            {t("qiCard")}
          </div>
        </div>
      </div>

      <Drawer
        anchor="bottom"
        variant="persistent"
        open={true}
        sx={{
          "& .MuiDrawer-paper": {
            height: "128px",
            padding: "16px",
            borderRadius: "32px 32px 0 0",
          },
        }}
      >
        <div className="flex items-center justify-between">
          <span className="text-md text-primary flex items-center font-semibold">
            <CaretLeft color="#6A778A" size={16} />
            {hotelBookingData?.offerPrice} {hotel.minPrice?.currency}
          </span>
          <h5 className="text-primary text-sm font-semibold">
            {t("hotels.totalPrice")}
          </h5>
        </div>
        <Button
          sx={{
            padding: "12px",
            borderRadius: "12px",
            textTransform: "unset",
            backgroundColor: "#0966D7",
            marginBottom: "8px",
          }}
          variant="contained"
          className="w-full"
          onClick={() => {
            setIsBooking(true);

            mutateAsync({
              offerId: hotelBookingData.offerId,
              hotelId: hotelBookingData.hotelId,
              rooms: rooms.map((room) => ({
                ...room,
                travelers: room.travelers.map((traveler) =>
                  transformHotelTraveler(traveler, {
                    isLead: traveler.id === leadTraveller?.id,
                  }),
                ),
              })),
            }).then((data) => {
              const orderId = data?.data?.data?.id;
              if (orderId) {
                setOrderId(orderId);
                setStep(5);
              }

              setIsBooking(false);
            });
          }}
          disabled={
            !termsAndConditions ||
            !leadTraveller?.id ||
            (leadTraveller?.id &&
              !assignedTravelers.includes(leadTraveller.id)) ||
            selectedTravellers.length !== assignedTravelers.length
          }
        >
          {t("hotels.overview.bookRoom")}
        </Button>
      </Drawer>

      <MoreDetailsDrawer
        open={moreDetailsOpen}
        onClose={() => setMoreDetailsOpen(false)}
      />
    </div>
  );
};
