import React, { type ReactNode } from "react";
import { Box, Card } from "@mui/material";
import { useTranslations } from "use-intl";
import type { Hotel } from "@/app/lib/types/types";
import {
  Bed,
  Lockers,
  Star,
  Hamburger,
  ListBullets,
  ListChecks,
  Swap,
  Swatches,
} from "@phosphor-icons/react";
import { capitalizeHotelNames } from "@/app/lib/capitalizeHotelNames";

export const boardIcon: Record<string, JSX.Element> = {
  RO: <Bed size={18} fill="#6A778A" />,
  SC: <Hamburger size={18} fill="#6A778A" />,
  BB: <Swap size={18} fill="#6A778A" />,
  HB: <ListBullets size={18} fill="#6A778A" />,
  FB: <ListChecks size={18} fill="#6A778A" />,
  AI: <Swatches size={18} fill="#6A778A" />,
  RD: <Lockers size={18} fill="#6A778A" />,
};

export const HotelCard = ({
  hotel,
  onClick,
  additionalInfo,
  isSuccessScreen,
}: {
  hotel: Hotel;
  additionalInfo?: ReactNode;
  onClick?: () => void;
  isSuccessScreen?: boolean;
}) => {
  const t = useTranslations();

  return (
    <Card
      sx={{
        minHeight: "228px",
        mb: "8px",
        boxShadow: "none",
        borderRadius: "24px",
        p: "13px 16px",
      }}
      onClick={onClick}
    >
      <div className="flex justify-between gap-4 rounded-2xl bg-white text-end hover:cursor-pointer">
        <div className="flex w-full flex-col gap-2">
          <span className="text-title text-end font-semibold">
            {capitalizeHotelNames(hotel.name)}
          </span>

          {!!hotel.stars && (
            <div className="flex items-center justify-end gap-2">
              <span className="text-tertiary text-sm">
                {t("hotels.card.stars", { stars: hotel.stars })}
              </span>
              <div className="flex">
                {Array.from(Array(Number(hotel.stars))).map((_, index) => (
                  <Star key={index} size={12} color="#EF9C43" weight="fill" />
                ))}
              </div>
            </div>
          )}
          {hotel.specialDeal && (
            <div className="flex items-center justify-end">
              <span className="gap-2 rounded-2xl bg-[#FBE6D0] px-3 text-sm">
                {t("hotels.card.specialDeal")}
              </span>
            </div>
          )}
          {hotel.recommended && (
            <div className="flex items-center justify-end">
              <span className="gap-2 rounded-2xl bg-[#94E0D8] px-3 text-sm">
                {t("hotels.card.recommended")}
              </span>
            </div>
          )}
          {!!hotel.boardBasis?.length &&
            hotel.boardBasis.map((bb) => (
              <div key={bb} className="flex items-center justify-end">
                <div className="text-tertiary flex items-center gap-2 text-sm">
                  {t(`hotels.boardBasis.${bb}`)}
                  {boardIcon[bb]}
                </div>
              </div>
            ))}
          {hotel.minPrice ? (
            <>
              <hr />
              <div className="flex flex-col justify-start">
                <div className="flex flex-row items-center justify-start">
                  <span className="text-title py-1 text-xl" dir="rtl">
                    {hotel.minPrice.value.toLocaleString()}{" "}
                    {hotel.minPrice.currency}
                  </span>
                  {!isSuccessScreen && (
                    <span className="text-tertiary px-1 text-sm">
                      {t("hotels.card.from")}
                    </span>
                  )}
                </div>
              </div>
            </>
          ) : null}
          {additionalInfo}
        </div>
        <div className="flex justify-end">
          {hotel.mainImageUrl ? (
            <div className="flex-end flex w-full">
              <img
                className="rounded-2xl"
                width="90"
                height="180"
                src={hotel.mainImageUrl}
                alt={hotel.name}
                style={{
                  objectFit: "cover",
                  maxHeight: "180px",
                  maxWidth: "90px",
                }}
              />
            </div>
          ) : (
            <Box //temporary placeholder for hotel images
              sx={{
                bgcolor: "grey.200",
                width: 90,
                height: 180,
                borderRadius: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="flex h-full flex-wrap items-center justify-center">
                <div className="text-tertiary text-center text-xs">
                  {t("noImage")}
                </div>
              </div>
            </Box>
          )}
        </div>
      </div>
    </Card>
  );
};
