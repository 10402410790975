import { Card, Avatar, Chip } from "@mui/material";
import { useTranslations } from "use-intl";
import dayjs from "dayjs";
import { calculateOverallFlightTime } from "@/app/lib/utils/calculateFlightTime";
import { extractAirlineCarriers } from "@/app/lib/utils/extractAirlineCarriers";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";
import { FlightDetails } from "../SingleAvailableFlight";

function FlightSummary({
  selectedFlight,
}: {
  selectedFlight: SingleFightDetailsData;
}) {
  const t = useTranslations();
  const departureFlight = selectedFlight?.flights?.[0] || null;
  const departureSegments = departureFlight?.segments || [];
  const departureStops = departureFlight?.stops || [];
  const departureOverallTime = calculateOverallFlightTime(departureFlight);

  const returnFlight =
    ((selectedFlight?.flights?.length || 0) > 1 &&
      selectedFlight?.flights?.[selectedFlight?.flights.length - 1]) ||
    null;
  const returnSegments = returnFlight?.segments || [];
  const returnStops = returnFlight?.stops || [];

  const departureAirlines = extractAirlineCarriers(departureSegments);
  const returnAirlines = extractAirlineCarriers(returnSegments);
  const returnOverallTime = calculateOverallFlightTime(returnFlight);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full justify-end">
        <span className="text-title text-base font-medium">
          {t("flights.flightSummary")}
        </span>
      </div>
      <div className="mt-6 flex flex-col gap-2">
        <span className="text-tertiary text-end text-xs">
          {t("flights.flightDetails")}
        </span>
        <Card
          className="flex flex-col gap-4 rounded-2xl bg-white p-4 hover:cursor-pointer"
          sx={{
            boxShadow: "none",
            borderRadius: "16px",
          }}
        >
          <div className="flex w-full items-center justify-end gap-2">
            <div className="flex flex-row-reverse">
              {departureAirlines?.length &&
                [...departureAirlines]?.splice(0, 2).map((airline, index) => (
                  <span
                    key={airline.name || index}
                    className="text-primary text-sm"
                  >
                    {airline.name || t("unknownAirline")}
                    {index !== 0 ? " ," : ""}
                  </span>
                ))}
              {departureAirlines?.length - 2 > 0 && (
                <span className="text-primary mr-1 text-sm">
                  +{departureAirlines.length - 2}
                </span>
              )}
            </div>
            <div className="flex w-fit flex-row-reverse">
              {departureAirlines?.map((airline, index) => (
                <Avatar
                  alt="airplane company logo"
                  sx={{
                    width: 24,
                    height: 24,
                    marginRight: index !== 0 ? "-8px" : 0,
                    zIndex: departureAirlines.length - index,
                  }}
                  src={airline.logoUrl}
                  key={airline.name}
                >
                  {airline.name?.split("")[0]}
                </Avatar>
              ))}
            </div>
          </div>
          <div className="flex items-end justify-between" dir="rtl">
            <div className="flex flex-col">
              <span className="text-tertiary text-sm">
                {dayjs(departureSegments[0].departure.date).format("MMMM DD")}
              </span>
              <h3 className="text-title text-xl font-semibold">
                {departureSegments[0].departure.time}
              </h3>
              <span className="text-tertiary text-sm">
                {departureSegments[0].departure.airport.code}
              </span>
            </div>

            <FlightDetails
              overallTime={departureOverallTime}
              stops={departureStops?.length}
            />

            <div className="flex flex-col">
              <span className="text-tertiary text-end text-sm">
                {dayjs(
                  departureSegments[departureSegments.length - 1].arrival.date,
                ).format("MMMM DD")}
              </span>
              <h3 className="text-title text-end text-xl font-semibold">
                {departureSegments[departureSegments.length - 1].arrival.time}
              </h3>
              <span className="text-tertiary text-end text-sm">
                {
                  departureSegments[departureSegments.length - 1].arrival
                    .airport.code
                }
              </span>
            </div>
          </div>
        </Card>
        {/* Return flight if its round trip */}
        {returnFlight && (
          <Card
            className="flex flex-col gap-4 rounded-2xl bg-white p-4 hover:cursor-pointer"
            sx={{
              boxShadow: "none",
              borderRadius: "16px",
            }}
          >
            <div className="flex w-full items-center justify-end gap-2">
              <div className="flex flex-row-reverse">
                {returnAirlines?.length &&
                  [...returnAirlines]?.splice(0, 2).map((airline, index) => (
                    <span
                      key={airline.name || index}
                      className="text-primary text-sm"
                    >
                      {airline.name || t("unknownAirline")}
                      {index !== 0 ? " ," : ""}
                    </span>
                  ))}
                {returnAirlines?.length - 2 > 0 && (
                  <span className="text-primary mr-1 text-sm">
                    +{returnAirlines.length - 2}
                  </span>
                )}
              </div>
              <div className="flex w-fit flex-row-reverse">
                {returnAirlines?.map((airline, index) => (
                  <Avatar
                    alt="airplane company logo"
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: index !== 0 ? "-8px" : 0,
                      zIndex: returnAirlines.length - index,
                    }}
                    src={airline.logoUrl}
                    key={airline.name}
                  >
                    {airline.name?.split("")[0]}
                  </Avatar>
                ))}
              </div>
            </div>
            <div className="flex items-end justify-between">
              <div className="flex flex-col">
                <span className="text-tertiary text-end text-sm">
                  {dayjs(returnSegments[0].departure.date).format("MMMM DD")}
                </span>
                <h3 className="text-title text-xl font-semibold">
                  {returnSegments[0].departure.time}
                </h3>
                <span className="text-tertiary text-sm">
                  {returnSegments[0].departure.airport.code}
                </span>
              </div>
              <div className="flex flex-col items-center gap-1">
                <div className="relative">
                  <Chip
                    className="bg-accent-700 relative z-10 px-2 text-xs font-normal text-white"
                    label={returnOverallTime}
                    color="primary"
                    size="small"
                  />
                  <div
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    style={{
                      height: "1.5px",
                      width: "161px",
                      background: "#1271F8",
                    }}
                  ></div>
                </div>
                <span className="text-tertiary text-sm">
                  {returnStops?.length
                    ? `${
                        returnStops.length == 1
                          ? returnStops.length + " Stop"
                          : returnStops.length + " Stops"
                      }`
                    : "Direct"}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-tertiary text-end text-sm">
                  {dayjs(
                    returnSegments[returnSegments.length - 1].arrival.date,
                  ).format("MMMM DD")}
                </span>
                <h3 className="text-title text-end text-xl font-semibold">
                  {returnSegments[returnSegments.length - 1].arrival.time}
                </h3>
                <span className="text-tertiary text-end text-sm">
                  {
                    returnSegments[returnSegments.length - 1].arrival.airport
                      .code
                  }
                </span>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}

export default FlightSummary;
