import dayjs from "dayjs";
import {
  BookFlightOfferTraveler,
  BookHotelOfferTraveler,
  FindUserTravelersOutputData,
} from "../types/codegen";
import { getAgeFromDateOfBirth } from "../getAgefromDateOfBirth";

function getTravelerPassengerTypeCode(
  traveler: Pick<FindUserTravelersOutputData, "birthDate">,
) {
  const age = getAgeFromDateOfBirth(traveler.birthDate);
  if (age > 12) {
    return "ADT";
  }
  if (age < 12 && age > 2) {
    return "CHD";
  }
  return "INF";
}

export function getTravelerType(
  traveler: Pick<FindUserTravelersOutputData, "birthDate">,
) {
  const code = getTravelerPassengerTypeCode(traveler);

  if (code === "ADT") {
    return "adult";
  }

  if (code === "CHD") {
    return "child";
  }

  return "infant";
}

export function transformFlightTraveler(
  traveler: FindUserTravelersOutputData,
  { isLead }: { isLead?: boolean } = {},
): BookFlightOfferTraveler {
  return {
    ...traveler,
    passportExpiryDate: dayjs(traveler.passportExpiryDate).format("YYYY-MM-DD"),
    passportIssuingDate: dayjs(traveler.passportIssuingDate).format(
      "YYYY-MM-DD",
    ),
    lead: isLead || false,
    emailAddress: traveler.emailAddress || undefined,
    passengerTypeCode: getTravelerPassengerTypeCode(traveler),
    type: getTravelerType(traveler),
    birthDate: dayjs(traveler.birthDate).format("YYYY-MM-DD"),
    phoneNumber: traveler.phoneNumber.replace(traveler.phonePrefix, ""),
  };
}

export function transformHotelTraveler(
  traveler: FindUserTravelersOutputData,
  { isLead }: { isLead?: boolean } = {},
): BookHotelOfferTraveler {
  return {
    ...traveler,
    lead: isLead || false,
    type: getTravelerType(traveler),
    birthDate: dayjs(traveler.birthDate).format("YYYY-MM-DD"),
    emailAddress: traveler.emailAddress || undefined,
    phoneNumber: traveler.phoneNumber.replace(traveler.phonePrefix, ""),
  };
}
