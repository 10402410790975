import { createFileRoute, Outlet, useMatchRoute } from "@tanstack/react-router";
import Footer from "@/app/ui/Footer";
import { motion } from "motion/react";
import clsx from "clsx";
import { TravellersProvider } from "@/app/lib/context/TravellersContext";

export const Route = createFileRoute("/_authenticated/_hotel-offer")({
  component: RouteComponent,
});

function RouteComponent() {
  const matchRoute = useMatchRoute();

  const imagesRoute = matchRoute({ to: "/hotels/$id/images", fuzzy: true });
  const galleryRoute = matchRoute({
    to: "/hotels/$id/fullscreen-gallery",
    fuzzy: true,
  });

  const hideFooter = imagesRoute || galleryRoute;

  return (
    <>
      <motion.div
        layoutId="hero"
        className={clsx(
          "bg-hero-pattern absolute top-0 z-20 h-6 w-full rounded-b-2xl bg-cover bg-no-repeat",
        )}
      />
      <main className={clsx("main", hideFooter ? "no-footer" : "with-drawer")}>
        <div className="mt-6 w-full bg-slate-100">
          <TravellersProvider>
            <Outlet />
          </TravellersProvider>
        </div>
        {hideFooter ? null : <Footer />}
      </main>
    </>
  );
}
