import FlightList from "@/app/ui/flight-list/FlightsList";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/flights/list")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <>
      <main className="main no-footer">
        <FlightList />
      </main>
    </>
  );
}
