import { Card, CircularProgress } from "@mui/material";
import { useTranslations } from "use-intl";

import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";
import { GetFlightAvailabilityTokenOptionalService } from "@/app/lib/types/codegen";

function AdditionalInfoCard({
  singleFlightDetails,
  isAdditionalDataLoading,
}: {
  singleFlightDetails: SingleFightDetailsData | null;
  isAdditionalDataLoading: boolean;
}) {
  const t = useTranslations();
  const processedServices = singleFlightDetails?.optionalServices
    ?.filter((service) => {
      return (
        service.service.categoryCode !== "CY" &&
        service.service.categoryCode !== "BG"
      );
    })
    .reduce((acc, service) => {
      // remove duplicates based on service name
      if (!acc.find((item) => item.service.name === service.service.name)) {
        acc.push(service);
      }
      return acc;
    }, [] as GetFlightAvailabilityTokenOptionalService[]);

  return (
    <div className="my-4">
      <span dir="rtl" className="text-tertiary mb-2 block w-full text-xs">
        {t("flights.additionalInfo")}
      </span>
      <Card
        dir="rtl"
        className="flex gap-4 rounded-2xl bg-white p-4"
        sx={{ boxShadow: "none" }}
      >
        {!isAdditionalDataLoading && !processedServices?.length && (
          <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
            {t("flights.noDetails")}
          </div>
        )}
        {isAdditionalDataLoading && (
          <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
            ...{t("flights.retrievingDetails")} <CircularProgress size={20} />
          </div>
        )}
        {processedServices && processedServices.length > 0 && (
          <div className="flex w-full flex-col gap-2">
            <h3 className="text-primary text-sm font-semibold">
              {t("flights.offerDetails")}
            </h3>
            {processedServices.map((service) => (
              <p
                key={
                  service.service.name + service.passengerIndex + service.target
                }
                className="text-primary text-sm font-normal"
              >
                {service.service.name} {t("flights.included")}
              </p>
            ))}
          </div>
        )}
      </Card>
      <Card
        dir="rtl"
        className="my-4 flex gap-4 rounded-2xl bg-white p-4"
        sx={{ boxShadow: "none" }}
      >
        <div className="flex w-full flex-col gap-2">
          <h3 className="text-primary text-sm font-semibold">
            {t("flights.regulationsOnSpecialBaggage")}
          </h3>
          <p className="text-primary text-sm font-normal">
            {t("flights.regulationsDetails")}
          </p>
        </div>
      </Card>
    </div>
  );
}

export default AdditionalInfoCard;
