import React from "react";
import { Card, Checkbox, Button } from "@mui/material";
import { useTranslations } from "use-intl";

const Summary = ({
  totalPrice,
  termsAndConditions,
  onTermsChange,
  onMoreDetails,
}: {
  totalPrice: string;
  termsAndConditions: boolean;
  onTermsChange: (checked: boolean) => void;
  onMoreDetails: () => void;
}) => {
  const t = useTranslations();
  return (
    <div className="h-fit">
      <span className="text-tertiary block text-end text-xs">
        {t("summary")}
      </span>
      <Card
        className="flex h-fit items-start justify-end gap-4 rounded-2xl bg-white p-4"
        sx={{
          boxShadow: "none",
          borderRadius: "16px",
          margin: "8px 0",
        }}
      >
        <div className="flex flex-col items-end">
          <Button
            onClick={() => onTermsChange(!termsAndConditions)}
            className="flex items-start p-0 text-right"
          >
            <div className="flex flex-col">
              <div className="text-primary text-[14px] font-semibold">
                {t("flights.acceptGeneralTerms")}
              </div>
              <div className="text-tertiary text-[14px]">
                {t("flights.acceptGeneralTermsWarning")}
              </div>
            </div>
            <Checkbox sx={{ pl: 1, pr: 0 }} checked={termsAndConditions} />
          </Button>
          <Button
            variant="text"
            onClick={onMoreDetails}
            sx={{
              textTransform: "none",
              padding: 0,
              textDecoration: "underline",
              marginRight: "32px",
            }}
          >
            {t("moreDetails")}
          </Button>
        </div>
      </Card>
      <Card
        className="flex flex-grow items-start justify-end rounded-2xl bg-white p-4"
        sx={{
          boxShadow: "none",
          borderRadius: "16px",
          margin: "8px 0",
        }}
      >
        <div className="flex h-full w-full flex-col gap-2">
          <div className="flex w-full justify-between">
            <span className="text-primary block text-sm font-semibold">
              {totalPrice}
            </span>
            <span className="text-primary text-sm">{t("total")}</span>
          </div>
          <div className="flex w-full justify-between">
            <span className="text-primary flex gap-2 text-sm font-semibold">
              {t("qiCard")}
            </span>
            <span className="text-primary text-sm">{t("paymentMethod")}</span>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Summary;
