"use client";

import type React from "react";
import { createContext, useContext, useState, useCallback } from "react";
import { Snackbar } from "@mui/material";
import { MuiThemeProvider } from "./MuiThemeProvider";

type ShowSnackbarType = (
  message: string,
  /** @deprecated */
  position: number,
) => void;
const SnackbarContext = createContext<ShowSnackbarType>(() => {});

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const showSnackbar = useCallback(
    (message: string, position: number) => {
      setSnackbar({
        ...snackbar,
        open: true,
        message,
      });
    },
    [snackbar],
  );

  const handleClose = useCallback(() => {
    setSnackbar({ ...snackbar, open: false });
  }, [snackbar]);

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <MuiThemeProvider>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          message={snackbar.message}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            bottom:
              "calc(env(safe-area-inset-bottom) + var(--footer-height) + (var(--spacing) * 4))",
          }}
        />
      </MuiThemeProvider>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => useContext(SnackbarContext);
