import dayjs from "dayjs";

export const initialHotelsValues = {
  destination: {
    id: 0,
    name: "",
    code: "",
    countryName: "",
    countryCode: "",
  },
  dates: {
    checkIn: dayjs(new Date()).format("YYYY-MM-DD").toString(),
    checkOut: dayjs(new Date()).add(1, "day").format("YYYY-MM-DD").toString(),
  },
  visitors: 0,
  rooms: [
    {
      adults: 1,
      children: 0,
      childrenAges: [] as number[],
    },
  ],
  travelPurpose: "leisure",
};
