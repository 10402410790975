import { useId, useState } from "react";
import { ToggleButtonGroup, ToggleButton, styled, Button } from "@mui/material";
import { useTranslations } from "use-intl";

import { StopsOptions } from "@/app/lib/types/enums";
import Image from "@/app/ui/ImageFallback";
import type { IFlightFilters } from "@/app/lib/types/interfaces";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

import PriceRangeSlider from "../PriceRangeSlider";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  borderRadius: "32px !important",
  border: "1px solid #0966D7 !important",
  textTransform: "none",
  padding: "8px",
  minWidth: "110px",
  color: "#0966D7",
  "&.Mui-selected": {
    backgroundColor: "#0966D7",
    color: "white",
    "&:hover": {
      backgroundColor: "#0966D7",
    },
    "&:selected": {
      backgroundColor: "#0966D7",
    },
  },
}));

function FlightsFilters({
  filters,
  handleFiltersChange,
  minPrice,
  maxPrice,
}: {
  filters: IFlightFilters;
  handleFiltersChange: (newFilters: Partial<IFlightFilters>) => void;
  minPrice: number;
  maxPrice: number;
}) {
  const priceHeaderId = useId();
  const { dir } = useLocaleInfo();
  const t = useTranslations();
  // Make local copy of filters
  const [localFilters, setLocalFilters] = useState<IFlightFilters>({
    ...filters,
  });

  return (
    <>
      <div className="p-4" style={{ direction: dir }}>
        <div className="mt-6 py-2">
          <h3
            id={priceHeaderId}
            className="pb-2 pl-4 text-xl font-bold text-black"
          >
            {t("hotels.filters.priceRange")}
          </h3>
          <span
            className="text-primary block w-full text-right text-sm font-medium"
            dir={dir}
          >
            {localFilters.priceRange[0].toLocaleString()} -{" "}
            {localFilters.priceRange[1].toLocaleString()}
          </span>
          <PriceRangeSlider
            min={minPrice}
            max={maxPrice}
            aria-labelledby={priceHeaderId}
            value={[localFilters.priceRange[0], localFilters.priceRange[1]]}
            onChange={(event, newValue) => {
              setLocalFilters((prevFilters) => ({
                ...prevFilters,
                priceRange: newValue,
              }));
            }}
          />
        </div>
        <div className="mt-6 py-2">
          <h3 className="pb-2 pl-4 text-xl font-bold text-black">
            {t("flights.stops")}
          </h3>
          <ToggleButtonGroup
            value={localFilters.stops}
            exclusive
            onChange={(event, newValue: StopsOptions) => {
              setLocalFilters((prevFilters) => ({
                ...prevFilters,
                stops: newValue,
              }));
            }}
            className="flex w-full justify-between gap-2 py-2"
          >
            <StyledToggleButton value={StopsOptions.ANY}>
              {t("flights.any")}
            </StyledToggleButton>
            <StyledToggleButton value={StopsOptions.DIRECT}>
              {t("flights.direct")}
            </StyledToggleButton>
            <StyledToggleButton value={StopsOptions.ONE}>
              {t("flights.upToOne")}
            </StyledToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="mt-6 py-2">
          <h3 className="pb-2 pl-4 text-xl font-bold text-black">
            {t("flights.airlines")}
          </h3>
          <div className="pl-4">
            {localFilters.airlines.map((airline) => (
              <div
                key={airline.name}
                className="my-2 flex cursor-pointer justify-between"
                onClick={() => {
                  setLocalFilters((prevFilters) => ({
                    ...prevFilters,
                    selectedAirlines: prevFilters.selectedAirlines.includes(
                      airline.code,
                    )
                      ? prevFilters.selectedAirlines.filter(
                          (a) => a !== airline.code,
                        )
                      : [...prevFilters.selectedAirlines, airline.code],
                  }));
                }}
              >
                <input
                  type="checkbox"
                  checked={localFilters.selectedAirlines.includes(airline.code)}
                  onChange={(e) => {
                    e.stopPropagation();
                    setLocalFilters((prevFilters) => ({
                      ...prevFilters,
                      selectedAirlines: e.target.checked
                        ? [...prevFilters.selectedAirlines, airline.code]
                        : prevFilters.selectedAirlines.filter(
                            (a) => a !== airline.code,
                          ),
                    }));
                  }}
                  className="mr-2"
                />
                <span className="flex items-center">
                  <span className="mr-2 ml-2 font-normal">{airline.name}</span>
                  <Image
                    src={airline.logoUrl}
                    fallbackSrc={"https://placehold.co/32"}
                    alt={`${airline.name} logo`}
                    width={32}
                    height={32}
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-auto flex flex-col items-center p-4">
        <Button
          onClick={() =>
            setLocalFilters((prevFilters) => ({
              ...prevFilters,
              stops: StopsOptions.ANY,
              priceRange: [minPrice, maxPrice],
              selectedAirlines: [],
            }))
          }
          variant="text"
          sx={{
            textTransform: "unset",
            color: "#0966D7",
            padding: "12px",
            fontSize: "16px",
          }}
        >
          {t("flights.clearFilters")}
        </Button>
        <Button
          onClick={() => handleFiltersChange(localFilters)}
          sx={{
            padding: "12px",
            borderRadius: "16px",
            textTransform: "unset",
            backgroundColor: "#0966D7",
          }}
          variant="contained"
          className="w-full rounded-2xl"
        >
          {t("flights.done")}
        </Button>
      </div>
    </>
  );
}

export default FlightsFilters;
