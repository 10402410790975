import { SingleFightDetailsData } from "../context/SingleFlightDetailsContext";
import { GetFlightAvailabilityTokenOptionalService } from "../types/codegen";

export function processServices(data: SingleFightDetailsData | null) {
  const services = data?.optionalServices;

  if (!services) {
    return null;
  }

  // Group services by route
  const baggageServiceGroups = services
    .filter(
      (service) =>
        service.service.categoryCode === "BG" ||
        service.service.categoryCode === "CY",
    )
    .reduce(
      (acc, service) => {
        const key =
          service.route.departureCode + "-" + service.route.arrivalCode;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(service);
        return acc;
      },
      {} as Record<string, GetFlightAvailabilityTokenOptionalService[]>,
    );

  // Process other services
  const otherServicesGroups = services
    .filter((service) => {
      return (
        service.service.categoryCode !== "BG" &&
        service.service.categoryCode !== "CY"
      );
    })
    .reduce(
      (acc, service) => {
        if (!acc[service.service.categoryName]) {
          acc[service.service.categoryName] = [];
        }
        acc[service.service.categoryName].push(service);
        return acc;
      },
      {} as Record<string, GetFlightAvailabilityTokenOptionalService[]>,
    );

  // // Check if services are the same for all routes
  const isSameServiceForAllRoutes = Object.values(baggageServiceGroups).every(
    (services) =>
      services[0].service.categoryCode === services[0].service.categoryCode,
  );

  const baggageServicesPerBaggageType =
    baggageServiceGroups[Object.keys(baggageServiceGroups)[0]]?.reduce(
      (acc, service) => {
        if (!acc[service.service.categoryName]) {
          acc[service.service.categoryName] = [];
        }
        acc[service.service.categoryName].push(service);
        return acc;
      },
      {} as Record<string, GetFlightAvailabilityTokenOptionalService[]>,
    ) || null;

  // Process baggage services
  const baggageServicesData = {
    isBaggageIdenticalForAllRoutes: isSameServiceForAllRoutes,
    baggageInfo: isSameServiceForAllRoutes
      ? baggageServicesPerBaggageType
      : baggageServiceGroups,
  };

  return { baggageServicesData, otherServicesGroups };
}
