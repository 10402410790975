"use client";

import { useState } from "react";
import { Button, SwipeableDrawer } from "@mui/material";
import { Puller } from "@/app/ui/StyledTz";
import { UserPlus } from "@phosphor-icons/react/dist/ssr";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useFlightDetailsProvider } from "@/app/lib/context/FlightDetailsContext";
import { useTranslations } from "use-intl";
import type { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import PageLoader from "../PageLoader";
import AnimatedLoader from "../AnimatedLoader";
import SingleTravellerInList from "@/app/ui/travellers/SingleTravellerInList";
import DeleteTraveller from "@/app/ui/travellers/DeleteTraveller";
import TravellerForm from "@/app/ui/travellers/TravellerForm";
import clsx from "clsx";
import { useHotelsDetailsProvider } from "@/app/lib/context/HotelDetailsProvider";
import dayjs from "dayjs";

function TravellersList({
  controlled,
  hasExternalCounter,
}: {
  controlled?: boolean;
  hasExternalCounter?: boolean;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [travellerForDeletion, setTravellerForDeletion] =
    useState<FindUserTravelersOutputData | null>(null);
  const [travellerForEdit, setTravellerForEdit] =
    useState<FindUserTravelersOutputData | null>(null);
  const [newTraveller, setNewTraveller] = useState(false);
  const t = useTranslations();
  const {
    travellers,
    selectedTravellers,
    toggleTravellerSelection,
    removeTraveller,
    editTraveller,
    addTraveller,
    isReady: isTravelersReady,
  } = useTravellersContext();
  const { searchData } = useFlightDetailsProvider();
  const { searchData: hotelSearchData } = useHotelsDetailsProvider();
  const travellerCount =
    hasExternalCounter && hotelSearchData
      ? hotelSearchData?.visitors
      : hasExternalCounter && searchData
        ? searchData?.adult + searchData?.child + searchData?.infantWithoutSeat
        : null;

  const firstTravelDate = hotelSearchData
    ? new Date(hotelSearchData.dates.checkIn)
    : searchData
      ? new Date(searchData.dates.departure)
      : null;

  const checkDisabled = selectedTravellers.length === travellerCount;
  const initTravellerDeletion = (traveller: FindUserTravelersOutputData) => {
    setTravellerForDeletion(traveller);
    setDrawerOpen(true);
  };

  const initTravellerEdit = (traveller: FindUserTravelersOutputData) => {
    setTravellerForEdit(traveller);
    setDrawerOpen(true);
  };

  const closeDrawerHandler = () => {
    setTravellerForDeletion(null);
    setTravellerForEdit(null);
    setNewTraveller(false);
    setDrawerOpen(false);
  };

  if (!isTravelersReady) {
    return (
      <div className="my-20">
        <PageLoader>
          <AnimatedLoader icon="users" />
        </PageLoader>
      </div>
    );
  }

  return (
    <>
      <>
        <div className="flex w-full flex-col px-4">
          {controlled && (
            <div className="flex w-full justify-end">
              <span className="text-tertiary text-xs font-medium">
                {t("travellers.selectedTravellers")} {selectedTravellers.length}
                /{travellerCount}
              </span>
            </div>
          )}
          <div
            className={clsx("mt-4 flex flex-col gap-2 overflow-y-auto", {
              "gap-0 rounded-xl bg-white": !controlled,
            })}
          >
            {travellers?.map((traveller, index) => {
              // block the traveller selection if the first travel date (check-in/departure) is less than 6 months from the passport expiry date
              const isExpired = firstTravelDate
                ? dayjs(firstTravelDate)
                    .add(6, "months")
                    .isAfter(dayjs(traveller.passportExpiryDate))
                : false;
              return (
                <SingleTravellerInList
                  lastInList={travellers.length === index + 1}
                  controlled={controlled}
                  key={traveller.id}
                  checkDisabled={checkDisabled || isExpired}
                  hint={isExpired ? t("travellers.passportExpiryHint") : ""}
                  traveller={traveller}
                  toggleTravellerSelection={toggleTravellerSelection}
                  selectedTravellers={selectedTravellers}
                  onDelete={initTravellerDeletion}
                  onEdit={initTravellerEdit}
                />
              );
            })}
          </div>
          <Button
            variant="text"
            onClick={() => {
              setDrawerOpen(true);
              setNewTraveller(true);
            }}
            sx={{
              textTransform: "none",
              color: "#0966D7",
              fontWeight: "600",
              fontSize: "14px",
              marginTop: "8px",
              padding: "8px 16px",
            }}
          >
            <UserPlus size={24} color="#0966D7" className="mr-2" />{" "}
            {t("travellers.addNew")}
          </Button>
        </div>
        <SwipeableDrawer
          anchor="bottom"
          onClose={closeDrawerHandler}
          onOpen={() => setDrawerOpen(true)}
          open={drawerOpen}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "32px 32px 0 0",
              paddingBottom: "env(safe-area-inset-bottom)",
              height: travellerForEdit || newTraveller ? "100vh" : "35vh",
            },
          }}
        >
          <Puller />
          {travellerForDeletion && (
            <DeleteTraveller
              traveller={travellerForDeletion}
              deleteTraveller={() => removeTraveller(travellerForDeletion.id)}
              closeDrawer={closeDrawerHandler}
            />
          )}
          {(newTraveller || travellerForEdit) && (
            <TravellerForm
              closeDrawer={closeDrawerHandler}
              traveller={travellerForEdit}
              addTraveller={addTraveller}
              editTraveller={editTraveller}
            />
          )}
        </SwipeableDrawer>
      </>
    </>
  );
}

export default TravellersList;
