import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Button } from "../StyledTz";
import { CabinClass } from "@/app/lib/types/enums";
import { useLocale, useTranslations } from "use-intl";
import type { FormikProps } from "formik";
import type { TFlightFormValues } from "@/app/lib/types/types";
import clsx from "clsx";
import PlusMinusButton from "../CounterButton";

function PassengersSelection({
  onDrawerClose,
  formProps,
}: {
  onDrawerClose: () => void;
  formProps: FormikProps<TFlightFormValues>;
}) {
  const locale = useLocale();
  const t = useTranslations();
  const maximumChild = 9 - formProps.values.adult;
  const maximumAdult = 9 - formProps.values.child;
  return (
    <div className="flex h-full w-full flex-col p-4">
      <div
        className="flex w-full justify-center"
        style={{
          padding: "29px 0 29px 0",
        }}
      >
        <h2 className="font-medium">
          {t("flights.search.travellersCabinClass")}
        </h2>
      </div>
      <span
        className={clsx("text-tertiary mb-4 inline-block text-xs", {
          "text-right": locale === "ar",
        })}
      >
        {t("flights.search.travellers")}
      </span>
      <div
        className={clsx("flex justify-between py-2", {
          "flex-row-reverse text-right": locale === "ar",
        })}
      >
        <div className="flex flex-col">
          <h4 className="text-primary font-medium">
            {t("flights.search.adult")}
          </h4>
          <span className="text-tertiary inline-block text-xs font-normal">
            {t("flights.search.twelveYearOldPlus")}
          </span>
        </div>
        <PlusMinusButton
          maxValue={maximumAdult}
          value={formProps.values.adult}
          onChange={(value) => formProps.setFieldValue("adult", value)}
        />
      </div>
      <div
        className={clsx("flex justify-between py-2", {
          "flex-row-reverse text-right": locale === "ar",
        })}
      >
        <div className="flex flex-col">
          <h4 className="text-primary font-medium">
            {t("flights.search.child")}
          </h4>
          <span className="text-tertiary inline-block text-xs font-normal">
            {t("flights.search.twoToTwelveYearOld")}
          </span>
        </div>
        <PlusMinusButton
          maxValue={maximumChild}
          value={formProps.values.child}
          onChange={(value) => formProps.setFieldValue("child", value)}
        />
      </div>
      <div
        className={clsx("flex justify-between py-2", {
          "flex-row-reverse text-right": locale === "ar",
        })}
      >
        <div className="flex flex-col">
          <h4 className="text-primary font-medium">
            {t("flights.search.infant")}
          </h4>
          <span className="text-tertiary inline-block max-w-[200px] text-xs font-normal text-wrap">
            {t("flights.search.withoutSeat")}
          </span>
        </div>
        <PlusMinusButton
          maxValue={formProps.values.adult}
          value={formProps.values.infantWithoutSeat}
          onChange={(value) =>
            formProps.setFieldValue("infantWithoutSeat", value)
          }
        />
      </div>
      <span
        className={clsx("text-tertiary mt-4 mb-2 inline-block text-xs", {
          "text-right": locale === "ar",
        })}
      >
        {t("flights.cabinClass.cabinClass")}
      </span>
      <div className="w-full">
        <RadioGroup
          aria-labelledby="cabin-class-radio-group"
          defaultValue="economy"
          name="cabinClass"
          value={formProps.values.cabinClass}
          onChange={formProps.handleChange}
        >
          <FormControlLabel
            className={clsx("m-0 flex justify-between py-2", {
              "flex-row-reverse": locale === "en",
            })}
            value={CabinClass.ECONOMY}
            control={<Radio />}
            label={t("flights.cabinClass.economy")}
          />
          <FormControlLabel
            className={clsx("m-0 flex justify-between py-2", {
              "flex-row-reverse": locale === "en",
            })}
            value={CabinClass.PREMIUM_ECONOMY}
            control={<Radio />}
            label={t("flights.cabinClass.premium")}
          />
          <FormControlLabel
            className={clsx("m-0 flex justify-between py-2", {
              "flex-row-reverse": locale === "en",
            })}
            value={CabinClass.FIRST_CLASS}
            control={<Radio />}
            label={t("flights.cabinClass.firstClass")}
          />
          <FormControlLabel
            className={clsx("m-0 flex justify-between py-2", {
              "flex-row-reverse": locale === "en",
            })}
            value={CabinClass.BUSINESS}
            control={<Radio />}
            label={t("flights.cabinClass.business")}
          />
        </RadioGroup>
      </div>
      <Button
        onClick={onDrawerClose}
        variant="contained"
        className="mt-auto w-full"
        sx={{
          marginTop: "auto",
          borderRadius: "16px",
        }}
      >
        {t("flights.confirm")}
      </Button>
    </div>
  );
}

export default PassengersSelection;
