import React from "react";
import { Calendar } from "../Calendar";
import { FormikProps } from "formik";
import { useTranslations } from "use-intl";
import { THotelFormValues } from "@/app/lib/types/types";
import { Button } from "../StyledTz";

function CheckInSelection({
  formProps,
  onDrawerClose,
}: {
  formProps: FormikProps<THotelFormValues>;
  onDrawerClose: () => void;
}) {
  const t = useTranslations();

  const calandarProps = {
    startField: {
      label: t("hotels.search.checkIn"),
      value: formProps.values.dates.checkIn,
      path: "dates.checkIn",
    },
    endField: {
      label: t("hotels.search.checkOut"),
      value: formProps.values.dates.checkOut,
      path: "dates.checkOut",
    },
    setFieldValue: formProps.setFieldValue,
    disabledDates: {
      before: new Date(),
    },
  };

  return (
    <div className="flex h-full flex-col px-2">
      <div
        className="flex w-full justify-center"
        style={{
          padding: "6px 0 0 0",
        }}
      >
        <h2 className="font-medium">{t("hotels.search.checkIn")}</h2>
      </div>
      <div className="h-full">
        <Calendar hasRange {...calandarProps} />
      </div>
      <Button
        fullWidth
        variant="contained"
        className="bottom-0 mb-4 rounded-lg align-bottom"
        onClick={onDrawerClose}
      >
        {t("hotels.confirm")}
      </Button>
    </div>
  );
}

export default CheckInSelection;
