import { ReactNode, useState } from "react";
import { IconButton, Button, Checkbox, Popover, Divider } from "@mui/material";
import { DotsThreeVertical } from "@phosphor-icons/react/dist/ssr";
import { useTranslations } from "use-intl";
import type { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import { TravelerTypeLabel } from "./TravelerTypeLabel";

function SingleTravellerInList({
  traveller,
  selectedTravellers,
  toggleTravellerSelection,
  checkDisabled,
  onDelete,
  onEdit,
  controlled,
  hint,
  lastInList,
}: {
  traveller: FindUserTravelersOutputData;
  selectedTravellers: string[];
  toggleTravellerSelection: (id: string) => void;
  checkDisabled: boolean;
  onDelete: (traveller: FindUserTravelersOutputData) => void;
  onEdit: (traveller: FindUserTravelersOutputData) => void;
  controlled?: boolean;
  lastInList?: boolean;
  hint?: ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const t = useTranslations();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isSelected = selectedTravellers.includes(traveller.id);

  const handleCheckboxChange = () => {
    toggleTravellerSelection(traveller.id);
  };

  const onDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
    onDelete(traveller);
    handleClose(event);
  };

  const onEditClick = (event: React.MouseEvent<HTMLElement>) => {
    onEdit(traveller);
    handleClose(event);
  };

  const fullName = `${traveller.firstName} ${traveller.lastName}`;

  return (
    <>
      <div
        className="flex justify-between rounded-xl bg-white p-4"
        onClick={controlled ? handleCheckboxChange : undefined}
      >
        <div className="flex items-center gap-1">
          <IconButton size="small" onClick={handleClick}>
            <DotsThreeVertical size={20} aria-describedby={id} />
          </IconButton>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "16px",
              boxShadow:
                "0px 1px 8px 0px rgba(56, 56, 81, 0.04), 0px 4px 10px 0px rgba(56, 56, 81, 0.04)",
            },
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="flex flex-col px-3 py-1.5">
            <Button
              onClick={onEditClick}
              sx={{
                textTransform: "none",
                textAlign: "right",
                display: "block",
              }}
              variant="text"
              className="text-primary"
            >
              {t("edit")}
            </Button>
            <Button
              onClick={onDeleteClick}
              sx={{
                textTransform: "none",
                textAlign: "right",
                display: "block",
                color: "#FF6661",
              }}
              variant="text"
            >
              {t("delete")}
            </Button>
          </div>
        </Popover>
        <div className="flex flex-1 items-center">
          <div className="flex flex-1 flex-col text-right">
            <span className="text-primary text-base font-medium">
              {fullName}
            </span>
            <span className="text-tertiary text-sm">
              <TravelerTypeLabel {...traveller} /> -{" "}
              {t("travellers.passportId")}: {traveller.passportNumber}
            </span>
            {hint ? <span className="text-xs text-red-600">{hint}</span> : null}
          </div>
          {controlled && (
            <Checkbox
              disabled={checkDisabled && !isSelected}
              checked={isSelected}
              value={traveller.id}
              sx={{
                padding: "8px",
              }}
            />
          )}
        </div>
      </div>
      {!controlled && !lastInList && <Divider className="mx-auto w-[90%]" />}
    </>
  );
}

export default SingleTravellerInList;
