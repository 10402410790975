"use client";
import { Warning } from "@phosphor-icons/react/dist/ssr";
import { Button } from "@mui/material";
import { useTranslations } from "use-intl";
import { WHATSAPP_URL } from "@/app/lib/routes";
import { useNavigate } from "@tanstack/react-router";

function BookingError({
  type,
  error,
}: {
  type: "flights" | "hotels";
  error: unknown;
}) {
  const t = useTranslations();
  const navigate = useNavigate();

  const isPurchaseError = !(error instanceof Error);

  const title = isPurchaseError
    ? t("purchaseError")
    : type === "flights"
      ? t("flights.errorWhileBooking")
      : t("hotels.errorWhileBooking");

  const description = isPurchaseError
    ? t("purchaseErrorDesc")
    : type === "flights"
      ? t("flights.errorWhileBookingDesc")
      : t("hotels.errorWhileBookingDesc");

  return (
    <div className="flex h-full flex-col items-center pb-10">
      <div className="mt-[30%] flex flex-col items-center gap-4">
        <Warning size={60} color="#DD3927" />
        <h2 className="text-xl font-semibold text-black">{title}</h2>
        <span className="text-primary text-center text-sm" dir="rtl">
          {description}
        </span>
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            fontWeight: "600",
            borderRadius: "10px",
            border: "1px solid #0966D7",
            padding: "0.5rem 1rem",
          }}
          onClick={() => navigate({ to: "/", search: { category: type } })}
        >
          {t("backToHome")}
        </Button>
      </div>
      <Button
        onClick={() => {
          window.open(WHATSAPP_URL, "_blank");
        }}
        variant="text"
        dir="rtl"
        sx={{
          marginTop: "auto",
          textTransform: "none",
          color: "#01AD99",
          fontWeight: "600",
        }}
      >
        {t("contactWhatsAppSupport")}
      </Button>
    </div>
  );
}

export default BookingError;
