import {
  Suitcase,
  SuitcaseRolling,
  Backpack,
} from "@phosphor-icons/react/dist/ssr";

export const baggageIcons: Record<string, React.ReactElement> = {
  CY: <Backpack size={20} />,
  BG: <SuitcaseRolling size={20} />,
};
