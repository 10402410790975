import {
  FindUserFlightsReservationsReservation,
  FindUserHotelsReservationsReservation,
} from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";

export const ReservationPaymentDetails = ({
  reservation,
}: {
  reservation:
    | FindUserFlightsReservationsReservation
    | FindUserHotelsReservationsReservation;
}) => {
  const t = useTranslations();
  return (
    <section>
      <h3 className="text-tertiary mt-4 mb-1 text-end text-xs">
        {t("reservations.paymentDetails")}
      </h3>
      <ul className="w-full items-center justify-between gap-4 space-y-2 rounded-2xl bg-white p-4 text-sm">
        {reservation.reference.confirmation ? (
          <li className="flex w-full flex-row-reverse items-center justify-between">
            <span className="text-title text-end">
              {t("reservations.orderNumber")}
            </span>
            <span className="text-title text-end font-semibold">
              {reservation.reference.confirmation}
            </span>
          </li>
        ) : null}
        <li className="flex w-full flex-row-reverse items-center justify-between">
          <span className="text-title text-end">
            {t("reservations.totalPrice")}
          </span>
          <span className="text-title text-end font-semibold">
            {reservation.totalPrice} {reservation.currency}
          </span>
        </li>
      </ul>
    </section>
  );
};
