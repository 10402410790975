"use client";

import { useState } from "react";
import useSWR from "swr";
import { X } from "@phosphor-icons/react/dist/ssr";
import PageLoader from "@/app/ui/PageLoader";
import { useHotelsDetailsProvider } from "@/app/lib/context/HotelDetailsProvider";
import { BASE_API_URL } from "@/app/lib/routes";
import useDebounce from "@/app/lib/hooks/useDebounce";
import { THotelFormValues, THotelSearchResult } from "@/app/lib/types/types";
import { LinearProgress, SwipeableDrawer } from "@mui/material";
import HotelListHeader from "./HotelListHeader";
import HotelsListSort from "./HotelsListSort";
import { DrawerComponent, HotelsSortOptions } from "@/app/lib/types/enums";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHotelSearchProgress } from "@/app/lib/hooks/useHotelSearchProgress";
import { HotelCard } from "./HotelCard";
import HotelsFilters from "./HotelsFilters";
import { IHotelsFilters } from "@/app/lib/types/interfaces";
import AnimatedLoader from "../AnimatedLoader";
import { useHotelSearchResultKey } from "@/app/lib/hooks/useHotelSearchResultKey";
import PageError from "../PageError";
import NoResultsError from "../NoResultsError";
import { useHotelsTokensContext } from "@/app/lib/context/HotelsTokenProvider";
import { useHotelDataProvider } from "@/app/lib/context/HotelDataProvider";
import { useTranslations } from "use-intl";
import { hotelsPostFetcher } from "@/app/lib/hooks/useHotelsSearch";
import { WhatsAppButton } from "../WhatsAppButton";
import { useNavigate } from "@tanstack/react-router";

const dataMapper = (searchData: THotelFormValues) => {
  return {
    cityId: searchData.destination.id,
    checkIn: searchData.dates.checkIn,
    checkOut: searchData.dates.checkOut,
    travelPurpose: searchData.travelPurpose,
    occupancy: {
      leaderNationality: 1,
      rooms: searchData.rooms.map(({ adults, childrenAges }) => ({
        adults,
        childrenAges,
      })),
    },
  };
};

const SORT_DIRECTION = "ASC";
const SORT_FIELD = "price";
const PRICES = [1, 999999];
const BOARD_BASIS = ["RO", "SC", "BB", "HB", "FB", "AI", "RD"];

function HotelsList() {
  const { searchData, isReady } = useHotelsDetailsProvider();
  const {
    tokens: { progressToken, searchResultKey, searchResultToken },
    setTokens,
  } = useHotelsTokensContext();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [drawerComponent, setDrawerComponent] = useState("");
  const [sortValue, setSortValue] = useState(HotelsSortOptions.PRICE);
  const { setHotelData } = useHotelDataProvider();
  const t = useTranslations();

  const handleOverviewOpen = (hotel: THotelSearchResult) => {
    setHotelData(hotel);
    navigate({
      to: "/hotels/$id/$step",
      params: { id: `${hotel.id}`, step: "1" },
    });
  };

  const handleDrawerOpen = (component: DrawerComponent) => {
    setDrawerComponent(component);
    setDrawerOpen(true);
  };

  const debouncedSearch = useDebounce(dataMapper(searchData!), 500);
  const { data: progressData, isValidating: isValidatingProgressData } =
    useHotelSearchProgress(progressToken, searchResultKey, searchResultToken);

  const [filters, setFilters] = useState<IHotelsFilters>({
    priceRange: [PRICES[0], PRICES[1]],
    sortField: SORT_FIELD,
    sortOrder: SORT_DIRECTION,
    boardBasis: progressData?.data?.boardBasis || BOARD_BASIS,
    starRating: progressData?.data?.stars || [1, 2, 3, 4, 5],
  });

  const shouldFetch = progressData?.data?.progress === 100;

  const {
    data: searchResultData,
    isValidating: isValidatingData,
    isError,
    hasReachedEnd,
    isEmpty,
    size,
    setSize,
  } = useHotelSearchResultKey(
    searchResultKey,
    filters,
    searchResultToken,
    shouldFetch,
    sortValue,
  );

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const { isValidating: isValidatingTokens } = useSWR(
    () => {
      return isReady && debouncedSearch
        ? {
            url: `${BASE_API_URL}/hotels/search`,
            params: { ...debouncedSearch },
          }
        : null;
    },
    hotelsPostFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      onSuccess: (data) => {
        setTokens((prev) => ({
          ...prev,
          progressToken: data.data.searchProgressToken,
          searchResultKey: data.data.searchResultKey,
          searchResultToken: data.data.searchResultToken,
        }));
      },
    },
  );

  const handleFiltersChange = (newFilters: Partial<IHotelsFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
    setDrawerOpen(false);
  };

  if (isError) {
    return <PageError />;
  }

  const progress = Number(progressData?.data?.progress || 0);
  const isValidating =
    isValidatingTokens ||
    isValidatingData ||
    isValidatingProgressData ||
    progress < 100;
  if (!isReady || isValidating) {
    return (
      <>
        {progress < 100 ? (
          <LinearProgress
            variant="determinate"
            sx={{
              direction: "rtl",
              transform: "rotate(180deg)",
            }}
            value={progress}
          />
        ) : null}

        <PageLoader>
          <AnimatedLoader icon="buildings" />
          <h3 className="text-primary mt-3 font-normal">
            {t("hotels.preparingBestOffers")}
          </h3>
        </PageLoader>
      </>
    );
  }

  return (
    <>
      <HotelListHeader
        selectedSortOption={sortValue}
        filtersHandler={() => {
          handleDrawerOpen(DrawerComponent.HOTELS_SORT);
        }}
        sortHandler={() => {
          handleDrawerOpen(DrawerComponent.HOTELS_FILTERS);
        }}
      />

      <WhatsAppButton />

      {isEmpty ? (
        <NoResultsError tab="hotels" />
      ) : (
        <div
          id="scrollableDiv"
          className="text-primary w-full px-4 text-lg font-normal"
        >
          {progressData?.data?.countHotels ? (
            <InfiniteScroll
              dataLength={progressData?.data?.countHotels || 0}
              scrollableTarget="scrollableDiv"
              next={() => {
                setSize(size + 1);
              }}
              hasMore={!hasReachedEnd}
              loader={
                <div className="flex flex-col items-center justify-center">
                  <AnimatedLoader icon="buildings" />
                  <h3 className="text-primary mt-3 font-normal">
                    {t("hotels.preparingBestOffers")}
                  </h3>
                </div>
              }
              endMessage={
                <div className="mb-9 flex flex-col items-center justify-center gap-3 p-8">
                  {t("hotels.noMoreResults")}
                </div>
              }
            >
              {searchResultData?.map(
                (hotels: { data: THotelSearchResult[]; status: string }) => {
                  return hotels.data.map(
                    (hotel: THotelSearchResult, index: number) => {
                      return (
                        <HotelCard
                          onClick={() => handleOverviewOpen(hotel)}
                          key={index}
                          hotel={hotel}
                        />
                      );
                    },
                  );
                },
              )}
            </InfiniteScroll>
          ) : null}
        </div>
      )}

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
            borderRadius: "32px 32px 0 0",
            paddingBottom: "env(safe-area-inset-bottom)",
            minHeight:
              drawerComponent == DrawerComponent.HOTELS_SORT ? "35vh" : "90vh",
          },
        }}
      >
        {DrawerComponent.HOTELS_SORT == drawerComponent ? (
          <>
            <button
              className="absolute top-7 left-4 hover:cursor-pointer"
              onClick={() => setDrawerOpen(false)}
              aria-label="close drawer"
            >
              <X size={20} color="#0966D7" />
            </button>
            <div
              className="flex justify-center"
              style={{
                padding: "24px 0 13px 0",
              }}
            >
              <h2 className="font-medium">{t("hotels.filters.sort")}</h2>
            </div>
            <HotelsListSort
              handleDrawerClose={handleDrawerClose}
              sortValue={sortValue}
              setSortValue={setSortValue}
            />
          </>
        ) : (
          <HotelsFilters
            handleDrawerClose={handleDrawerClose}
            sortValue={sortValue}
            setSortValue={setSortValue}
            filters={filters}
            handleFiltersChange={handleFiltersChange}
          />
        )}
      </SwipeableDrawer>
    </>
  );
}

export default HotelsList;
