import { Button } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";

export const FlightOfferNotAvailableError = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <h1 className="text-primary text-center text-2xl" dir="rtl">
        {t("flights.notAvailable.title")}
      </h1>
      <p className="text-secondary mb-4">
        {t("flights.notAvailable.description")}
      </p>
      <Button
        variant="outlined"
        onClick={() => navigate({ to: "/flights/list" })}
      >
        {t("flights.notAvailable.button")}
      </Button>
    </div>
  );
};
