import { useTranslations } from "use-intl";
import {
  Chip,
  Card,
  Divider,
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import { Alarm } from "@phosphor-icons/react";
import type { Stop } from "@/app/lib/types/interfaces";
import type {
  FindUserTravelersOutputData,
  GetFlightAvailabilityTokenFlight,
} from "@/app/lib/types/codegen";
import dayjs from "dayjs";
import TravellersListSummary from "./TravellersListSummary";
import { DownloadVoucher } from "../DownloadVoucher";
import { useReservation } from "@/app/lib/hooks/useReservation";
import { useReservationDocuments } from "@/app/lib/hooks/useReservationDocuments";
import type { TVoucherDoc } from "@/app/lib/types/types";
import { useNavigate } from "@tanstack/react-router";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";

function FlightBookSuccess({
  departureStops,
  selectedTravellers,
  departureFlight,
  leadTraveller,
  returnStops,
  returnFlight,
  singleFlightDetails,
  reservationId,
}: {
  departureStops: Stop[];
  selectedTravellers: FindUserTravelersOutputData[];
  returnStops: Stop[];
  leadTraveller: FindUserTravelersOutputData | null;
  departureFlight: GetFlightAvailabilityTokenFlight | null;
  returnFlight: GetFlightAvailabilityTokenFlight | null;
  singleFlightDetails: SingleFightDetailsData | null;
  reservationId: string;
}) {
  const t = useTranslations();
  const navigate = useNavigate();
  const { data, isLoading } = useReservation("flights", reservationId);
  const { data: documentsData } = useReservationDocuments(reservationId, 2000);

  const baggage =
    singleFlightDetails?.optionalServices?.filter(
      (service) =>
        (service.included && service.service.categoryCode === "BG") ||
        service.service.categoryCode === "CY",
    ) || [];

  return (
    <div className="flex w-full flex-col items-center gap-4 px-4">
      <h5 className="mt-6 mb-10 text-xl font-semibold text-black">
        {t("flights.flightBooked")}
      </h5>
      <Card
        dir="rtl"
        className="mb-0 flex w-full items-center justify-between gap-4 rounded-2xl bg-white p-4 hover:cursor-pointer"
        sx={{
          boxShadow: "none",
          borderRadius: "16px",
        }}
      >
        <span className="text-tertiary text-xs font-medium">
          {t("flights.ticketNumber")}
        </span>
        <h3 className="text-title text-base font-semibold">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            data?.reference?.external
          )}
        </h3>
      </Card>

      {data?.id && !documentsData?.documents?.length && (
        <div className="flex flex-col items-center justify-center">
          <CircularProgress size={24} className="mb-2" />
          ...{t("preparingTickets")}
        </div>
      )}

      {data?.id &&
        documentsData?.documents?.map((doc: TVoucherDoc, index: number) => (
          <DownloadVoucher
            key={doc.link}
            doc={doc}
            dataId={data.id}
            index={index}
          />
        ))}

      <Card
        dir="rtl"
        className="flex h-full w-full flex-col gap-4 rounded-2xl bg-white p-4 hover:cursor-pointer"
        sx={{
          boxShadow: "none",
          borderRadius: "16px",
        }}
      >
        {returnFlight && (
          <span className="text-tertiary text-xs">
            {t("flights.search.departure")}
          </span>
        )}
        {departureFlight &&
          departureFlight.segments.map((segment, index) => (
            <div
              key={index}
              className="flex w-full flex-col items-center justify-end gap-2"
            >
              <div dir="rtl" className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                  <Avatar
                    alt="airplane company logo"
                    sx={{
                      width: 16,
                      height: 16,
                      fontSize: "12px",
                    }}
                    src={segment.marketingCarrier.airline.logoUrl}
                  >
                    {segment.marketingCarrier.airline.name?.split("")[0]}
                  </Avatar>
                  <span className="text-primary text-xs">
                    {segment.marketingCarrier.airline.name ||
                      t("unknownAirline")}
                  </span>
                </div>
                <span className="text-tertiary text-xs">
                  {segment.equipment.name || segment.equipment.code}{" "}
                  {segment.marketingCarrier.flightNumber}
                </span>
              </div>
              <div className="flex w-full items-end justify-between">
                <div className="flex flex-col">
                  <span className="text-tertiary text-sm">
                    {dayjs(segment.departure.date).format("MMMM DD")}
                  </span>
                  <h3 className="text-title text-xl font-semibold">
                    {segment.departure.time}
                  </h3>
                  <span className="text-tertiary text-sm">
                    {segment.departure.airport.code}
                  </span>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <div className="relative">
                    {segment.duration && (
                      <Chip
                        className="bg-accent-700 relative z-10 px-2 text-xs font-normal text-white"
                        label={segment.duration}
                        dir="rtl"
                        color="primary"
                        size="small"
                      />
                    )}
                    <div
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      id="flight-separator"
                    ></div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-tertiary text-end text-sm">
                    {dayjs(segment.arrival.date).format("MMMM DD")}
                  </span>
                  <h3 className="text-title text-end text-xl font-semibold">
                    {segment.arrival.time}
                  </h3>
                  <span className="text-tertiary text-end text-sm">
                    {segment.arrival.airport.code}
                  </span>
                </div>
              </div>
              {departureStops.length > 0 && departureStops[index] && (
                <Chip
                  icon={
                    <Alarm size={16} color="#222930" style={{ margin: 0 }} />
                  }
                  className="mx-auto my-4"
                  sx={{
                    paddingRight: "10px",
                    "& .MuiChip-label": {
                      paddingRight: "4px",
                    },
                  }}
                  label={`${
                    departureStops[index].stopTime.split(":")[0] +
                    "h " +
                    departureStops[index].stopTime.split(":")[1]
                  } stop in ${segment.arrival.city.name} (${
                    segment.arrival.airport.code
                  })`}
                ></Chip>
              )}
            </div>
          ))}
        {returnFlight && (
          <span className="text-tertiary text-xs">
            {t("flights.search.return")}
          </span>
        )}
        {returnFlight &&
          returnFlight.segments.map((segment, index) => (
            <div
              key={index}
              dir="rtl"
              className="flex w-full flex-col items-center justify-end gap-2"
            >
              <div dir="rtl" className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                  <Avatar
                    alt="airplane company logo"
                    sx={{
                      width: 16,
                      height: 16,
                      fontSize: "12px",
                    }}
                    src={segment.marketingCarrier.airline.logoUrl}
                  >
                    {segment.marketingCarrier.airline.name?.split("")[0]}
                  </Avatar>
                  <span className="text-primary text-xs">
                    {segment.marketingCarrier.airline.name ||
                      t("unknownAirline")}
                  </span>
                </div>
                <span className="text-tertiary text-xs">
                  {segment.equipment.name || segment.equipment.code}{" "}
                  {segment.marketingCarrier.flightNumber}
                </span>
              </div>
              <div className="flex w-full items-end justify-between">
                <div className="flex flex-col">
                  <span className="text-tertiary text-sm">
                    {dayjs(segment.departure.date).format("MMMM DD")}
                  </span>
                  <h3 className="text-title text-xl font-semibold">
                    {segment.departure.time}
                  </h3>
                  <span className="text-tertiary text-sm">
                    {segment.departure.airport.code}
                  </span>
                </div>
                <div className="flex flex-col items-center gap-1">
                  <div className="relative">
                    {segment.duration && (
                      <Chip
                        className="bg-accent-700 relative z-10 px-2 text-xs font-normal text-white"
                        label={segment.duration}
                        dir="rtl"
                        color="primary"
                        size="small"
                      />
                    )}
                    <div
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      id="flight-separator"
                    ></div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-tertiary text-end text-sm">
                    {dayjs(segment.arrival.date).format("MMMM DD")}
                  </span>
                  <h3 className="text-title text-end text-xl font-semibold">
                    {segment.arrival.time}
                  </h3>
                  <span className="text-tertiary text-end text-sm">
                    {segment.arrival.airport.code}
                  </span>
                </div>
              </div>
              {returnStops.length > 0 && returnStops[index] && (
                <Chip
                  icon={
                    <Alarm size={16} color="#222930" style={{ margin: 0 }} />
                  }
                  className="mx-auto my-4"
                  sx={{
                    paddingRight: "10px",
                    "& .MuiChip-label": {
                      paddingRight: "4px",
                    },
                  }}
                  label={`${
                    returnStops[index].stopTime.split(":")[0] +
                    "h " +
                    returnStops[index].stopTime.split(":")[1]
                  } stop in ${segment.arrival.city.name} (${
                    segment.arrival.airport.code
                  })`}
                ></Chip>
              )}
            </div>
          ))}
        <Divider sx={{ margin: "1.5rem 0" }} />
        <TravellersListSummary
          leadTraveller={leadTraveller}
          selectedTravellers={selectedTravellers}
          baggage={baggage}
        />
      </Card>
      <div className="flex w-full flex-col gap-2">
        <span className="text-tertiary block w-full text-end text-xs">
          {t("reservations.whatsNext")}
        </span>
        <Card
          className="w-full gap-4 bg-white p-4"
          sx={{ boxShadow: "none", borderRadius: "16px" }}
        >
          <div className="flex flex-col items-end gap-2 text-end">
            <h5 className="text-primary text-sm font-semibold">
              {t("flights.nextSteps")}
            </h5>
            <span className="text-primary block text-sm font-normal">
              {t("flights.nextStepsDescription")}
            </span>
          </div>
        </Card>
      </div>
      <div className="mt-auto flex flex-col gap-2">
        <Button
          onClick={() => {
            navigate({ to: "/", search: { category: "flights" } });
          }}
          variant="contained"
          sx={{
            textTransform: "none",
            width: "240px",
            background: "#0966D7",
            borderRadius: "8px",
            color: "white",
            marginTop: "24px",
            fontWeight: "600",
          }}
        >
          {t("backToHome")}
        </Button>
        <Button
          onClick={() => {
            navigate({ to: "/trips" });
          }}
          variant="text"
          sx={{
            textTransform: "none",
            width: "240px",
            borderRadius: "8px",
            color: "#0966D7",
            fontWeight: "600",
          }}
        >
          {t("openMyTrips")}
        </Button>
      </div>
    </div>
  );
}

export default FlightBookSuccess;
