import React from "react";
import { SwipeableDrawer } from "@mui/material";
import { Puller } from "@/app/ui/StyledTz";
import { useTranslations, useLocale } from "use-intl";
const EnglishTerms = () => {
  return (
    <>
      <h3 className="p1">TERMS OF SERVICE</h3>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        These Terms are important as they, together with your booking voucher
        (the “<b>Booking Confirmation</b>”), set out the legal terms on which
        Travel Services are made available to you through our Service. They also
        cover any interactions or communications you have with us through our
        Service.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Your use of our Service is conditioned upon your acceptance of these
        Terms. To book a Travel Service, you must also accept these Terms. If
        you do not accept these Terms, then please do not use our Service or
        book a Travel Service.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        We may change these Terms at any time and your future use of our Service
        following changes to these Terms is subject to you accepting those
        updated Terms. We recommend that you save or print a copy of these
        Terms.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">In these Terms:</p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        “We,” “us,” or “our” refer to{" "}
        <b>أرض التجوال للسفر والسياحة محدودة المسؤولية</b>, an Iraqi
        corporation, having its registered office at بغداد / حي حطين / محلة 620
        / شارع 26 بناية رقم 2/60, which provides our Service.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        “<b>Our Group of Companies</b>” refers to us, and our subsidiaries and
        corporate affiliates
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        “<b>Our Partners</b>” refers to any affiliated, co-branded, or linked
        website through which our Group of Companies provides content or service
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        “<b>Our Service</b>” refers to the provision of our websites, apps and
        online tools
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        “<b>Travel Provider</b>” refers to the travel supplier making available
        the Travel Services to you through our Service
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        <b>“Travel Services”</b> refers to the travel services made available to
        you by the relevant Travel Provider(s) through our Service, such as
        purchasing flight tickets, stays at a property, and other related travel
        services.
      </p>
      <p className="p3">
        “<b>you</b>” refers to you, the traveler, using our Service or making a
        booking with our Service.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        <b>Please read these Terms carefully.</b>
      </p>
      <h3 className="p4">Section 1 Rules and Restrictions</h3>
      <p className="p3">
        As well as these Terms, other terms and conditions provided by Travel
        Providers (such as a property’s or airline’s terms and conditions) also
        apply to your booking (“Rules and Restrictions”).
      </p>
      <p className="p3">
        To make a booking, you must accept the Rules and Restrictions of the
        Travel Provider that you select (such as payment of due amounts,
        refundability, penalties, availability restrictions, and use of fares or
        services, etc.). The relevant Rules and Restrictions are provided to you
        before you make a booking and are incorporated by reference into these
        Terms.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        If you violate a Travel Provider's Rules and Restrictions, your booking
        may be canceled and you may be denied access to the relevant Travel
        Service. You may also lose any money paid for such booking and we or the
        Travel Provider may debit your account for any costs we or they incur as
        a result of such violation.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 2 Using our Service</h3>
      <h4 className="p4">Our rules</h4>
      <p className="p3">
        We provide our service to help you find information about travel
        services and assist you in booking those services. It is provided to you
        for no other purpose.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">You agree that:</p>
      <ul className="ul1">
        <li className="li5">
          you will only use our Service for personal and non-commercial purposes
          <br />
          you must be at least 18 years of age and have the legal authority to
          enter into contracts
        </li>
        <li className="li3">
          you will use our Service lawfully and in compliance with these Terms
        </li>
        <li className="li3">
          all information supplied by you is true, accurate, current, and
          complete
        </li>
        <li className="li3">if you have an account with us, you will:</li>
        <ul className="ul1">
          <li className="li3">safeguard your account information</li>
          <li className="li3">
            be responsible for any use of your account by you or others
          </li>
        </ul>
        <li className="li3">if you book on behalf of others:</li>
        <ul className="ul1">
          <li className="li3">
            you will obtain their authorization prior to acting on their behalf
          </li>
          <li className="li3">
            you will inform them about the terms that apply to the booking
            (including the Rules and Restrictions) and ensure that they agree to
            such terms
          </li>
        </ul>
        <li className="li3">
          You are responsible for paying any amounts due, making any
          change/cancellation requests, and handling all other matters relating
          to the booking.
        </li>
      </ul>
      <p className="p6">
        <br />
      </p>
      <p className="p3">You also agree not to:</p>
      <p className="p3">
        <br />
      </p>
      <ul className="ul1">
        <li className="li5">
          make any false or fraudulent booking
          <br />
          access, monitor, or copy any content on our Service using any robot,
          spider, scraper, or other automated means or any manual process
        </li>
        <li className="li3">
          violate the restrictions in any robot exclusion headers on our Service
          or bypass or circumvent other measures employed to prevent or limit
          access to our Service
        </li>
        <li className="li3">
          take any action that imposes, or may impose, an unreasonable or large
          load on our infrastructure
        </li>
        <li className="li3">deep-link to any part of our Service</li>
        <li className="li3">
          “frame”, “mirror” or otherwise incorporate any part of our Service
          into any other website.
        </li>
      </ul>
      <p className="p6">
        <br />
      </p>
      <h4 className="p4">Access</h4>
      <p className="p3">
        We may, acting reasonably, deny anyone access to our Service at any time
        for any valid reason. We may also make improvements and changes to our
        Service at any time.
      </p>
      <h4 className="p4">Account</h4>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        To use our mini-app, you must first have an active Super Qi account.
        This account must be created in accordance with Super Qi's terms, which
        may include an age requirement of at least 18 years. Additionally, when
        accessing our mini-app, you must allow Super Qi to share your account
        information with us. If you do not meet these requirements or choose not
        to share your information, you will not be able and not allowed to
        access our mini-app.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p4">How we order your search results</p>
      <p className="p3">
        There are many travel options available through our Service and we want
        to make your search results as relevant as possible. On the search
        results page, you will see our default sort order, however, you can
        select how to sort your results and also use filter options to
        prioritize results based on your chosen preferences, for example, price,
        guest review score, or other criteria.
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 3 Confirming a booking</h3>
      <p className="p3">
        Your Booking Confirmation contains all the essential details of your
        reservation, including the description of the Travel Service(s) booked
        and the total price. You will receive your Booking Confirmation and any
        relevant travel documents in the final step within the app after
        completing your payment. If you do not receive your Booking Confirmation
        within 24 hours of booking, please contact us through our
        <span className="Apple-converted-space"> </span>
      </p>
      <ul className="ul1">
        <li className="li3">Phone Number: +9647766850489</li>
        <li className="li3">Email: res@travelzone.iq</li>
      </ul>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 4 Payment</h3>
      <p className="p3">
        <span className="s1">
          Price:
          <br />
        </span>
        The price of the Travel Service(s) will be as displayed on our Service,
        except in cases of obvious error.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Prices for Travel Services are dynamic and can change at any time. Price
        changes will not affect bookings already accepted, except in cases of
        obvious error. We display many Travel Services, and we try hard to
        ensure that the displayed price is accurate. We reserve the right to
        correct any pricing errors on our Service.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        If there is an obvious error and you have made a booking, we will offer
        you the opportunity to keep your booking by paying the correct price or
        we will cancel your booking without penalty. We have no obligation to
        make available Travel Services to you at an incorrect (lower) price even
        after you have been sent a Booking Confirmation if the error should
        reasonably have been apparent to you.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Taxes</h4>
      <p className="p3">
        You acknowledge that our Group of Companies does not collect any taxes,
        and the prices shown do not include any taxes. We are not responsible
        for any taxes that may be requested by the Travel Provider. Travel
        Providers are responsible for remitting applicable taxes to the relevant
        taxing jurisdictions. In certain jurisdictions, you may be responsible
        for paying local taxes imposed by local tax authorities (such as city
        taxes or tourist taxes). These taxes may be charged by either our Group
        of Companies or the Travel Provider. We will notify you of any local
        taxes that will be payable by you before you complete your booking,
        provided that these taxes have been communicated to us by the Travel
        Provider. Please note that taxability and the appropriate tax rate can
        vary greatly by jurisdiction, and the amount of local taxes may change
        between your booking date and the date of your stay or flight. If taxes
        have increased by your stay or flight date, you may be liable to pay the
        higher rate.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Sales, use, and/or local hotel occupancy taxes, as well as similar taxes
        on flights, are imposed on the amounts that our Group of Companies
        charges for services they provide (such as service fees and/or
        facilitation fees) in certain jurisdictions. The actual amount imposed
        on services provided by our Group of Companies may vary depending on the
        rates in effect at the time the services are provided by the Travel
        Provider. In certain jurisdictions, our Group of Companies are
        responsible for collecting and remitting sales, use, and/or local hotel
        occupancy taxes, as well as taxes related to flights, for the entire
        price, including the amounts we charge for our services as well as the
        Travel Services provided by the Travel Provider.
      </p>
      <h4 className="p4">Payment processing</h4>
      <p className="p3">
        When payment is taken at the time of your booking and paid for in the
        local currency of our Service (as relevant), the company taking that
        payment (via third-party payment processors) and charging your payment
        method will be the third-party payment processors
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Payment verification</h4>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        You authorize the relevant company (as set out in the table above) or
        the Travel Provider to:
      </p>
      <ul className="ul1">
        <li className="li5">
          verify your payment method by obtaining a pre-authorization, charging
          a nominal fee or through other verification means, and
        </li>
        <li className="li3">on verification, charge your payment method.</li>
      </ul>
      <h4 className="p4">Fraud</h4>
      <p className="p3">
        If a booking or account shows signs of fraud, abuse, association with a
        government-sanctioned person or entity, or other suspicious activity, we
        may request extra information from you.
      </p>
      <p className="p3">
        If we reasonably conclude that a booking or account is associated with
        fraud, abuse, a government-sanctioned person or entity, or suspicious
        activity, we may:
      </p>
      <ul className="ul1">
        <li className="li5">
          cancel any bookings associated with your name, email address, or
          account
        </li>
        <li className="li3">close any associated accounts, and</li>
        <li className="li3">
          take legal action, including to seek to hold you liable for any loss.
        </li>
      </ul>
      <p className="p3">
        Please contact us about the cancellation of a booking or closing of an
        account.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 5 Cancelling or changing a booking</h3>
      <h4 className="p4">Cancellation or change by you</h4>
      <p className="p3">
        Cancellations or changes (with respect to the travel date, destination,
        place where the trip starts, property or means of transport) to a
        booking can be made by contacting us.
      </p>
      <p className="p3">
        You do not have an automatic right to cancel or change a booking
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Travel Providers may charge you fees for cancelling (in full or part) or
        changing a booking. Such fees will be set out in the Rules and
        Restrictions. You agree to pay any charges that you incur. Please be
        aware that for changes, the price of your new arrangements will be based
        on the applicable price at the time you ask us to make the change. This
        price may not be the same as when you originally booked the Travel
        Services. Prices tend to increase the closer to the departure date that
        the change is made.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Please read the relevant Rules and Restrictions, so you know which terms
        apply to your booking. For example:
      </p>
      <h4 className="p7">Hotels Cancellation:</h4>
      <p className="p8">Refundable With Penalty ty Bookings:</p>
      <ul className="ul1">
        <li className="li9">
          You will receive a refund for the booking amount.
        </li>
        <li className="li10">
          No cancellations are allowed within 24 hours of the check-in time. If
          you attempt to cancel within this period, no refund will be issued,
          and the full amount may be charged according to the hotel's policy.
        </li>
      </ul>
      <p className="p8">Non-Refundable Bookings:</p>
      <ul className="ul1">
        <li className="li9">
          The amount refunded upon cancellation will depend on the supplier's
          and hotel's policies. In some cases, no refund may be available.
        </li>
        <li className="li10">
          Certain properties do not permit cancellations or changes after the
          booking is made. If such restrictions apply, you will be notified at
          the time of booking.
        </li>
      </ul>
      <h4 className="p7">General Rules for All Bookings:</h4>
      <ul className="ul1">
        <li className="li9">
          If you do not cancel or change your booking before the relevant
          cancellation policy period, you may be subject to cancellation or
          change charges as specified in the relevant Rules and Restrictions.
        </li>
        <li className="li3">
          If you make a "Pay Later" booking and fail to show up or cancel the
          booking, the property may impose a no-show or cancellation charge as
          shown in the relevant Rules and Restrictions.
        </li>
        <li className="li10">
          If you do not show up or fail to use some or all of the Travel
          Services booked, refunds may only be issued in line with the relevant
          Rules and Restrictions.
        </li>
      </ul>
      <p className="p3">
        If you want to cancel or change any part of a booking and such
        cancellation or change is allowed by the relevant Travel Provider, then,
        in addition to any charges imposed by the Travel Provider, we may also
        charge you an administration fee. If such an administration fee applies,
        it will be notified to you before you agree to proceed with the
        change/cancellation.
      </p>
      <p className="p4">Other cancellations or change</p>
      <p className="p3">
        For a variety of reasons (such as a property is overbooked due to
        connectivity issues or a property being closed due to a hurricane,
        etc.), it is possible that a booking may be canceled or changed by the
        Travel Provider or us. If this happens, we will make reasonable efforts
        to notify you as soon as possible and offer alternative
        options/assistance where possible or a refund.
      </p>
      <h4 className="p4">Refund</h4>
      <p className="p3">
        Any refunds will be transferred back to you to the payment method you
        used to make the original booking. Our fees are not refundable unless
        this is stated otherwise during the booking process.
      </p>
      <p className="p4">First-night no-show</p>
      <p className="p3">
        If you do not show up for the first night of your stay booking, but plan
        to check in for the subsequent nights, please confirm this with us
        before the original check-in date. If you do not confirm this, then your
        whole booking may be canceled. Refunds for no-shows will only be due to
        you in line with the relevant Rules and Restrictions of the property.
      </p>
      <h4 className="p4">Meals</h4>
      <p className="p3">
        If meals are part of your stay booking, the number of meals included
        depends on the number of nights of your stay. Full board normally
        includes breakfast, lunch, and dinner. Half board normally includes
        breakfast and either lunch or dinner. No refunds will be available if
        one or more meals are not consumed.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p7">Flights Cancellation:</h4>
      <p className="p8">Cancellations:</p>
      <ul className="ul1">
        <li className="li11">
          <b>After 12:00 AM on the Reservation Day:</b> Flights can be canceled
          with applicable fees. The cancellation fees depend on the airline, and
          our cancellation fee will be 8% of the total booking cost.
        </li>
        <li className="li12">
          <b>No Cancellations Accepted Within 36 Hours of the Flight Date:</b>{" "}
          Cancellations are not permitted within 36 hours of the scheduled
          flight.
        </li>
      </ul>
      <h4 className="p8">Void:</h4>
      <ul className="ul1">
        <li className="li11">
          <b>Flights on the Same Day:</b> If the flight canceled withing the
          same day, it can be canceled without any fees.
        </li>
        <li className="li4">
          <b>Flights Within 36 Hours:</b> Flights scheduled within 36 hours
          cannot be canceled.
        </li>
        <li className="li12">
          <b>Non-Refundable Tickets:</b> Not all airlines allow voids or
          cancellations. Please note that some tickets may be non-refundable.
        </li>
      </ul>
      <h4 className="p8">Changes:</h4>
      <ul className="ul1">
        <li className="li11">
          <b>No Changes Accepted Within 24 Hours of the Flight Date:</b> Changes
          to the flight itinerary are not allowed within 24 hours of the
          scheduled flight.
        </li>
        <li className="li12">
          <b>Change Fees:</b> If a traveler requests a change to the flight,
          change fees will apply, regardless of whether the change involves an
          upgrade or downgrade. Our service fee for processing changes is 15,000
          IQD.
        </li>
      </ul>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 6 International travel</h3>
      <h4 className="p4">International travel</h4>
      <p className="p3">
        Although most travel occurs without incident, travel to certain
        destinations may involve more risk than others. You must review any
        travel warnings/advice, etc. issued by the relevant governments before
        you book international travel. You should also monitor such travel
        warnings/advice during travel and before your return journey to help
        avoid and minimize any potential disruptions.
      </p>
      <h4 className="p4">Health</h4>
      <p className="p3">
        You should check the recommended inoculations/vaccinations which may
        change at any time. You should consult your doctor before you depart.
        You are responsible for ensuring you:
      </p>
      <ul className="ul1">
        <li className="li5">meet all health entry requirements</li>
        <li className="li3">
          receive the relevant/required inoculations/vaccinations
        </li>
        <li className="li3">take all recommended medication, and</li>
        <li className="li3">
          follow all medical advice in relation to your travel.
        </li>
      </ul>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Passport and visa</h4>
      <p className="p3">
        You must consult the relevant Embassy or Consulate for passport and visa
        information. Requirements may change so check for up-to-date information
        before booking and departure and allow sufficient time for all relevant
        applications.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Our Company is not liable if you are refused entry onto a flight or
        cruise ship (if applicable) or into any country, due to your conduct,
        including your failure to carry the correct and adequate travel
        documents required by any Travel Provider, authority or country
        (including countries you are transiting through). This includes all
        stops made by an aircraft or cruise ship (if applicable), even if you do
        not leave the aircraft or airport or cruise ship.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Some governments require airlines to provide personal information about
        all travelers on their aircraft. The data will be collected either at
        the airport when you check in or in some circumstances when you make
        your booking. Please contact the relevant airline you are travelling
        with if you have any questions about this.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Our Company does not represent or warrant that travel to international
        destinations is advisable or without risk and is not liable for damages
        or losses that may result from travel to such destinations.
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 7 Liability</h3>
      <h4 className="p4">Our liability</h4>
      <p className="p3">
        We own and operate our Service and the Travel Providers provide the
        Travel Services to you.
      </p>
      <p className="p3">
        To the maximum extent permitted by law, our Company and our Partners
        will not be liable for:
      </p>
      <ul className="ul1">
        <li className="li5">
          any such Travel Services that the Travel Providers make available to
          you
        </li>
        <li className="li3">
          for the acts, errors, omissions, representations, warranties or
          negligence of any such Travel Providers, or
        </li>
        <li className="li3">
          for any personal injuries, death, property damage or other damages or
          expenses resulting from the above.
        </li>
      </ul>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        The Travel Providers provide us with information describing the Travel
        Services. This information includes Travel Service details, photos,
        rates and the relevant Rules and Restrictions, etc. We display this
        information through our Service. The Travel Providers are responsible
        for ensuring that such information is accurate, complete and up to date.
        Our Company and our Partners will not be liable for any inaccuracies in
        such information, unless and only if we directly caused such
        inaccuracies. Our Company and our Partners make no guarantees about the
        availability of specific Travel Services.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Photos and illustrations on our Service are provided as a guide to show
        you the level and type of accommodation only.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        To the maximum extent permitted by law, except as expressly set out in
        these Terms:
      </p>
      <ul className="ul1">
        <li className="li5">
          all information, software, or Travel Services displayed through our
          Service are provided without any warranty or condition of any kind.
          This includes, but is not limited to, any implied warranties and
          conditions of satisfactory quality, merchantability, fitness for a
          particular purpose, title or non-infringement, and
        </li>
        <li className="li3">
          our Group of Companies and our Partners disclaim all such warranties
          and conditions.
        </li>
      </ul>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        The display of Travel Services through our Service is not an endorsement
        or recommendation of such Travel Services by our Group of Companies or
        our Partners. Our Group of Companies and our Partners disclaim, to the
        maximum extent permitted by law, all warranties and conditions that our
        Service, its servers or any email sent from us or our Partners are free
        of viruses or other harmful components.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        To the maximum extent permitted by law and subject to the limitations in
        these Terms, neither our Group of Companies nor our Partners will be
        liable for any direct, indirect, punitive, special, incidental or
        consequential losses or damages arising from:
      </p>
      <p className="p2">
        <br />
      </p>
      <ul className="ul1">
        <li className="li3">the Travel Services,</li>
        <li className="li3">the use of our Service,</li>
        <li className="li3">any delay or inability to use our Service, or</li>
        <li className="li3">your use of links from our Service,</li>
      </ul>
      <p className="p6">
        <br />
      </p>
      <p className="p3">
        whether based in negligence, contract, tort, strict liability, consumer
        protection statutes, or otherwise, and even if our Group of Companies
        and our Partners have been advised of the possibility of such damages.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        If our Group of Companies or our Partners are found liable for any loss
        or damage under these Terms, then, to the maximum extent permitted by
        law, we shall only be liable to you for direct damages that were:
      </p>
      <ul className="ul1">
        <li className="li5">
          reasonably foreseeable by both you and us (or our Partners as
          applicable),
        </li>
        <li className="li3">actually suffered or incurred by you, and</li>
        <li className="li3">
          directly attributable to our actions (or the actions of our Partners
          as applicable),
        </li>
      </ul>
      <p className="p5">
        and in the event of any liability of our Group of Companies and/or our
        Partners, such liability will in no event exceed, in total, the greater
        of (a) the cost paid by you for the Travel Services in question or (b)
        one-hundred and fifty thousand Iraqi Dinar (IQD 150,000) or the
        equivalent in local currency.
      </p>
      <p className="p6">
        <br />
      </p>
      <p className="p5">
        This limitation of liability reflects the allocation of risk between you
        and us. The limitations specified in this Section will survive and apply
        even if any limited remedy specified in these Terms is found to have
        failed its essential purpose. The limitations of liability provided in
        these Terms inure to the benefit of our Group of Companies and our
        Partners.
      </p>
      <p className="p6">
        <br />
      </p>
      <p className="p3">
        Every instance of force majeure, including the interruption of means of
        communication or a strike (by airlines, properties or air traffic
        controllers, as applicable), will lead to the suspension of the
        obligations in these Terms that are affected by the force majeure event.
        In such a case the party affected by the force majeure event will not be
        liable as a result of the inability to meet such obligations.
      </p>
      <h4 className="p4">Indemnity</h4>
      <p className="p3">
        You agree to defend and indemnify our Group of Companies and our
        Partners and any of their officers, directors, employees and agents from
        and against any claims, causes of action, demands, recoveries, losses,
        damages, fines, penalties or other costs or expenses of any kind or
        nature (“<b>Losses</b>”), including but not limited to, reasonable legal
        and accounting fees, brought by third parties as a result of:
      </p>
      <ul className="ul1">
        <li className="li5">
          your breach of these Terms or the documents referenced in them
        </li>
        <li className="li3">
          your violation of any law or the rights of a third-party, or
        </li>
        <li className="li3">your use of our Service,</li>
      </ul>
      <p className="p5">
        to the extent that such Losses are not directly caused by the actions of
        our Group of Companies or our Partners (as applicable).
      </p>
      <p className="p6">
        <br />
      </p>
      <h3 className="p4">Section 8 Reviews, comments and photos</h3>
      <p className="p3">
        By submitting content to our Service by email, postings or otherwise,
        including any property reviews, photos, videos, questions, comments,
        suggestions, ideas or the like contained in any submissions
        (collectively "<b>Submissions</b>"), you:
      </p>
      <ul className="ul1">
        <li className="li5">
          confirm that all Submissions you make are your original creation and
          that you have and will maintain all rights necessary to allow us to
          use the Submissions as set out in these Terms, and
        </li>
        <li className="li3">
          grant our Group of Companies and our Partners as permitted by law, a
          non-exclusive, royalty-free, perpetual, transferable, irrevocable and
          fully sub-licensable through multi-levels right to use, reproduce,
          modify, adapt, translate, distribute, publish, create derivative works
          from and publicly display and perform such Submissions throughout the
          world in any media, now known or later devised.
        </li>
      </ul>
      <p className="p5">
        You also acknowledge and agree that our Group of Companies and our
        Partners may choose to use the name that you submit with such Submission
        to attribute your Submissions (for example, listing your first name and
        hometown on a review that you submit) at our discretion in a
        non-identifiable format. Such Submissions may also be shared with the
        Travel Providers.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        You also grant our Group of Companies the right to legally pursue any
        person or entity that violates your or our Group of Companies’ rights in
        the Submissions.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        <b>Submissions are non-confidential and non-proprietary.</b>
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        If possible, you expressly waive any and all ‘moral rights’ (including
        rights of attribution or integrity) that may subsist in your
        Submissions. You agree that you have no objection to the publication,
        use, modification, deletion or exploitation of your Submissions by our
        Group of Companies, our Partners or any of our other licensees.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        You are fully responsible for the content of your Submissions. You must
        not post or transmit to or from our Service and agree that any
        Submissions you make do not contain any content that:
      </p>
      <ul className="ul1">
        <li className="li5">
          is unlawful, threatening, libellous, defamatory, obscene,
          pornographic, or would violate publicity or privacy rights or any law
        </li>
        <li className="li3">
          is commercial (such as solicitation of funds, advertising, or
          marketing of any goods or services, etc.)
        </li>
        <li className="li3">
          infringes, misappropriates or violates any copyright, trademark,
          patent or other proprietary right of any third-party, or
        </li>
        <li className="li3">
          is objectionable on the grounds of public interest, public morality,
          public order, public security or national harmony.
        </li>
      </ul>
      <p className="p3">
        You will be solely liable for any damages resulting from not complying
        with the rules above, or any other harm resulting from your posting of
        Submissions to our Service.
      </p>
      <p className="p3">
        We may exercise our rights (for example: to use, publish, display,
        delete, etc.) to any Submissions without notice to you.
      </p>
      <p className="p3">
        If you submit more than one review for the same property, only your most
        recent Submission is eligible for use.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        We claim no ownership or endorsement of, or affiliation with, any
        Submissions made by you.
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 9 Intellectual property policy and notices</h3>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Copyright and trademark notices</h4>
      <p className="p3">
        All contents of our Service are ©2024 Travelzone.iq, a Digital Zone
        Group company. All rights reserved. Travelzone.iq and the Travelzone.iq
        logo are trademarks of Travelzone.iq. Other logos and product and
        company names mentioned herein may be the trademarks of their respective
        owners. We are not responsible for content on websites operated by
        parties other than us.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Our Service may contain links to websites operated by parties other than
        us. Such links are provided for your reference only. We do not control
        such websites and are not responsible for their content or your use of
        them. Our inclusion of such links does not imply any endorsement of the
        material on such websites or any association with their operators.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        <b>
          If you are aware of an infringement of our brand, please let us know
          by emailing us at res@travelzone.iq.
          <span className="Apple-converted-space"> </span>
        </b>
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p4">
        Intellectual property infringement policy and complaints
      </p>
      <p className="p3">
        We respect the intellectual property rights of others and expect our
        suppliers, partners, and users (collectively “Users”) to do the same. We
        have a policy of prohibiting Users from posting materials that infringe
        the copyright, trademark rights, or other intellectual property rights
        of others, and under appropriate circumstances, we will terminate the
        account of Users who are repeat infringers.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Patent notices</h4>
      <p className="p3">
        One or more patents owned by us or our Group of Companies may apply to
        our Service and to the features and services accessible through our
        Service. Portions of our Service operate under license of one or more
        patents. Other patents pending.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 10 Software available on our Service</h3>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Software</h4>
      <p className="p3">
        Any software made available to download from our Service or a mobile app
        store ("<b>Software</b>"), is the copyrighted work of our Group of
        Companies or our respective suppliers. Your use of the Software is
        governed by the terms of the end user license agreement (if any) which
        accompanies the Software ("<b>License Agreement</b>"). You must first
        agree to the License<span className="Apple-converted-space"> </span>
      </p>
      <p className="p3">Agreement to install, download or use any Software.</p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        For any Software not accompanied by a License Agreement, we grant you a
        limited, personal, non-exclusive, non-transferable and
        non-sub-licensable license to download, install and use the Software for
        using our Service in line with these Terms and for no other purpose. The
        Software is provided to you free of any fees or charges.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        All Software (such as all HTML code and Active X controls, etc.)
        contained on our Service, is owned by our Group of Companies,
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        our Partners or our respective suppliers. All Software is protected by
        copyright laws and international treaty provisions. Any reproduction or
        redistribution of the Software is prohibited by law and may result in
        severe civil and criminal penalties. Anyone who violates this will be
        prosecuted.
      </p>
      <p className="p3">
        Without limiting the above, copying or reproduction of the Software to
        any other server or location for further reproduction or redistribution
        is expressly prohibited. The Software is warranted, if at all, only
        according to the terms of the License Agreement.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p6">
        <br />
      </p>
      <h3 className="p4">Section 11 Your privacy and personal information</h3>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        We are committed to the privacy, confidentiality, and security of
        personal information entrusted to us.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Please review our current Privacy Statement, which also governs your use
        of our Service and is incorporated by reference into these Terms, to
        understand our practices.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 12 Disputes and arbitration</h3>
      <h4 className="p4">Disputes and arbitration</h4>
      <p className="p3">
        Please read this Section carefully. It requires that any and all claims
        be resolved by binding arbitration or in small claims court, and it
        prevents you from pursuing a class action or similar proceeding in any
        forum. Arbitration is required if your country of residence enforces
        arbitration agreements. If your country of residence does not enforce
        arbitration agreements, the mandatory pre-arbitration dispute resolution
        and notification and prohibition on class actions or representative
        proceedings provided below still apply to the extent enforceable by law.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        We are committed to traveler satisfaction and to resolving consumer
        disputes in a timely and efficient manner. We have a two-step dispute
        resolution process that includes: (1) investigation and negotiation of
        your claim with our Traveler Support team; and, if necessary, (2)
        binding arbitration administered by all Iraqi courts according to Iraqi
        law.
      </p>
      <h4 className="p4">Agreement to arbitrate (“Arbitration Agreement”)</h4>
      <p className="p3">
        <b>
          You and we mutually agree that any disputes between us arising out of
          or relating in any way to our Service, these Terms, our{" "}
        </b>
        Privacy Statement
        <b>
          , any services or products provided by us or any of our subsidiaries,
          Travel Providers, or companies offering products or services through
          us, any dealings with our Traveler Support agents, or any
          representations made by us (“Claims”), will be resolved by binding
          arbitration, rather than in court except those resolved in small
          claims court. This includes any Claims you assert against us, our
          subsidiaries, Travel Providers, or any companies offering products or
          services through us
        </b>{" "}
        <b>
          (which are beneficiaries of this Arbitration Agreement). This
          Arbitration Agreement shall be binding upon, and shall include any
          claims brought by or against, any third parties, including but not
          limited to your spouses, heirs, third-party beneficiaries, and
          assigns, where their underlying claims are in relation to your use of
          the Travel Services. To the extent that any third-party beneficiary to
          these Terms brings claims against the entities covered by these Terms;
          those claims shall also be subject to this Arbitration Agreement. The
          arbitrator shall also be responsible for determining all threshold
          arbitrability issues, including without limitation the existence,
          scope, or validity of the Arbitration Agreement, any defense to
          arbitration such as issues relating to whether this Arbitration
          Agreement can be enforced, is unconscionable or illusory, and any
          defenses to arbitration, including without limitation jurisdiction,
          waiver, delay, laches, or estoppel.
        </b>
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Small claims court matters</h4>
      <p className="p3">
        Notwithstanding the foregoing arbitration provisions, either you or we
        may bring or remove any claim in an Iraqi court if the claim is within
        such court’s jurisdictional limit; provided that the court does not have
        the authority to entertain any claims on a class or representative
        basis, or to consolidate or join the claims of other persons or parties
        who may be similarly situated in such proceedings. Further, if the
        claims asserted in any demand for arbitration are within the Iraqi
        court’s jurisdictional limit, either you or we may elect to have the
        claims heard in an Iraqi court, rather than in arbitration, at any time
        before the arbitrator is appointed by notifying the other party of that
        election in writing.
      </p>
      <h4 className="p4">No class actions or representative proceedings</h4>
      <p className="p3">
        You and we agree that any and all proceedings to resolve Claims will be
        conducted only on an individual basis and not as part of a class,
        consolidated, or representative action, including without limitation as
        a private attorney general. The court may not consolidate more than one
        party’s Claims and may not otherwise preside over any form of class or
        representative proceeding. You and we further acknowledge that both
        parties are waiving the right to have Claims resolved through a jury
        trial, and instead, any such Claims will be resolved in an Iraqi court.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Arbitration rules and governing law</h4>
      <p className="p13">
        This Arbitration Agreement is a "written agreement to arbitrate" under
        Iraqi law. The arbitration will be administered according to the
        applicable arbitration rules under Iraqi law, except as modified here.
        The arbitrator shall apply the law of Iraq, regardless of any conflict
        of laws principles. Foreign laws do not apply. This Arbitration
        Agreement can only be amended by mutual agreement in writing.
      </p>
      <p className="p4">
        Mandatory pre-arbitration dispute resolution and notification
      </p>
      <p className="p3">
        Before initiating arbitration, you agree to provide us with the
        opportunity to resolve any Claims by notifying us in writing and making
        a good faith effort to negotiate an informal resolution.
        <span className="Apple-converted-space"> </span>
      </p>
      <p className="p3">
        You must send a written and signed Notice of Dispute ("Notice") by
        certified mail, addressed to: Legal Department/Dispute Resolution
        Provision, Travel Zone, بغداد / حي حطين / محلة 620 / شارع 26 بناية رقم
        2/60, Iraq.<span className="Apple-converted-space"> </span>
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        The Notice must contain the following information: (1) your name, (2)
        your address, (3) the email address you used to make your reservation,
        (4) Your phone number used tp make your reservation (5) a brief
        description of the nature of your complaint, (6) the resolution that you
        are seeking, and (7) your signature.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Commencing arbitration</h4>
      <p className="p3">
        A party initiating arbitration against us must send the written Demand
        for Arbitration to Legal Department/Dispute Resolution Provision, Travel
        Zone, Baghdad, Hay Hiteen, District 620, Street 26, Building No. 2/60,
        Iraq, concurrent with filing the Demand.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p3">
        Arbitration shall be conducted by a single arbitrator selected either in
        accordance with applicable Iraqi arbitration rules or by mutual
        agreement between you and us. The arbitration shall be held either: (i)
        at an arbitration court in Iraq; (ii) at such other location as may be
        mutually agreed upon by you and us; (iii) via videoconference; or (iv)
        at your election, if the only claims in the arbitration are asserted by
        you and are for less than $40,000 in aggregate, by telephone or by
        written submission.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h3 className="p4">Section 13 General</h3>
      <h4 className="p4">Governing law and jurisdiction</h4>
      <p className="p4">
        These Terms are governed by the laws of Iraq, without regard to
        principles of conflicts of laws.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Insurance</h4>
      <p className="p3">
        Unless otherwise stated, prices displayed do not include travel
        insurance. You are advised to take out insurance that covers the
        consequences of certain cases of cancellation and certain risks (such as
        the cost of repatriation in the event of an accident or illness). You
        are responsible for ensuring that any insurance policy taken out
        adequately covers your requirements. You may be shown certain travel
        insurance products. If so, details of the insurance provider, relevant
        key information and terms and conditions will be shown on our Service.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Failure to invoke</h4>
      <p className="p3">
        Our failure or delay to enforce any provision of these Terms does not
        waive our right to enforce the same or any other provision(s) of these
        Terms in the future.
      </p>
      <p className="p2">
        <br />
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Unenforceable provisions</h4>
      <p className="p3">
        If any provision (or part provision) of these Terms is found by a court
        or other authority of competent jurisdiction to be invalid, illegal or
        unenforceable, that provision (or part provision) shall, if required, be
        deemed not to form part of these Terms with you. In such a case, the
        validity and enforceability of the other provisions shall not be
        affected.
      </p>
      <h4 className="p4">Entire agreement</h4>
      <p className="p3">
        These Terms constitute the entire agreement between you and us with
        respect to our Service. They supersede all prior or contemporaneous
        communications (whether electronic, oral, or written) between you and us
        about our Service.
      </p>
      <h4 className="p4">Assignment</h4>
      <p className="p3">
        We may, and you may not assign, subcontract or delegate rights, duties
        or obligations under these Terms.
      </p>
      <h4 className="p4">Third-party rights</h4>
      <p className="p3">
        Save as expressly stated in these Terms we do not intend any part of
        these Terms to be enforceable by any person who is not a party to these
        Terms. No third-party's consent shall be required for the waiver,
        variation or termination of any part of these Terms. These Terms do not
        give rise to any rights under any applicable laws or regulations in
        relation to the rights of third parties to enforce any part of these
        Terms.
      </p>
      <p className="p2">
        <br />
      </p>
      <h4 className="p4">Survival of obligations</h4>
      <p className="p3">
        Any provision of these Terms, which expressly, or by its nature, imposes
        obligations beyond the expiration, or termination of these Terms, shall
        survive such expiration or termination.
      </p>
    </>
  );
};

const ArabicTerms = () => {
  return (
    <>
      <p dir="rtl" className="p13">
        <b>شروط الخدمة</b>
      </p>
      <p dir="rtl" className="p13">
        تعد هذه الشروط مهمة لأنها، مع قسيمة الحجز الخاصة بك ("تأكيد الحجز")،
        تحدد الشروط القانونية التي يتم بموجبها توفير خدمات السفر لك من خلال
        خدمتنا. كما تغطي أي تفاعلات أو اتصالات تجريها معنا من خلال خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        استخدامك لخدمتنا مشروط بقبولك لهذه الشروط. لحجز خدمة سفر، يجب عليك أيضًا
        قبول هذه الشروط. إذا لم تقبل هذه الشروط، فلا تستخدم خدمتنا أو تحجز خدمة
        سفر.
      </p>
      <p dir="rtl" className="p13">
        يجوز لنا تغيير هذه الشروط في أي وقت، ويخضع استخدامك المستقبلي لخدمتنا
        بعد أي تغييرات في هذه الشروط لقبولك للشروط المحدثة. نوصي بأن تحتفظ بنسخة
        من هذه الشروط أو تطبعها.
      </p>
      <p dir="rtl" className="p13">
        في هذه الشروط:
      </p>
      <p dir="rtl" className="p13">
        "نحن"، "لنا"، أو "خاصتنا" تشير إلى{" "}
        <b>أرض التجوال للسفر والسياحة محدودة المسؤولية</b>، وهي شركة عراقية، يقع
        مكتبها المسجل في بغداد / حي حطين / محلة 620 / شارع 26 بناية رقم 2/60.
      </p>
      <p dir="rtl" className="p13">
        "مجموعة شركاتنا" تشير إلى شركتنا والشركات التابعة لنا والشركات الزميلة.
      </p>
      <p dir="rtl" className="p13">
        "شركاؤنا" يشير إلى أي موقع مرتبط أو يحمل علامة تجارية مشتركة أو موقع
        مرتبط تقدم من خلاله مجموعة شركاتنا المحتوى أو الخدمة.
      </p>
      <p dir="rtl" className="p13">
        "خدمتنا" تشير إلى توفير مواقعنا الإلكترونية، التطبيقات، والأدوات عبر
        الإنترنت.
      </p>
      <p dir="rtl" className="p13">
        "مزود السفر" يشير إلى المورد الذي يوفر خدمات السفر لك من خلال خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        "خدمات السفر" تشير إلى خدمات السفر المتاحة لك من قبل مزود السفر ذي الصلة
        من خلال خدمتنا، مثل شراء تذاكر الطيران، الإقامة في عقار، وغيرها من خدمات
        السفر ذات الصلة.
      </p>
      <p dir="rtl" className="p13">
        "أنت" تشير إلى المسافر الذي يستخدم خدمتنا أو يقوم بالحجز من خلال خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        يرجى قراءة هذه الشروط بعناية.
      </p>
      <p dir="rtl" className="p2">
        <br />
      </p>
      <p dir="rtl" className="p8"></p>
      <p dir="rtl" className="p13">
        <b>القسم 1: القواعد والقيود</b>
      </p>
      <p dir="rtl" className="p13">
        إلى جانب هذه الشروط، تنطبق أيضًا الشروط والأحكام التي يقدمها مزودو السفر
        (مثل شروط وأحكام الملكية أو شركة الطيران) على حجزك ("القواعد والقيود").
      </p>
      <p dir="rtl" className="p13">
        لإجراء حجز، يجب عليك قبول القواعد والقيود الخاصة بمزود السفر الذي تختاره
        (مثل دفع المبالغ المستحقة، إمكانية استرداد الأموال، العقوبات، قيود
        التوفر، واستخدام الأجرة أو الخدمات، إلخ). يتم تقديم القواعد والقيود ذات
        الصلة لك قبل إجراء الحجز وهي جزء لا يتجزأ من هذه الشروط.
      </p>
      <p dir="rtl" className="p13">
        إذا انتهكت قواعد وقيود مزود السفر، قد يتم إلغاء حجزك وقد يتم منعك من
        الوصول إلى خدمة السفر ذات الصلة. قد تفقد أيضًا أي أموال مدفوعة مقابل هذا
        الحجز وقد نخصم نحن أو مزود السفر من حسابك أي تكاليف تكبدناها نحن أو هم
        نتيجة لهذا الانتهاك.
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 2: استخدام خدمتنا</b>
      </p>
      <p dir="rtl" className="p13">
        <b>قواعدنا</b>
      </p>
      <p dir="rtl" className="p13">
        نحن نقدم خدمتنا لمساعدتك في العثور على معلومات حول خدمات السفر ولتيسير
        حجز تلك الخدمات. يتم تقديمها لك لهذا الغرض فقط، ولا يجوز استخدامها لأي
        غرض آخر.
      </p>
      <p dir="rtl" className="p13">
        أنت توافق على:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          استخدامك لخدمتنا سيكون لأغراض شخصية وغير تجارية فقط.
        </li>
        <li dir="rtl" className="li3">
          يجب أن يكون عمرك 18 عامًا على الأقل وأن تكون لديك السلطة القانونية
          لإبرام العقود.
        </li>
        <li dir="rtl" className="li3">
          ستستخدم خدمتنا بطريقة قانونية ووفقًا لهذه الشروط.
        </li>
        <li dir="rtl" className="li3">
          جميع المعلومات التي تقدمها يجب أن تكون صحيحة، دقيقة، حديثة وكاملة.
        </li>
        <li dir="rtl" className="li3">
          إذا كان لديك حساب معنا، فإنك توافق على:
        </li>
        <ul className="ul1">
          <li dir="rtl" className="li3">
            حماية معلومات حسابك.
          </li>
          <li dir="rtl" className="li3">
            تحمل المسؤولية عن أي استخدام لحسابك من قبلك أو من قبل الآخرين.
          </li>
        </ul>
        <li dir="rtl" className="li3">
          إذا قمت بالحجز نيابة عن آخرين، فإنك توافق على:
        </li>
        <ul className="ul1">
          <li dir="rtl" className="li3">
            الحصول على تفويضهم قبل التصرف نيابة عنهم.
          </li>
          <li dir="rtl" className="li3">
            إبلاغهم بالشروط التي تنطبق على الحجز (بما في ذلك القواعد والقيود)
            وضمان موافقتهم على تلك الشروط.
          </li>
          <li dir="rtl" className="li10">
            تحمل المسؤولية عن دفع أي مبالغ مستحقة، وعن تنفيذ أي طلبات
            تغيير/إلغاء، وعن جميع الأمور الأخرى المتعلقة بالحجز.
          </li>
        </ul>
      </ul>
      <p dir="rtl" className="p13">
        كما توافق على عدم:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          إجراء أي حجز كاذب أو احتيالي.
        </li>
        <li dir="rtl" className="li3">
          الوصول إلى أو مراقبة أو نسخ أي محتوى على خدمتنا باستخدام أي روبوت أو
          عنكبوت أو أداة كشط أو أي وسائل آلية أخرى أو عملية يدوية.
        </li>
        <li dir="rtl" className="li3">
          انتهاك القيود الموجودة في أي رؤوس استثناء للروبوتات على خدمتنا أو
          التحايل على أو تجاوز أي تدابير مستخدمة لمنع أو تقييد الوصول إلى
          خدمتنا.
        </li>
        <li dir="rtl" className="li3">
          اتخاذ أي إجراء يفرض أو قد يفرض حملاً غير معقول أو كبيرًا على بنيتنا
          التحتية.
        </li>
        <li dir="rtl" className="li3">
          الربط العميق لأي جزء من خدمتنا.
        </li>
        <li dir="rtl" className="li10">
          "إطار"، "مرآة" أو دمج أي جزء من خدمتنا في أي موقع آخر.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        <b>الوصول</b>
      </p>
      <p dir="rtl" className="p13">
        يجوز لنا، عند التصرف بشكل معقول، منع أي شخص من الوصول إلى خدمتنا في أي
        وقت ولأي سبب وجيه. كما يجوز لنا أيضًا إجراء تحسينات وتغييرات على خدمتنا
        في أي وقت.
      </p>
      <p dir="rtl" className="p13">
        <b>الحساب</b>
      </p>
      <p dir="rtl" className="p13">
        لاستخدام تطبيقنا المصغر، يجب أن يكون لديك أولاً حساب نشط في Super Qi.
        يجب إنشاء هذا الحساب وفقًا لشروط Super Qi، التي قد تشمل متطلب العمر الذي
        لا يقل عن 18 عامًا. بالإضافة إلى ذلك، عند الوصول إلى تطبيقنا المصغر، يجب
        أن تسمح لـ Super Qi بمشاركة معلومات حسابك معنا. إذا لم تستوفِ هذه
        المتطلبات أو اخترت عدم مشاركة معلوماتك، فلن تتمكن من الوصول إلى تطبيقنا
        المصغر ولن يُسمح لك بذلك.
      </p>
      <p dir="rtl" className="p13">
        <b>كيفية ترتيب نتائج البحث الخاصة بك</b>
      </p>
      <p dir="rtl" className="p13">
        هناك العديد من خيارات السفر المتاحة من خلال خدمتنا ونرغب في جعل نتائج
        البحث الخاصة بك ذات صلة قدر الإمكان. في صفحة نتائج البحث، سترى ترتيب
        الفرز الافتراضي الخاص بنا، ومع ذلك يمكنك اختيار كيفية ترتيب النتائج
        واستخدام خيارات التصفية لإعطاء الأولوية للنتائج بناءً على تفضيلاتك
        المختارة، مثل السعر أو تقييم الضيوف أو معايير أخرى.
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 3: تأكيد الحجز</b>
      </p>
      <p dir="rtl" className="p13">
        يتضمن تأكيد الحجز الخاص بك جميع التفاصيل الأساسية لحجزك، بما في ذلك وصف
        خدمات السفر المحجوزة والسعر الإجمالي. ستتلقى تأكيد الحجز الخاص بك وأي
        وثائق سفر ذات صلة في الخطوة الأخيرة داخل التطبيق بعد إتمام الدفع. إذا لم
        تتلقَ تأكيد الحجز الخاص بك خلال 24 ساعة من إجراء الحجز، يرجى الاتصال بنا
        عبر:
      </p>
      <p dir="rtl" className="p13">
        رقم الهاتف: +9647766850489
        <br />
        البريد الإلكتروني: res@travelzone.iq
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 4: الدفع</b>
      </p>
      <p dir="rtl" className="p13">
        <b>السعر:</b>
      </p>
      <p dir="rtl" className="p13">
        سعر خدمات السفر سيكون كما هو معروض على خدمتنا، باستثناء حالات الخطأ
        الواضح.
      </p>
      <p dir="rtl" className="p13">
        أسعار خدمات السفر ديناميكية ويمكن أن تتغير في أي وقت. لن تؤثر تغييرات
        الأسعار على الحجوزات المقبولة بالفعل، باستثناء حالات الخطأ الواضح. نحن
        نعرض العديد من خدمات السفر، ونبذل جهدًا كبيرًا لضمان أن السعر المعروض
        دقيق. نحن نحتفظ بالحق في تصحيح أي أخطاء في الأسعار على خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        إذا كان هناك خطأ واضح وقد قمت بإجراء حجز، سنعرض عليك الفرصة للاحتفاظ
        بحجزك عن طريق دفع السعر الصحيح أو سنلغي حجزك دون عقوبة. ليس لدينا أي
        التزام بتوفير خدمات السفر لك بسعر غير صحيح (أقل) حتى بعد إرسال تأكيد
        الحجز إليك إذا كان يجب أن يكون الخطأ واضحًا لك بشكل معقول.
      </p>
      <p dir="rtl" className="p13">
        <b>الضرائب</b>
      </p>
      <p dir="rtl" className="p13">
        أنت تقر بأن مجموعة شركاتنا لا تجمع أي ضرائب، وأن الأسعار المعروضة لا
        تشمل أي ضرائب. نحن غير مسؤولين عن أي ضرائب قد يطلبها مزود السفر. يكون
        مزودو السفر مسؤولين عن تحويل الضرائب المستحقة إلى السلطات الضريبية ذات
        الصلة. في بعض الولايات القضائية، قد تكون مسؤولًا عن دفع الضرائب المحلية
        التي تفرضها السلطات الضريبية المحلية (مثل ضرائب المدينة أو الضرائب
        السياحية). قد يتم فرض هذه الضرائب من قبل مجموعة شركاتنا أو مزود السفر.
        سنقوم بإبلاغك بأي ضرائب محلية يتعين عليك دفعها قبل إتمام حجزك، بشرط أن
        تكون هذه الضرائب قد أبلغتنا بها من قبل مزود السفر. يرجى ملاحظة أن
        الضريبة المناسبة ومعدلها يمكن أن تختلف بشكل كبير حسب الولاية القضائية،
        وقد يتغير مبلغ الضرائب المحلية بين تاريخ الحجز وتاريخ إقامتك أو رحلتك.
        إذا زادت الضرائب بحلول تاريخ إقامتك أو رحلتك، قد تكون مسؤولًا عن دفع
        المعدل الأعلى.
      </p>
      <p dir="rtl" className="p13">
        يتم فرض ضرائب الاستخدام والمبيعات و/أو ضرائب إشغال الفنادق المحلية،
        وكذلك الضرائب المشابهة على الرحلات الجوية، على المبالغ التي تفرضها
        مجموعة شركاتنا مقابل الخدمات التي تقدمها (مثل رسوم الخدمة و/أو رسوم
        التسهيلات) في بعض الولايات القضائية. قد يختلف المبلغ الفعلي المفروض على
        الخدمات المقدمة من قبل مجموعة شركاتنا بناءً على الأسعار السارية في وقت
        تقديم الخدمات من قبل مزود السفر. في بعض الولايات القضائية، تكون مجموعة
        شركاتنا مسؤولة عن جمع وتحويل ضرائب المبيعات والاستخدام و/أو ضرائب إشغال
        الفنادق المحلية، وكذلك الضرائب المتعلقة بالرحلات الجوية، على السعر
        الإجمالي بما في ذلك المبالغ التي نفرضها مقابل خدماتنا وكذلك خدمات السفر
        المقدمة من قبل مزود السفر.
      </p>
      <p dir="rtl" className="p13">
        <b>معالجة الدفع</b>
      </p>
      <p dir="rtl" className="p13">
        عندما يتم استلام الدفع في وقت الحجز ويتم دفعه بالعملة المحلية لخدمتنا
        (حسب الحاجة)، فإن الشركة التي تستلم ذلك الدفع (عبر معالجي الدفع من الطرف
        الثالث) وتحصيل طريقة الدفع الخاصة بك ستكون معالجي الدفع من الطرف الثالث.
      </p>
      <p dir="rtl" className="p13">
        <b>التحقق من الدفع</b>
      </p>
      <p dir="rtl" className="p13">
        أنت تفوض الشركة المعنية (كما هو موضح في الجدول أعلاه) أو مزود السفر
        للتحقق من طريقة الدفع الخاصة بك عن طريق الحصول على تصريح مسبق، فرض رسوم
        رمزية أو من خلال وسائل تحقق أخرى، وعند التحقق، فرض رسوم على طريقة الدفع
        الخاصة بك.
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>الاحتيال</b>
      </p>
      <p dir="rtl" className="p13">
        إذا أظهر الحجز أو الحساب علامات على الاحتيال، إساءة الاستخدام، الارتباط
        بشخص أو كيان خاضع لعقوبات حكومية، أو أي نشاط مشبوه آخر، قد نطلب منك
        تقديم معلومات إضافية.
      </p>
      <p dir="rtl" className="p13">
        إذا توصلنا بشكل معقول إلى أن الحجز أو الحساب مرتبط بالاحتيال، إساءة
        الاستخدام، شخص أو كيان خاضع لعقوبات حكومية، أو نشاط مشبوه آخر، فقد نقوم
        بـ:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          إلغاء أي حجوزات مرتبطة باسمك أو عنوان بريدك الإلكتروني أو حسابك.
        </li>
        <li dir="rtl" className="li3">
          إغلاق أي حسابات مرتبطة، و
        </li>
        <li dir="rtl" className="li10">
          اتخاذ إجراء قانوني، بما في ذلك تحميلك المسؤولية عن أي خسائر.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        يرجى الاتصال بنا بشأن إلغاء الحجز أو إغلاق الحساب.
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 5: إلغاء أو تغيير الحجز</b>
      </p>
      <p dir="rtl" className="p13">
        <b>الإلغاء أو التغيير من قبلك</b>
      </p>
      <p dir="rtl" className="p13">
        يمكنك إجراء إلغاءات أو تغييرات (فيما يتعلق بتاريخ السفر، الوجهة، مكان
        بدء الرحلة، العقار أو وسائل النقل) على الحجز من خلال الاتصال بنا. ليس
        لديك حق تلقائي في إلغاء أو تغيير الحجز.
      </p>
      <p dir="rtl" className="p13">
        قد يفرض مزودو السفر رسومًا عليك مقابل إلغاء (كليًا أو جزئيًا) أو تغيير
        الحجز. سيتم توضيح هذه الرسوم في القواعد والقيود. أنت توافق على دفع أي
        رسوم قد تتحملها. يُرجى ملاحظة أنه بالنسبة للتغييرات، سيتم تحديد سعر
        ترتيباتك الجديدة بناءً على السعر المطبق في وقت طلبك لإجراء التغيير. قد
        لا يكون هذا السعر هو نفس السعر الذي حجزت به خدمات السفر في الأصل. تميل
        الأسعار إلى الزيادة كلما اقترب موعد المغادرة.
      </p>
      <p dir="rtl" className="p13">
        يرجى قراءة القواعد والقيود ذات الصلة، حتى تعرف الشروط التي تنطبق على
        حجزك. على سبيل المثال:
      </p>
      <p dir="rtl" className="p13">
        <b>إلغاء الفنادق:</b>
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          <b>الحجوزات القابلة للاسترداد مع غرامة:</b> ستتلقى استردادًا لمبلغ
          الحجز. لا يُسمح بالإلغاءات خلال 24 ساعة من وقت تسجيل الوصول. إذا حاولت
          الإلغاء خلال هذه الفترة، لن يتم إصدار استرداد، وقد يتم فرض المبلغ
          الكامل وفقًا لسياسة الفندق.
        </li>
        <li dir="rtl" className="li10">
          <b>الحجوزات غير القابلة للاسترداد:</b> يعتمد المبلغ المسترد عند
          الإلغاء على سياسات المورد والفندق. في بعض الحالات، قد لا يكون هناك
          استرداد متاح. بعض العقارات لا تسمح بالإلغاءات أو التغييرات بعد إجراء
          الحجز. إذا كانت هذه القيود تنطبق، سيتم إخطارك في وقت الحجز.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        <b>القواعد العامة لجميع الحجوزات:</b>
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          إذا لم تقم بإلغاء أو تغيير حجزك قبل فترة سياسة الإلغاء ذات الصلة، فقد
          تخضع لرسوم إلغاء أو تغيير كما هو محدد في القواعد والقيود ذات الصلة.
        </li>
        <li dir="rtl" className="li3">
          إذا قمت بإجراء حجز "ادفع لاحقًا" وفشلت في الظهور أو إلغاء الحجز، قد
          يفرض العقار رسوم عدم الحضور أو الإلغاء كما هو موضح في القواعد والقيود
          ذات الصلة.
        </li>
        <li dir="rtl" className="li3">
          إذا لم تظهر أو فشلت في استخدام بعض أو كل خدمات السفر المحجوزة، قد يتم
          إصدار استرداد فقط وفقًا للقواعد والقيود ذات الصلة.
        </li>
        <li dir="rtl" className="li10">
          إذا كنت ترغب في إلغاء أو تغيير أي جزء من الحجز وكان هذا الإلغاء أو
          التغيير مسموحًا من قبل مزود السفر ذي الصلة، فإنه بالإضافة إلى أي رسوم
          تفرضها الجهة المقدمة للسفر، قد نفرض عليك أيضًا رسومًا إدارية. إذا كانت
          هذه الرسوم الإدارية تنطبق، سيتم إخطارك بها قبل موافقتك على المضي قدمًا
          في التغيير/الإلغاء.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        <b>الإلغاءات أو التغييرات الأخرى</b>
      </p>
      <p dir="rtl" className="p13">
        لأسباب متنوعة (مثل تجاوز سعة الحجز في العقار بسبب مشكلات الاتصال أو
        إغلاق العقار نتيجة كارثة طبيعية مثل الإعصار، إلخ)، قد يتم إلغاء أو تعديل
        الحجز من قبل مزود السفر أو من قبلنا. في حالة حدوث ذلك، سنبذل جهودًا
        معقولة لإبلاغك في أقرب وقت ممكن وتقديم خيارات بديلة/مساعدة إذا كان ذلك
        متاحًا أو رد المبالغ المدفوعة.
      </p>
      <p dir="rtl" className="p13">
        <b>استرداد المبالغ</b>
      </p>
      <p dir="rtl" className="p13">
        سيتم تحويل أي مبالغ مستردة إلى طريقة الدفع التي استخدمتها لإجراء الحجز
        الأصلي. رسومنا غير قابلة للاسترداد ما لم يُنَص على خلاف ذلك خلال عملية
        الحجز.
      </p>
      <p dir="rtl" className="p13">
        <b>عدم الحضور في الليلة الأولى</b>
      </p>
      <p dir="rtl" className="p13">
        إذا لم تحضر في الليلة الأولى من حجز إقامتك، ولكنك تخطط لتسجيل الوصول
        للليالي التالية، يُرجى التأكيد معنا قبل موعد تسجيل الوصول الأصلي. إذا لم
        تقم بذلك، فقد يتم إلغاء حجزك بالكامل. الاسترداد في حالة عدم الحضور سيتم
        فقط وفقًا للقواعد والقيود ذات الصلة بالعقار.
      </p>
      <p dir="rtl" className="p13">
        <b>الوجبات</b>
      </p>
      <p dir="rtl" className="p13">
        إذا كانت الوجبات مشمولة في حجز إقامتك، فإن عدد الوجبات التي تشملها
        الإقامة يعتمد على عدد الليالي التي حجزتها. يشمل النظام الكامل عادةً
        الإفطار، الغداء، والعشاء. يشمل النظام نصف الوجبات عادةً الإفطار إما مع
        الغداء أو العشاء. لن يكون هناك استرداد إذا لم يتم استهلاك أي من الوجبات.
      </p>
      <p dir="rtl" className="p13">
        <b>إلغاء الرحلات الجوية:</b>
      </p>
      <p dir="rtl" className="p13">
        <b>الإلغاءات:</b>
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          <b>بعد الساعة 12:00 صباحًا في يوم الحجز:</b> يمكن إلغاء الرحلات الجوية
          مع دفع الرسوم المعمول بها. تعتمد رسوم الإلغاء على شركة الطيران، ورسوم
          الإلغاء الخاصة بنا ستكون 8% من التكلفة الإجمالية للحجز.
        </li>
        <li dir="rtl" className="li10">
          <b>لا يتم قبول الإلغاءات خلال 36 ساعة من تاريخ الرحلة:</b> لا يُسمح
          بالإلغاءات خلال 36 ساعة من موعد الرحلة المجدول.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        <b>الإلغاء الكامل (Void):</b>
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          <b>الرحلات الجوية في نفس اليوم: </b>إذا تم إلغاء الرحلة في نفس اليوم،
          فيمكن إلغاؤها دون أي رسوم.
        </li>
        <li dir="rtl" className="li3">
          <b>الرحلات الجوية خلال 36 ساعة:</b> لا يمكن إلغاء الرحلات المجدولة
          خلال 36 ساعة.
        </li>
        <li dir="rtl" className="li10">
          <b>التذاكر غير القابلة للاسترداد:</b> ليست جميع شركات الطيران تسمح
          بالإلغاء الكامل أو الإلغاءات. يُرجى ملاحظة أن بعض التذاكر قد تكون غير
          قابلة للاسترداد.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        <b>التغييرات:</b>
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          <b>لا يتم قبول التغييرات خلال 24 ساعة من تاريخ الرحلة:</b> لا يُسمح
          بإجراء تغييرات على مسار الرحلة خلال 24 ساعة من موعد الرحلة المجدول.
        </li>
        <li dir="rtl" className="li10">
          <b>رسوم التغيير:</b> إذا طلب المسافر تغيير الرحلة، سيتم تطبيق رسوم
          التغيير، بغض النظر عما إذا كان التغيير يتضمن ترقية أو تخفيض. رسوم
          خدمتنا لمعالجة التغييرات هي 15,000 دينار عراقي.
        </li>
      </ul>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 6: السفر الدولي</b>
      </p>
      <p dir="rtl" className="p13">
        <b>السفر الدولي</b>
      </p>
      <p dir="rtl" className="p13">
        على الرغم من أن معظم الرحلات تتم دون حوادث، فإن السفر إلى بعض الوجهات قد
        ينطوي على مخاطر أكبر من غيرها. يجب عليك مراجعة أي تحذيرات أو نصائح سفر
        صادرة عن الحكومات المعنية قبل حجز السفر الدولي. كما يجب عليك متابعة هذه
        التحذيرات والنصائح أثناء السفر وقبل رحلتك العودة لتجنب وتقليل أي
        اضطرابات محتملة.
      </p>
      <p dir="rtl" className="p13">
        <b>الصحة</b>
      </p>
      <p dir="rtl" className="p13">
        يجب عليك التحقق من التطعيمات/اللقاحات الموصى بها والتي قد تتغير في أي
        وقت. ينبغي عليك استشارة طبيبك قبل مغادرتك. أنت المسؤول عن التأكد من:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          تلبية جميع متطلبات الدخول الصحية.
        </li>
        <li dir="rtl" className="li3">
          تلقي التطعيمات/اللقاحات ذات الصلة/المطلوبة.
        </li>
        <li dir="rtl" className="li3">
          تناول جميع الأدوية الموصى بها.
        </li>
        <li dir="rtl" className="li10">
          اتباع جميع النصائح الطبية المتعلقة بسفرك.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        <b>جواز السفر والتأشيرة</b>
      </p>
      <p dir="rtl" className="p13">
        يجب عليك استشارة السفارة أو القنصلية المعنية للحصول على معلومات حول جواز
        السفر والتأشيرة. قد تتغير المتطلبات، لذا تحقق من المعلومات الحديثة قبل
        الحجز والمغادرة واترك وقتًا كافيًا لجميع الطلبات ذات الصلة.
      </p>
      <p dir="rtl" className="p13">
        ليست شركتنا مسؤولة إذا تم رفض دخولك إلى رحلة جوية أو سفينة سياحية (إذا
        كان ذلك ينطبق) أو إلى أي بلد، بسبب سلوكك، بما في ذلك فشلك في حمل الوثائق
        الصحيحة والكافية المطلوبة من قبل أي مزود سفر أو سلطة أو بلد (بما في ذلك
        البلدان التي تمر عبرها). يشمل ذلك جميع المحطات التي تتوقف فيها الطائرة
        أو السفينة السياحية (إذا كان ذلك ينطبق)، حتى إذا لم تغادر الطائرة أو
        المطار أو السفينة السياحية.
      </p>
      <p dir="rtl" className="p13">
        تتطلب بعض الحكومات من شركات الطيران تقديم معلومات شخصية عن جميع
        المسافرين على متن طائراتهم. سيتم جمع البيانات إما في المطار عند تسجيل
        الدخول أو في بعض الحالات عند قيامك بالحجز. يُرجى الاتصال بشركة الطيران
        المعنية التي تسافر معها إذا كانت لديك أي أسئلة حول هذا الأمر.
      </p>
      <p dir="rtl" className="p13">
        لا تقدم شركتنا أي تمثيل أو ضمان بأن السفر إلى الوجهات الدولية ينصح به أو
        خالٍ من المخاطر، وليست مسؤولة عن أي أضرار أو خسائر قد تنتج عن السفر إلى
        تلك الوجهات.
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 7: المسؤولية</b>
      </p>
      <p dir="rtl" className="p13">
        <b>مسؤوليتنا</b>
      </p>
      <p dir="rtl" className="p13">
        نحن نمتلك ونشغل خدمتنا، بينما يقوم مزودو السفر بتقديم خدمات السفر لك.
      </p>
      <p dir="rtl" className="p13">
        إلى أقصى حد يسمح به القانون، فإن شركتنا وشركاءنا لن يكونوا مسؤولين عن:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          أي من خدمات السفر التي يقدمها لك مزودو السفر.
        </li>
        <li dir="rtl" className="li3">
          الأفعال أو الأخطاء أو الإغفالات أو التمثيلات أو الضمانات أو الإهمال من
          قبل أي من مزودي السفر.
        </li>
        <li dir="rtl" className="li10">
          أي إصابات شخصية، وفاة، أضرار في الممتلكات أو أي أضرار أو نفقات أخرى
          ناتجة عن ما سبق.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        يقوم مزودو السفر بتزويدنا بمعلومات تصف خدمات السفر. تشمل هذه المعلومات
        تفاصيل خدمات السفر، الصور، الأسعار والقواعد والقيود ذات الصلة، إلخ. نحن
        نعرض هذه المعلومات من خلال خدمتنا. يتحمل مزودو السفر مسؤولية ضمان أن
        تكون هذه المعلومات دقيقة وكاملة ومحدثة. شركتنا وشركاؤنا لن يكونوا
        مسؤولين عن أي عدم دقة في هذه المعلومات، إلا إذا كانت تلك الأخطاء ناجمة
        عن سبب مباشر من طرفنا. شركتنا وشركاؤنا لا يقدمون أي ضمانات بشأن توفر
        خدمات سفر معينة.
      </p>
      <p dir="rtl" className="p13">
        الصور والرسوم التوضيحية على خدمتنا مقدمة كدليل لإظهار مستوى ونوع الإقامة
        فقط.
      </p>
      <p dir="rtl" className="p13">
        إلى أقصى حد يسمح به القانون، باستثناء ما هو منصوص عليه صراحة في هذه
        الشروط:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          جميع المعلومات، البرمجيات، أو خدمات السفر المعروضة من خلال خدمتنا
          مقدمة دون أي ضمان أو شرط من أي نوع. يشمل ذلك، على سبيل المثال لا
          الحصر، أي ضمانات وشروط ضمنية بشأن الجودة المقبولة، أو القابلية
          للتسويق، أو الملاءمة لغرض معين، أو الملكية أو عدم التعدي.
        </li>
        <li dir="rtl" className="li10">
          مجموعة شركاتنا وشركاؤنا يتنصلون من جميع تلك الضمانات والشروط.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        عرض خدمات السفر من خلال خدمتنا لا يمثل تأييدًا أو توصية لهذه الخدمات من
        قبل مجموعة شركاتنا أو شركائنا. تتنصل مجموعة شركاتنا وشركاؤنا، إلى أقصى
        حد يسمح به القانون، من جميع الضمانات والشروط بأن خدمتنا أو خوادمها أو أي
        بريد إلكتروني مرسل منا أو من شركائنا خالٍ من الفيروسات أو أي مكونات ضارة
        أخرى.
      </p>
      <p dir="rtl" className="p13">
        إلى أقصى حد يسمح به القانون ومع مراعاة القيود الواردة في هذه الشروط، لن
        تكون مجموعة شركاتنا ولا شركاؤنا مسؤولين عن أي خسائر أو أضرار مباشرة أو
        غير مباشرة أو عقابية أو خاصة أو عرضية أو تبعية تنشأ عن:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          خدمات السفر،
        </li>
        <li dir="rtl" className="li3">
          استخدامك لخدمتنا،
        </li>
        <li dir="rtl" className="li3">
          أي تأخير أو عدم قدرة على استخدام خدمتنا، أو
        </li>
        <li dir="rtl" className="li10">
          استخدامك للروابط من خدمتنا،
        </li>
      </ul>
      <p dir="rtl" className="p13">
        سواء كانت قائمة على الإهمال أو العقد أو الضرر أو المسؤولية الصارمة أو
        قوانين حماية المستهلك أو غير ذلك، حتى لو تم إخطار مجموعة شركاتنا
        وشركائنا بإمكانية حدوث مثل هذه الأضرار.
      </p>
      <p dir="rtl" className="p13">
        إذا تبين أن مجموعة شركاتنا أو شركائنا مسؤولون عن أي خسارة أو ضرر بموجب
        هذه الشروط، فإننا، إلى أقصى حد يسمح به القانون، سنكون مسؤولين تجاهك فقط
        عن الأضرار المباشرة التي:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          كانت متوقعة بشكل معقول من قبلك ومن قبلنا (أو من قبل شركائنا كما هو
          مطبق)،
        </li>
        <li dir="rtl" className="li3">
          عانيت منها بالفعل أو تكبدتها، و
        </li>
        <li dir="rtl" className="li10">
          تُعزى مباشرةً إلى أفعالنا (أو أفعال شركائنا كما هو مطبق)،
        </li>
      </ul>
      <p dir="rtl" className="p13">
        وفي حال كانت هناك أي مسؤولية على مجموعة شركاتنا و/أو شركائنا، فإن تلك
        المسؤولية لن تتجاوز بأي حال من الأحوال، في المجمل، (أ) التكلفة التي
        دفعتها مقابل خدمات السفر المعنية أو (ب) مائة وخمسون الف دينار عراقي فقط
        لا غير (150,000 دينار عراقي) أو ما يعادلها بالعملة المحلية، أيهما أكبر.
      </p>
      <p dir="rtl" className="p13">
        تعكس هذه الحدود في المسؤولية توزيع المخاطر بينك وبيننا. ستستمر القيود
        المحددة في هذا القسم وتُطبق حتى إذا تبين أن أي علاج محدود محدد في هذه
        الشروط قد فشل في تحقيق غرضه الأساسي. تستفيد القيود المفروضة على
        المسؤولية المنصوص عليها في هذه الشروط لصالح مجموعة شركاتنا وشركائنا.
      </p>
      <p dir="rtl" className="p13">
        كل حالة من حالات القوة القاهرة، بما في ذلك انقطاع وسائل الاتصال أو
        الإضراب (من قبل شركات الطيران أو العقارات أو مراقبي الحركة الجوية، كما
        هو مطبق)، ستؤدي إلى تعليق الالتزامات الواردة في هذه الشروط والتي تتأثر
        بحدث القوة القاهرة. في هذه الحالة، لن يكون الطرف المتضرر من حدث القوة
        القاهرة مسؤولاً نتيجة لعدم قدرته على الوفاء بهذه الالتزامات.
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>التعويض</b>
      </p>
      <p dir="rtl" className="p13">
        أنت توافق على الدفاع عن وتعويض مجموعة شركاتنا وشركائنا وأي من مسؤوليهم
        أو مديريهم أو موظفيهم أو وكلائهم من وضد أي مطالبات أو دعاوى أو مطالب أو
        استردادات أو خسائر أو أضرار أو غرامات أو عقوبات أو تكاليف أو نفقات أخرى
        من أي نوع أو طبيعة ("الخسائر")، بما في ذلك، على سبيل المثال لا الحصر،
        الرسوم القانونية والمحاسبية المعقولة، التي يقدمها أطراف ثالثة نتيجة لـ:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          خرقك لهذه الشروط أو الوثائق المشار إليها فيها.
        </li>
        <li dir="rtl" className="li3">
          انتهاكك لأي قانون أو حقوق طرف ثالث، أو
        </li>
        <li dir="rtl" className="li10">
          استخدامك لخدمتنا،
        </li>
      </ul>
      <p dir="rtl" className="p13">
        إلى الحد الذي لا تكون فيه هذه الخسائر ناتجة بشكل مباشر عن أفعال مجموعة
        شركاتنا أو شركائنا (حسب الاقتضاء).
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 8: المراجعات والتعليقات والصور</b>
      </p>
      <p dir="rtl" className="p13">
        من خلال تقديم محتوى إلى خدمتنا عبر البريد الإلكتروني أو المشاركات أو غير
        ذلك، بما في ذلك أي مراجعات للعقارات أو صور أو مقاطع فيديو أو أسئلة أو
        تعليقات أو اقتراحات أو أفكار أو ما شابه ذلك (ويشار إليها مجتمعة بـ
        "المشاركات")، فإنك:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          تؤكد أن جميع المشاركات التي تقدمها هي من إبداعك الأصلي وأنك تمتلك
          وستحتفظ بجميع الحقوق اللازمة التي تتيح لنا استخدام المشاركات كما هو
          منصوص عليه في هذه الشروط، و
        </li>
        <li dir="rtl" className="li10">
          تمنح مجموعة شركاتنا وشركائنا، إلى الحد الذي يسمح به القانون، حقًا غير
          حصري وخاليًا من حقوق الملكية ودائمًا وقابلًا للتحويل وغير قابل للإلغاء
          وقابل للترخيص من الباطن بشكل كامل على مستويات متعددة لاستخدام وإعادة
          إنتاج وتعديل وتكييف وترجمة وتوزيع ونشر وإنشاء أعمال مشتقة من وعرض
          وتنفيذ هذه المشاركات علنًا في جميع أنحاء العالم في أي وسائط، سواء
          المعروفة حاليًا أو التي سيتم اختراعها لاحقًا.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        كما تقر وتوافق على أن مجموعة شركاتنا وشركائنا قد يختارون استخدام الاسم
        الذي تقدمه مع تلك المشاركة لنسب مشاركتك (على سبيل المثال، ذكر اسمك الأول
        ومدينة إقامتك في مراجعة تقدمها) وفقًا لتقديرنا وفي شكل غير قابل للتعريف.
        قد يتم أيضًا مشاركة هذه المشاركات مع مزودي السفر.
      </p>
      <p dir="rtl" className="p13">
        كما تمنح مجموعة شركاتنا الحق في اتخاذ الإجراءات القانونية ضد أي شخص أو
        كيان ينتهك حقوقك أو حقوق مجموعة شركاتنا في المشاركات.
      </p>
      <p dir="rtl" className="p13">
        المشاركات ليست سرية وليست ملكية خاصة.
      </p>
      <p dir="rtl" className="p13">
        إذا أمكن، فإنك تتنازل صراحةً عن أي وجميع "الحقوق المعنوية" (بما في ذلك
        حقوق النسبة أو النزاهة) التي قد تكون موجودة في مشاركاتك. أنت توافق على
        أنك لا تعترض على نشر أو استخدام أو تعديل أو حذف أو استغلال مشاركاتك من
        قبل مجموعة شركاتنا أو شركائنا أو أي من المرخص لهم الآخرين.
      </p>
      <p dir="rtl" className="p13">
        أنت مسؤول بالكامل عن محتوى مشاركاتك. يجب ألا تنشر أو ترسل إلى خدمتنا أو
        من خلالها، وتوافق على أن أي مشاركات تقدمها لا تحتوي على أي محتوى:
      </p>
      <ul className="ul1">
        <li dir="rtl" className="li9">
          غير قانوني أو تهديدي أو تشهيري أو افترائي أو فاحش أو إباحي، أو ينتهك
          حقوق النشر أو الخصوصية أو أي قانون.
        </li>
        <li dir="rtl" className="li3">
          تجاري (مثل طلب الأموال أو الإعلان أو تسويق أي بضائع أو خدمات، إلخ).
        </li>
        <li dir="rtl" className="li3">
          ينتهك أو يتعدى أو يخالف أي حقوق طبع أو علامة تجارية أو براءة اختراع أو
          أي حق ملكية آخر لطرف ثالث.
        </li>
        <li dir="rtl" className="li10">
          غير مقبول على أساس المصلحة العامة أو الأخلاق العامة أو النظام العام أو
          الأمن العام أو الانسجام الوطني.
        </li>
      </ul>
      <p dir="rtl" className="p13">
        ستكون وحدك المسؤول عن أي أضرار ناتجة عن عدم الامتثال للقواعد المذكورة
        أعلاه، أو أي ضرر آخر ناتج عن نشر مشاركاتك على خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        يجوز لنا ممارسة حقوقنا (على سبيل المثال: الاستخدام، النشر، العرض، الحذف،
        إلخ) على أي مشاركات دون إشعارك.
      </p>
      <p dir="rtl" className="p13">
        إذا قدمت أكثر من مراجعة واحدة لنفس العقار، فإن أحدث مشاركة لك هي فقط
        المؤهلة للاستخدام.
      </p>
      <p dir="rtl" className="p13">
        نحن لا ندعي ملكية أو تأييدًا أو ارتباطًا بأي مشاركات تقدمها.
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 9: سياسة الملكية الفكرية والإشعارات</b>
      </p>
      <p dir="rtl" className="p13">
        <b>إشعارات حقوق الطبع والعلامات التجارية</b>
      </p>
      <p dir="rtl" className="p13">
        جميع محتويات خدمتنا محمية ©2024 Travelzone.iq، شركة تابعة لمجموعة
        Digital Zone. جميع الحقوق محفوظة. Travelzone.iq وشعار Travelzone.iq هما
        علامتان تجاريتان لـ Travelzone.iq. قد تكون الشعارات وأسماء المنتجات
        والشركات الأخرى المذكورة هنا علامات تجارية لأصحابها المعنيين. نحن غير
        مسؤولين عن المحتوى الموجود على المواقع الإلكترونية التي تديرها أطراف
        أخرى غيرنا.
      </p>
      <p dir="rtl" className="p13">
        قد تحتوي خدمتنا على روابط لمواقع إلكترونية تديرها أطراف أخرى غيرنا.
        تُقدم هذه الروابط لمرجعك فقط. نحن لا نتحكم في تلك المواقع الإلكترونية
        ولا نتحمل مسؤولية محتواها أو استخدامك لها. إن تضميننا لمثل هذه الروابط
        لا يعني أي تأييد للمواد الموجودة على تلك المواقع أو أي ارتباط مع
        مشغليها.
      </p>
      <p dir="rtl" className="p13">
        إذا كنت على علم بانتهاك علامتنا التجارية، يُرجى إعلامنا عبر البريد
        الإلكتروني: res@travelzone.iq.
      </p>
      <p dir="rtl" className="p13">
        <b>سياسة الانتهاك المتعلقة بالملكية الفكرية والشكاوى</b>
      </p>
      <p dir="rtl" className="p13">
        نحن نحترم حقوق الملكية الفكرية للآخرين ونتوقع من موردينا وشركائنا
        ومستخدمينا (ويشار إليهم مجتمعين بـ "المستخدمين") أن يفعلوا الشيء نفسه.
        لدينا سياسة تحظر على المستخدمين نشر المواد التي تنتهك حقوق الطبع أو
        العلامات التجارية أو حقوق الملكية الفكرية الأخرى للآخرين، وفي ظل ظروف
        مناسبة سنقوم بإنهاء حسابات المستخدمين الذين ينتهكون الحقوق بشكل متكرر.
      </p>
      <p dir="rtl" className="p13">
        <b>إشعارات براءات الاختراع</b>
      </p>
      <p dir="rtl" className="p13">
        قد تنطبق براءة اختراع واحدة أو أكثر مملوكة لنا أو لمجموعة شركاتنا على
        خدمتنا وعلى الميزات والخدمات التي يمكن الوصول إليها من خلال خدمتنا. تعمل
        أجزاء من خدمتنا بموجب ترخيص من براءة اختراع واحدة أو أكثر. وهناك براءات
        اختراع أخرى قيد الانتظار.
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 10: البرامج المتاحة على خدمتنا</b>
      </p>
      <p dir="rtl" className="p13">
        <b>البرامج</b>
      </p>
      <p dir="rtl" className="p13">
        أي برنامج يتم إتاحته للتنزيل من خدمتنا أو من متجر التطبيقات ("البرنامج")
        هو عمل محمي بحقوق الطبع والنشر لمجموعة شركاتنا أو لموردينا المعنيين.
        يخضع استخدامك للبرنامج لشروط اتفاقية ترخيص المستخدم النهائي (إن وجدت)
        التي تصاحب البرنامج ("اتفاقية الترخيص"). يجب عليك أولاً الموافقة على
        اتفاقية الترخيص لتثبيت أو تنزيل أو استخدام أي برنامج.
      </p>
      <p dir="rtl" className="p13">
        بالنسبة لأي برنامج لا يصاحبه اتفاقية ترخيص، نمنحك ترخيصًا محدودًا،
        شخصيًا، غير حصري، غير قابل للنقل وغير قابل للترخيص من الباطن لتنزيل
        وتثبيت واستخدام البرنامج لاستخدام خدمتنا بما يتماشى مع هذه الشروط ولأي
        غرض آخر. يتم توفير البرنامج لك مجانًا بدون أي رسوم أو تكاليف.
      </p>
      <p dir="rtl" className="p13">
        جميع البرامج (مثل جميع أكواد HTML وأدوات التحكم Active X، إلخ) الموجودة
        على خدمتنا مملوكة من قبل مجموعة شركاتنا أو شركائنا أو موردينا المعنيين.
        جميع البرامج محمية بقوانين حقوق الطبع والنشر وأحكام المعاهدات الدولية.
        يحظر القانون أي استنساخ أو إعادة توزيع للبرامج وقد يؤدي ذلك إلى عقوبات
        مدنية وجنائية شديدة. سيتم ملاحقة أي شخص ينتهك ذلك قانونيًا.
      </p>
      <p dir="rtl" className="p13">
        دون تقييد ما سبق، يُحظر صراحةً نسخ أو إعادة إنتاج البرامج إلى أي خادم
        آخر أو موقع آخر لإعادة إنتاجها أو توزيعها. يتم ضمان البرامج، إذا كان
        هناك ضمان على الإطلاق، فقط وفقًا لشروط اتفاقية الترخيص.
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 11: خصوصيتك والمعلومات الشخصية</b>
      </p>
      <p dir="rtl" className="p13">
        نحن ملتزمون بالحفاظ على خصوصية وسرية وأمن المعلومات الشخصية التي تم
        ائتماننا عليها.
      </p>
      <p dir="rtl" className="p13">
        يرجى مراجعة بيان الخصوصية الحالي لدينا، الذي يحكم أيضًا استخدامك لخدمتنا
        ويُدرج بالإشارة في هذه الشروط، لفهم ممارساتنا.
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 12: النزاعات والتحكيم</b>
      </p>
      <p dir="rtl" className="p13">
        <b>النزاعات والتحكيم</b>
      </p>
      <p dir="rtl" className="p13">
        يرجى قراءة هذا القسم بعناية. يتطلب هذا القسم أن يتم حل جميع المطالبات من
        خلال التحكيم الملزم أو في محكمة الدعاوى الصغيرة، ويمنعك من متابعة دعوى
        جماعية أو إجراء مشابه في أي محفل. التحكيم مطلوب إذا كان بلد إقامتك يفرض
        اتفاقيات التحكيم. إذا كان بلد إقامتك لا يفرض اتفاقيات التحكيم، فإن
        إجراءات حل النزاعات والإشعار قبل التحكيم والإجراءات الجماعية أو
        التمثيلية المحظورة الموضحة أدناه تظل سارية إلى الحد الذي يمكن إنفاذها
        بموجب القانون.
      </p>
      <p dir="rtl" className="p13">
        نحن ملتزمون برضا المسافرين وحل النزاعات الاستهلاكية بشكل سريع وفعال.
        لدينا عملية حل نزاعات من مرحلتين تشمل: (1) التحقيق والتفاوض بشأن مطالبتك
        مع فريق دعم المسافرين لدينا؛ وإذا لزم الأمر، (2) التحكيم الملزم الذي
        تديره جميع المحاكم العراقية وفقًا للقانون العراقي.
      </p>
      <p dir="rtl" className="p13">
        <b>اتفاقية التحكيم ("اتفاقية التحكيم")</b>
      </p>
      <p dir="rtl" className="p13">
        أنت ونحن نتفق بشكل متبادل على أن أي نزاعات بيننا تنشأ عن أو تتعلق بأي
        شكل من الأشكال بخدمتنا، أو هذه الشروط، أو بيان الخصوصية الخاص بنا، أو أي
        خدمات أو منتجات نقدمها نحن أو أي من شركاتنا التابعة، أو مزودي السفر، أو
        الشركات التي تقدم منتجات أو خدمات من خلالنا، أو أي تعاملات مع وكلاء دعم
        المسافرين لدينا، أو أي تمثيلات نقدمها ("المطالبات")، سيتم حلها عن طريق
        التحكيم الملزم، بدلاً من المحكمة، باستثناء تلك التي يتم حلها في محكمة
        الدعاوى الصغيرة. يشمل ذلك أي مطالبات تقدمها ضدنا، أو ضد شركاتنا التابعة،
        أو مزودي السفر، أو أي شركات تقدم منتجات أو خدمات من خلالنا (والتي تستفيد
        من هذه اتفاقية التحكيم). تكون هذه الاتفاقية ملزمة وتشمل أي مطالبات يتم
        تقديمها من قبل أو ضد أي أطراف ثالثة، بما في ذلك على سبيل المثال لا الحصر
        الأزواج أو الورثة أو المستفيدين من الطرف الثالث أو المعينين، حيث تكون
        المطالبات الأساسية لهم متعلقة باستخدامك لخدمات السفر. إلى الحد الذي يقدم
        فيه أي مستفيد من الطرف الثالث لهذه الشروط مطالبات ضد الكيانات المشمولة
        بهذه الشروط؛ فإن تلك المطالبات ستكون أيضًا خاضعة لهذه اتفاقية التحكيم.
        سيكون المحكم مسؤولًا أيضًا عن تحديد جميع القضايا الأساسية المتعلقة
        بالتحكيم، بما في ذلك على سبيل المثال لا الحصر وجود أو نطاق أو صلاحية
        اتفاقية التحكيم، وأي دفاع ضد التحكيم مثل القضايا المتعلقة بما إذا كانت
        اتفاقية التحكيم هذه قابلة للتنفيذ، أو غير عادلة أو غير واقعية، وأي
        دفاعات ضد التحكيم، بما في ذلك على سبيل المثال لا الحصر الاختصاص القضائي،
        التنازل، التأخير، أو الاستبعاد.
      </p>
      <p dir="rtl" className="p13">
        <b>قضايا محكمة الدعاوى الصغيرة</b>
      </p>
      <p dir="rtl" className="p13">
        على الرغم من أحكام التحكيم المذكورة أعلاه، يجوز لك أو لنا تقديم أي
        مطالبة أو إزالتها في محكمة عراقية إذا كانت المطالبة ضمن حدود الاختصاص
        القضائي لتلك المحكمة؛ بشرط ألا يكون للمحكمة السلطة للنظر في أي مطالبات
        على أساس جماعي أو تمثيلي، أو لتوحيد أو ضم مطالبات أشخاص آخرين أو أطراف
        قد تكون في وضع مماثل في هذه الإجراءات. علاوة على ذلك، إذا كانت المطالبات
        المقدمة في أي طلب تحكيم تقع ضمن حدود الاختصاص القضائي للمحكمة العراقية،
        يجوز لك أو لنا اختيار أن تُسمع المطالبات في محكمة عراقية بدلاً من
        التحكيم، في أي وقت قبل تعيين المحكم عن طريق إخطار الطرف الآخر بذلك
        الاختيار كتابيًا.
      </p>
      <p dir="rtl" className="p13">
        <b>عدم وجود دعاوى جماعية أو إجراءات تمثيلية</b>
      </p>
      <p dir="rtl" className="p13">
        أنت ونحن نتفق على أن جميع الإجراءات لحل المطالبات ستتم على أساس فردي فقط
        وليس كجزء من دعوى جماعية أو موحدة أو تمثيلية، بما في ذلك على سبيل المثال
        لا الحصر كمحامٍ خاص. لا يجوز للمحكمة توحيد أكثر من مطالبة طرف واحد ولا
        يجوز لها النظر في أي شكل من أشكال الإجراءات الجماعية أو التمثيلية. علاوة
        على ذلك، نقر نحن وأنت بأن كلا الطرفين يتنازلان عن الحق في حل المطالبات
        من خلال محاكمة أمام هيئة محلفين، وبدلاً من ذلك، سيتم حل أي مطالبات من
        هذا النوع في محكمة عراقية.
      </p>
      <p dir="rtl" className="p13">
        <b>قواعد التحكيم والقانون الحاكم</b>
      </p>
      <p dir="rtl" className="p13">
        تُعتبر اتفاقية التحكيم هذه "اتفاقية مكتوبة للتحكيم" بموجب القانون
        العراقي. سيتم إدارة التحكيم وفقًا لقواعد التحكيم المطبقة بموجب القانون
        العراقي، باستثناء التعديلات الواردة هنا. يتعين على المحكم تطبيق القانون
        العراقي، بغض النظر عن أي مبادئ تتعلق بتعارض القوانين. لا تنطبق القوانين
        الأجنبية. لا يمكن تعديل اتفاقية التحكيم هذه إلا باتفاق متبادل وبشكل
        مكتوب.
      </p>
      <p dir="rtl" className="p13">
        <b>حل النزاعات والإشعار الإجباري قبل التحكيم</b>
      </p>
      <p dir="rtl" className="p13">
        قبل بدء التحكيم، توافق على منحنا الفرصة لحل أي مطالبات من خلال إخطارنا
        كتابيًا وبذل جهد حسن النية للتفاوض على حل غير رسمي. يجب عليك إرسال إشعار
        نزاع مكتوب وموقع ("الإشعار") عبر البريد المسجل، موجه إلى: قسم الشؤون
        القانونية / بند حل النزاعات، Travel Zone، بغداد / حي حطين / محلة 620 /
        شارع 26 بناية رقم 2/60، العراق.
      </p>
      <p dir="rtl" className="p13">
        يجب أن يحتوي الإشعار على المعلومات التالية: (1) اسمك، (2) عنوانك، (3)
        عنوان البريد الإلكتروني الذي استخدمته لإجراء الحجز، (4) رقم هاتفك الذي
        استخدمته لإجراء الحجز، (5) وصف موجز لطبيعة شكواك، (6) الحل الذي تسعى
        إليه، و (7) توقيعك.
      </p>
      <p dir="rtl" className="p13">
        <b>بدء التحكيم</b>
      </p>
      <p dir="rtl" className="p13">
        يجب على الطرف الذي يبدأ التحكيم ضدنا إرسال طلب التحكيم المكتوب إلى قسم
        الشؤون القانونية / بند حل النزاعات، Travel Zone، بغداد، حي حطين، محلة
        620، شارع 26، بناية رقم 2/60، العراق، بالتزامن مع تقديم الطلب.
      </p>
      <p dir="rtl" className="p13">
        يجب أن يتم التحكيم بواسطة محكم واحد يتم اختياره إما وفقًا لقواعد التحكيم
        العراقية المعمول بها أو باتفاق متبادل بينك وبيننا. يجب أن يتم التحكيم
        إما: (أ) في محكمة تحكيم في العراق؛ (ب) في أي موقع آخر يتم الاتفاق عليه
        بشكل متبادل بينك وبيننا؛ (ج) عبر مؤتمر فيديو؛ أو (د) بناءً على اختيارك،
        إذا كانت المطالبات الوحيدة في التحكيم مقدمة من قبلك وكانت تقل عن 40,000
        دولار في المجمل، عبر الهاتف أو من خلال تقديم مكتوب.
      </p>
      <p dir="rtl" className="p13">
        <b>القسم 13: أحكام عامة</b>
      </p>
      <p dir="rtl" className="p13">
        <b>القانون الحاكم والاختصاص القضائي</b>
      </p>
      <p dir="rtl" className="p13">
        تحكم هذه الشروط بقوانين العراق، بغض النظر عن مبادئ تنازع القوانين.
      </p>
      <p dir="rtl" className="p13">
        <b>التأمين</b>
      </p>
      <p dir="rtl" className="p13">
        ما لم يُنص على خلاف ذلك، فإن الأسعار المعروضة لا تشمل التأمين على السفر.
        يُنصح بالحصول على تأمين يغطي عواقب حالات إلغاء معينة وبعض المخاطر (مثل
        تكلفة الإعادة إلى الوطن في حالة وقوع حادث أو مرض). أنت المسؤول عن التأكد
        من أن أي بوليصة تأمين يتم الحصول عليها تغطي متطلباتك بشكل كافٍ. قد يتم
        عرض بعض منتجات التأمين على السفر. إذا كان الأمر كذلك، فسيتم عرض تفاصيل
        مزود التأمين والمعلومات الرئيسية ذات الصلة والشروط والأحكام على خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        <b>الفشل في التنفيذ</b>
      </p>
      <p dir="rtl" className="p13">
        إن عدم قيامنا أو تأخرنا في تنفيذ أي بند من هذه الشروط لا يعني التنازل عن
        حقنا في تنفيذ نفس البند أو أي بنود أخرى من هذه الشروط في المستقبل.
      </p>
      <p dir="rtl" className="p13">
        <b>الأحكام غير القابلة للتنفيذ</b>
      </p>
      <p dir="rtl" className="p13">
        إذا وجدت محكمة أو سلطة مختصة أن أي بند (أو جزء من بند) من هذه الشروط غير
        صالح أو غير قانوني أو غير قابل للتنفيذ، فإن ذلك البند (أو جزء البند)
        سيُعتبر، إذا لزم الأمر، غير مكون من هذه الشروط معك. في مثل هذه الحالة،
        لن تتأثر صلاحية وقابلية تنفيذ الأحكام الأخرى.
      </p>
      <p dir="rtl" className="p13">
        <b>الاتفاق الكامل</b>
      </p>
      <p dir="rtl" className="p13">
        تشكل هذه الشروط الاتفاق الكامل بينك وبيننا فيما يتعلق بخدمتنا. تحل هذه
        الشروط محل جميع الاتصالات السابقة أو المتزامنة (سواء كانت إلكترونية أو
        شفهية أو كتابية) بينك وبيننا بشأن خدمتنا.
      </p>
      <p dir="rtl" className="p13">
        <b>التنازل</b>
      </p>
      <p dir="rtl" className="p13">
        يجوز لنا، ولا يجوز لك التنازل أو التعاقد من الباطن أو تفويض الحقوق أو
        الواجبات أو الالتزامات بموجب هذه الشروط.
      </p>
      <p dir="rtl" className="p13">
        <b>حقوق الأطراف الثالثة</b>
      </p>
      <p dir="rtl" className="p13">
        ما لم يُنص صراحةً على خلاف ذلك في هذه الشروط، لا ننوي أن يكون لأي جزء من
        هذه الشروط قابلية التنفيذ من قبل أي شخص ليس طرفًا في هذه الشروط. ولا
        يتطلب التنازل أو التغيير أو إنهاء أي جزء من هذه الشروط موافقة أي طرف
        ثالث. هذه الشروط لا تنشئ أي حقوق بموجب أي قوانين أو لوائح معمول بها
        تتعلق بحقوق الأطراف الثالثة في تنفيذ أي جزء من هذه الشروط.
      </p>
      <p dir="rtl" className="p13">
        <b>استمرارية الالتزامات</b>
      </p>
      <p dir="rtl" className="p13">
        أي بند من هذه الشروط يفرض التزامات تتجاوز انتهاء أو إنهاء هذه الشروط،
        سيظل ساريًا بعد انتهاء أو إنهاء هذه الشروط.
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
      <p dir="rtl" className="p14">
        <br />
      </p>
    </>
  );
};
const MoreDetailsDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const t = useTranslations();
  const locale = useLocale();
  return (
    <SwipeableDrawer
      anchor="bottom"
      onClose={onClose}
      onOpen={() => {}}
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          borderRadius: "32px 32px 0 0",
          height: "90vh",
          paddingBottom: "env(safe-area-inset-bottom)",
          padding: "16px",
        },
      }}
    >
      <Puller />
      <h2
        className="text-title my-6 font-semibold"
        dir={locale === "ar" ? "rtl" : "ltr"}
      >
        {t("flights.termsAndConditions")}
      </h2>
      {locale === "ar" ? <ArabicTerms /> : <EnglishTerms />}
    </SwipeableDrawer>
  );
};

export default MoreDetailsDrawer;
