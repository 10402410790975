"use client";

import React, { ReactNode, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useLocaleInfo } from "../hooks/useLocaleInfo";

export const MuiThemeProvider = ({ children }: { children: ReactNode }) => {
  const { dir } = useLocaleInfo();

  const theme = useMemo(
    () =>
      createTheme({
        direction: dir,
        components: {
          MuiBottomNavigation: {
            styleOverrides: {
              root: {
                "& .MuiBottomNavigationAction-label": {
                  marginTop: "2px",
                  fontWeight: "bold",
                },
                "& .Mui-selected": {
                  color: "#003E2B",
                  zIndex: 2,
                  "& .MuiTouchRipple-root": {
                    position: "absolute",
                    top: 0,
                    left: "20%",
                    maxWidth: "75px",
                    opacity: 0.4,
                    borderRadius: "50%",
                    background:
                      "radial-gradient(circle at 50% 50%, #A7FDC3 40%, #FFFFFF 60%)",
                    zIndex: -1,
                  },
                },
              },
            },
          },
          MuiSlider: {
            styleOverrides: {
              thumb: {
                transform: `translate(${dir === "rtl" ? 50 : -50}%, -50%)`,
              },
            },
          },
          MuiSnackbarContent: {
            styleOverrides: {
              root: {
                maxWidth: "var(--container-2xs)",
                borderRadius: "var(--radius-lg)",
                backgroundColor: "var(--color-zinc-800)",
                boxShadow: "var(--shadow-md)",
              },
            },
          },
        },
      }),
    [dir],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
