import React from "react";
import { Plus, Minus } from "@phosphor-icons/react";

interface CounterProps {
  value: number;
  maxValue?: number;
  minValue?: number;
  onChange: (newValue: number) => void;
}

export default function CounterButton({
  value,
  maxValue,
  minValue,
  onChange,
}: CounterProps) {
  return (
    <div className="flex w-fit items-center gap-4 rounded-2xl border border-solid p-3">
      <button
        className="h-5 w-5"
        onClick={() => onChange(Math.max(value - 1, minValue || 0))}
      >
        <Minus size={20} color="#475162" weight="bold" />
      </button>
      <span className="flex w-3 justify-center">{value}</span>
      <button
        className="h-5 w-5"
        onClick={() => onChange(Math.min(value + 1, maxValue || 9))}
      >
        <Plus size={20} color="#475162" weight="bold" />
      </button>
    </div>
  );
}
