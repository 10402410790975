import HotelsList from "@/app/ui/hotels-list/HotelsList";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/hotels/list")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <main className="main no-footer">
      <HotelsList />
    </main>
  );
}
