"use client";

import { useEffect, useState } from "react";
import { X } from "@phosphor-icons/react/dist/ssr";
import { SwipeableDrawer } from "@mui/material";
import { Puller } from "../StyledTz";
import { StopsOptions, SortOrder } from "@/app/lib/types/enums";
import { useFlightDetailsProvider } from "@/app/lib/context/FlightDetailsContext";
import { useTranslations } from "use-intl";
import { useFlightsSearch } from "@/app/lib/hooks/useFlightsSearch";
import type { IFlightFilters } from "@/app/lib/types/interfaces";
import Header from "@/app/ui/flight-list/Header";
import AnimatedLoader from "@/app/ui/AnimatedLoader";
import PageLoader from "@/app/ui/PageLoader";
import SingleAvailableFlight from "@/app/ui/SingleAvailableFlight";
import FlightsFilters from "./FlightsFilters";
import FlightsSort from "@/app/ui/flight-list/FlightsSort";
import InfiniteScroll from "react-infinite-scroll-component";
import NoResultsError from "../NoResultsError";
import PageError from "../PageError";
import { useFlightSelectionPayloadContext } from "@/app/lib/context";
import { WhatsAppButton } from "../WhatsAppButton";

enum DrawerComponent {
  FLIGHTS_FILTERS = "FLIGHTS_FILTERS",
  FLIGHTS_SORT = "FLIGHTS_SORT",
}

function FlightList() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortValue, setSortValue] = useState(SortOrder.ASC);

  const [drawerComponent, setDrawerComponent] = useState("");
  const [filters, setFilters] = useState<IFlightFilters>({
    stops: StopsOptions.ANY,
    sortField: "price",
    priceRange: [1, 100000000],
    selectedAirlines: [],
    airlines: [],
  });

  const t = useTranslations();
  const { searchData, isReady } = useFlightDetailsProvider();
  const {
    offers,
    isError,
    backendFilters,
    hasReachedEnd,
    searchResultKey,
    searchResultToken,
    isEmpty,
    size,
    setSize,
    isValidating,
  } = useFlightsSearch(searchData!, isReady, filters, sortValue);

  const handleFiltersChange = (newFilters: Partial<IFlightFilters>) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
    setDrawerOpen(false);
  };

  const minPrice = backendFilters?.minPrice || 1;
  const maxPrice = backendFilters?.maxPrice || 100000000;

  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      airlines: backendFilters?.airlines || [],
      priceRange: [minPrice, maxPrice],
    }));
  }, [backendFilters, minPrice, maxPrice]);

  const handleSortChange = (newSortValue: SortOrder) => {
    setSortValue(newSortValue);
    setDrawerOpen(false);
  };

  const handleDrawerOpen = (component: DrawerComponent) => {
    setDrawerOpen(true);
    setDrawerComponent(component);
  };

  const { handleFlightSelection } = useFlightSelectionPayloadContext();

  if (isError) {
    return <PageError />;
  }

  if (!isReady || isValidating) {
    return (
      <PageLoader>
        <AnimatedLoader icon="airplane" />
        <h3 className="text-primary mt-3 font-normal">
          {t("flights.preparingOffers")}
        </h3>
      </PageLoader>
    );
  }

  return (
    <div>
      <Header
        selectedSortOption={sortValue}
        backendFilters={backendFilters}
        filtersHandler={() => {
          handleDrawerOpen(DrawerComponent.FLIGHTS_SORT);
        }}
        sortHandler={() => {
          handleDrawerOpen(DrawerComponent.FLIGHTS_FILTERS);
        }}
      />

      <WhatsAppButton />

      {isEmpty ? (
        <NoResultsError tab="flights" />
      ) : (
        <div className="w-full overflow-y-auto px-4" id="scrollableDiv">
          <InfiniteScroll
            dataLength={offers.length}
            scrollableTarget="scrollableDiv"
            next={() => {
              setSize(size + 1);
            }}
            hasMore={!hasReachedEnd}
            loader={
              <div className="mt-3 flex flex-col items-center justify-center">
                <AnimatedLoader icon="airplane" />
                <h3 className="text-primary mt-3 font-normal">
                  {t("flights.preparingOffers")}
                </h3>
              </div>
            }
            endMessage={<div>{t("flights.noResults")}</div>}
          >
            <div className="flex justify-end">
              <h4 className="text-title font-medium">
                {t("flights.availableFlights")}
              </h4>
            </div>
            {offers?.map((itinerary) => (
              <SingleAvailableFlight
                key={itinerary.index + (itinerary as any).itineraryIndex}
                itinerary={itinerary}
                onClick={() => {
                  handleFlightSelection(
                    {
                      offerId: itinerary.index as unknown as string,
                      itineraryIndex: (itinerary as any).itineraryIndex,
                      searchResultKey,
                      searchResultToken,
                    },
                    itinerary,
                  );
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        disableSwipeToOpen={false}
        disableBackdropTransition={true}
        sx={{
          "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
            borderRadius: "32px 32px 0 0",
            touchAction: "pan-y",
            paddingBottom: "env(safe-area-inset-bottom)",
            height:
              drawerComponent === DrawerComponent.FLIGHTS_SORT
                ? "30vh"
                : "90vh",
          },
        }}
      >
        {DrawerComponent.FLIGHTS_SORT == drawerComponent ? (
          <>
            <button
              className="absolute top-7 left-4 hover:cursor-pointer"
              onClick={() => setDrawerOpen(false)}
              aria-label="close drawer"
            >
              <X size={20} color="#0966D7" />
            </button>
            <div
              className="flex w-full justify-center"
              style={{
                padding: "24px 0 13px 0",
              }}
            >
              <h2 className="font-medium">{t("flights.sort")}</h2>
            </div>
            <FlightsSort
              sortValue={sortValue}
              setSortValue={handleSortChange}
            />
          </>
        ) : (
          <>
            <Puller />
            <div
              className="flex w-full justify-center"
              style={{
                padding: "24px 0 13px 0",
              }}
            >
              <h2 className="font-medium">{t("flights.filters")}</h2>
            </div>
            <FlightsFilters
              handleFiltersChange={handleFiltersChange}
              filters={filters}
              minPrice={minPrice}
              maxPrice={maxPrice}
            />
          </>
        )}
      </SwipeableDrawer>
    </div>
  );
}

export default FlightList;
