import { TFlightFormValues } from "@/app/lib/types/types";
import type {
  IFlightSearchPostData,
  IFlightFilters,
} from "@/app/lib/types/interfaces";
import type {
  FindFlightOffersOutputData,
  InitiateFlightsSearchBodyInputDto,
} from "@/app/lib/types/codegen";
import { useMemo } from "react";
import { TripType } from "@/app/lib/types/enums";
import api, { my } from "@/app/lib/api/api";
import dayjs from "dayjs";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";

const PAGE_SIZE = 20;

const transformer = (
  data: TFlightFormValues,
): InitiateFlightsSearchBodyInputDto => {
  const transformedData: InitiateFlightsSearchBodyInputDto = {
    originDestinations: [
      {
        origin: {
          id: data.from.id as number,
          type: data.from?.airports ? "CITY" : "AIRPORT",
        },
        destination: {
          id: data.to.id as number,
          type: data.to?.airports ? "CITY" : "AIRPORT",
        },
        date: dayjs(data.dates.departure).format("YYYY-MM-DD"),
      },
    ],
    cabinClass: data.cabinClass,
    passengers: {
      passengerTypeCodeList: [],
    },
  };
  if (data.tripType === TripType.ROUND_TRIP) {
    transformedData.originDestinations.push({
      origin: {
        id: data.to.id as number,
        type: data.to?.airports ? "CITY" : "AIRPORT",
      },
      destination: {
        id: data.from.id as number,
        type: data.from?.airports ? "CITY" : "AIRPORT",
      },
      date: dayjs(data.dates.return).format("YYYY-MM-DD"),
    });
  }
  data.adult &&
    transformedData.passengers.passengerTypeCodeList.push({
      passengerTypeCode: "ADT",
      count: data.adult,
    });
  data.child &&
    transformedData.passengers.passengerTypeCodeList.push({
      passengerTypeCode: "CHD",
      count: data.child,
    });
  data.infantWithoutSeat &&
    transformedData.passengers.passengerTypeCodeList.push({
      passengerTypeCode: "INF",
      count: data.infantWithoutSeat,
    });
  return transformedData;
};

// Fetcher Function for token
const flightsTokenFetcher = ({
  url,
  searchParams,
  sortOrder,
}: {
  url: string;
  searchParams: IFlightSearchPostData;
  sortOrder: "ASC" | "DESC";
}) => {
  return api
    .post(url, searchParams, {
      params: { perPage: PAGE_SIZE, sortField: "price", sortOrder },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      my.showToast({
        type: "exception",
        content: `Failed to fetch flights. ${err.toString()}`,
        duration: 3000,
      });
      throw err;
    });
};

// Fetcher Function for flights
const flightsFetcher = ({ url, params }: { url: string; params: any }) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      throw err;
    });
};

export function useFlightsSearch(
  searchParams: TFlightFormValues,
  isReady: boolean,
  filters: IFlightFilters,
  sortOrder: "ASC" | "DESC",
) {
  const {
    data,
    error: tokenError,
    isLoading: isTokenLoading,
    isValidating: isTokenValidating,
  } = useSWR(
    () =>
      isReady
        ? {
            url: `/flights/search`,
            searchParams: transformer(searchParams),
            sortOrder,
          }
        : null,
    flightsTokenFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 3600 * 1000,
    },
  );

  const postDataFlightReturnParams = useMemo(() => {
    if (data) {
      return {
        maxPrice: data.data.maxPrice,
        minPrice: data.data.minPrice,
        searchResultKey: data.data.searchResultKey,
        searchResultToken: data.data.searchResultToken,
        carriers: data.data.carriers.map((c: any) => c.code),
        airlines: data.data.carriers,
      };
    }
  }, [data]);

  const { airlines, ...postReturnFilters } = postDataFlightReturnParams || {
    airlines: [],
  };
  const {
    data: flightsData,
    error,
    size,
    setSize,
    isLoading,
    isValidating,
  } = useSWRInfinite<FindFlightOffersOutputData>(
    (index) =>
      data
        ? {
            url: `/flights`,
            params: {
              ...postReturnFilters,
              perPage: PAGE_SIZE,
              page: index + 1,
              sortField: filters.sortField,
              minPrice: filters.priceRange[0],
              carriers: filters.selectedAirlines,
              maxPrice: filters.priceRange[1],
              sortOrder,
              stops: filters.stops,
            },
          }
        : null,
    flightsFetcher,
    {
      initialSize: 1,
      revalidateOnFocus: false,
      revalidateFirstPage: false,
      dedupingInterval: 5000,
    },
  );

  const { offers } = useMemo(() => {
    if (flightsData) {
      const flatData = [
        ...flightsData.flatMap((page) => {
          return page;
        }),
      ];
      return {
        offers: [...flatData.map((page) => page.offers)],
      };
    }
    return { offers: [] };
  }, [flightsData]);

  const isEmpty = flightsData?.[0]?.offers.length === 0;
  const hasReachedEnd =
    isEmpty || (data && !flightsData?.[flightsData.length - 1]?.hasMore);

  return {
    offers: offers?.flat() || [],
    isEmpty,
    setSize,
    searchResultToken: data?.data.searchResultToken,
    searchResultKey: data?.data.searchResultKey,
    size,
    hasReachedEnd,
    backendFilters: postDataFlightReturnParams,
    isLoading: isLoading || isTokenLoading,
    isValidating: isValidating || isTokenValidating,
    isError: tokenError || error,
  };
}
