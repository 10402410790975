import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../AnimatedLoader";
import { useTranslations } from "use-intl";
import NoResultsError from "../NoResultsError";
import type { FindUserHotelsReservationsReservation } from "@/app/lib/types/codegen";
import { useHotelsReservations } from "@/app/lib/hooks/useHotelsReservations";
import { MyHotelCard } from "./MyHotelCard";

export const MyHotelsList = ({
  onReservationClick,
}: {
  onReservationClick: (flight: FindUserHotelsReservationsReservation) => void;
}) => {
  const t = useTranslations();
  const { data, setSize, size, hasMore, isLoading } = useHotelsReservations();

  const isEmpty = data?.length === 0;

  if (isEmpty && !isLoading) return <NoResultsError tab="hotels" />;

  return (
    <InfiniteScroll
      className="mt-2"
      dataLength={
        data
          ?.flatMap((page) => page.data.reservations.length)
          .reduce((prev, cur) => prev + cur, 0) || 0
      }
      scrollableTarget="scrollableDiv"
      next={() => {
        setSize(size + 1);
      }}
      hasMore={hasMore}
      loader={
        <div className="flex flex-col items-center justify-center">
          <Loader icon="buildings" />
          <h3 className="text-primary mt-3 font-normal">
            {t("hotels.preparingOffers")}
          </h3>
        </div>
      }
      endMessage={
        <div className="text-tertiary mb-9 flex flex-col items-center justify-center gap-3 p-8">
          {t("hotels.noResults")}
        </div>
      }
    >
      {data?.map((page) =>
        page.data.reservations.map((reservation) => (
          <MyHotelCard
            onClick={() => onReservationClick(reservation)}
            key={reservation.id}
            reservation={reservation}
          />
        )),
      )}
    </InfiniteScroll>
  );
};
