export const FLIGHTS = () => "/flights";
export const HOTELS = () => "/hotels";

export const HOTEL = () => "/hotel";

export const HOTELS_LIST = () => "/hotels-list";
export const FLIGHT_LIST = () => "/flight-list";

export const HOME = () => "/";
export const TRAVELLERS = () => "/travellers";
export const TRIPS = () => "/trips";

export const FLIGHT_PURCHASE = () => "/flight-purchase";
export const FLIGHT_PURCHASE_STEP_1 = () => "/flight-purchase/step-1";
export const FLIGHT_PURCHASE_STEP_2 = () => "/flight-purchase/step-2";
export const FLIGHT_PURCHASE_STEP_3 = () => "/flight-purchase/step-3";
export const FLIGHT_BOOKING = () => "/flight-purchase/flight-booking";
export const HOTEL_BOOKING = (step: number) => `/hotel-purchase/${step}`;
export const BASE_API_URL =
  import.meta.env.VITE_PUBLIC_ENVIRONMENT === "production"
    ? "https://api.travelzone.app/api/v1"
    : "https://travelzone.digitalzone-dev.net/api/v1";
// TZ SUPPORTS WHATSAPP NUMBER
//export const BASE_API_URL = "https://travelzone.digitalzone-dev.net/api/v1";
export const WHATSAPP_URL = "https://wa.me/+9647766850489";
