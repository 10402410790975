"use client";

import {
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from "@mui/material";
import clsx from "clsx";
import { useLocale, useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

// FIXME: Merge with ScopeSwitcher
export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: "none",
  backgroundColor: "transparent",
  textTransform: "none",
  color: "var( --tz-tertiary)",
  [`&.${toggleButtonClasses.selected}`]: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    align: "center",
    borderRadius: "16px",
    backgroundColor: "white !important",
    color: "black !important",
  },
}));

interface CategorySwitcherProps {
  current: "flights" | "hotels";
}

export const CategorySwitcher = ({ current }: CategorySwitcherProps) => {
  const locale = useLocale();
  const t = useTranslations();
  const navigate = useNavigate();

  return (
    <div className="sticky top-10 z-10 flex justify-center rounded-3xl">
      <ToggleButtonGroup
        color="primary"
        value={current || "flights"}
        exclusive
        onChange={(_, newValue) => {
          navigate({
            to: "/trips",
            search: { category: newValue || current },
          });
        }}
        aria-label="Platform"
        className="flex rounded-3xl border-none"
        sx={{
          backgroundColor: "var(--tz-gray-500)",
          borderRadius: "20px",
          padding: "4px !important",
          height: "46px",
        }}
      >
        <StyledToggleButton
          value={"flights"}
          className={clsx("min-w-28", {
            "order-2": locale === "ar",
          })}
        >
          {t("reservations.flight.title")}
        </StyledToggleButton>
        <StyledToggleButton value={"hotels"} className="min-w-28">
          {t("reservations.hotel.title")}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
