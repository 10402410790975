import { TextField } from "../StyledTz";
import { Button } from "@mui/material";
import { useLocale, useTranslations } from "use-intl";
import clsx from "clsx";
import { FormikProps } from "formik";
import { TCity, THotelFormValues } from "@/app/lib/types/types";
import { useState } from "react";
import { useHotelsSearch } from "@/app/lib/hooks/useHotelsSearch";
import { initialHotelsValues } from "@/app/lib/initialHotelsValues";
import { X } from "@phosphor-icons/react";

function DestinationSearch({
  onDrawerClose,
  formProps,
}: {
  onDrawerClose: () => void;
  formProps: FormikProps<THotelFormValues>;
}) {
  const [input, setInput] = useState(formProps.values.destination.name);
  const locale = useLocale();
  const t = useTranslations();
  const { data } = useHotelsSearch(input);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value.slice(0, 30);
    setInput(input);
  };

  const onClick = (value: TCity) => {
    formProps.setFieldValue("destination", value);
    onDrawerClose();
  };

  const resetDestination = () => {
    formProps.setFieldValue("destination", initialHotelsValues.destination);
    setInput("");
  };

  return (
    <div className="w-full p-4">
      <div className="relative flex w-full flex-col gap-2">
        <TextField
          name="destination"
          fullWidth
          value={input}
          variant="filled"
          dir="rtl"
          onChange={onChange}
          InputProps={{
            endAdornment: formProps.values.destination.name && (
              <button onClick={resetDestination} className="absolute left-3">
                <X size={20} color="#6A778A" />
              </button>
            ),
          }}
          onBlur={formProps.handleBlur}
          label={t("hotels.search.destination")}
        />
      </div>
      {data?.cities?.length > 0 ? (
        <>
          <div
            className={clsx("w-full py-3", {
              "text-right": locale === "ar",
            })}
          >
            <span className="text-xs font-medium" style={{ color: "#5E6B7D" }}>
              {t("hotels.search.popular")}
            </span>
          </div>
          <ul>
            {data.cities.map((city: any) => (
              <li key={city.id}>
                <Button
                  onClick={() => {
                    onClick(city);
                  }}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    padding: "0",
                  }}
                  className={clsx("flex w-full gap-1", {
                    "flex-row-reverse": locale === "ar",
                  })}
                >
                  <div
                    className={clsx("flex w-full items-center gap-1 py-3", {
                      "flex-row-reverse": locale === "ar",
                    })}
                  >
                    <span className="text-label text-base font-medium capitalize">
                      {city.name.toLowerCase()}
                    </span>
                    <span className="text-sm text-slate-500">
                      {city.countryCode}
                    </span>
                    <span className={"text-sm text-slate-500"}>
                      {city.countryName},
                    </span>
                  </div>
                </Button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="text-placeholder mt-6 flex justify-center text-sm">
          {t("hotels.search.enterName")}
        </div>
      )}
    </div>
  );
}

export default DestinationSearch;
