"use client";

import React, { createContext, useContext, useState, ReactNode } from "react";
import { initialHotelsValues } from "../initialHotelsValues";
import { THotelSearchResult } from "../types/types";

interface IHotelDataContext {
  hotelData: THotelSearchResult;
  setHotelData: (data: THotelSearchResult) => void;
}

const defaultContextValue: IHotelDataContext = {
  hotelData: {
    id: 0,
    name: "",
    address: "",
    specialDeal: false,
    stars: 4,
    mainImageUrl: "",
    imagesUrls: [],
    minPrice: {
      value: 0,
      currency: "",
    },
    boardBasis: [],
  },
  setHotelData: () => {},
};

const HotelDataContext = createContext<any>(defaultContextValue);

export const HotelDataProvider = ({ children }: { children: ReactNode }) => {
  const [hotelData, setHotelData] = useState(initialHotelsValues);

  return (
    <>
      <HotelDataContext.Provider value={{ hotelData, setHotelData }}>
        {children}
      </HotelDataContext.Provider>
    </>
  );
};

export const useHotelDataProvider = () => useContext(HotelDataContext);
