import { type NavigateOptions, useMatchRoute } from "@tanstack/react-router";

interface ActiveStepData {
  stepNumber: number;
  stepTitle: string;
  step: number;
  backRoute: NavigateOptions;
  nextRoute?: NavigateOptions;
  buttonLabel: string;
}

const useActiveStepData = (): ActiveStepData => {
  const matches = useMatchRoute();

  if (matches({ from: "/", to: "/flights/offer/$id/step/1" })) {
    return {
      stepNumber: 1,
      stepTitle: "flights.selectFlight",
      step: 5,
      backRoute: { to: "/flights/list" },
      nextRoute: { to: "/flights/offer/$id/step/2" },
      buttonLabel: "flights.continue",
    };
  }

  if (matches({ from: "/", to: "/flights/offer/$id/step/2" })) {
    return {
      stepNumber: 2,
      stepTitle: "travellers.title",
      step: 4,
      backRoute: { to: "/flights/offer/$id/step/1" },
      nextRoute: { to: "/flights/offer/$id/step/3" },
      buttonLabel: "flights.continue",
    };
  }
  if (matches({ from: "/", to: "/flights/offer/$id/step/3" })) {
    return {
      stepNumber: 3,
      stepTitle: "bookingConfirmation.title",
      step: 3,
      backRoute: { to: "/flights/offer/$id/step/2" },
      nextRoute: { to: "/flights/offer/$id/step/final" },
      buttonLabel: "flights.purchaseTicket",
    };
  }

  return {
    stepNumber: 1,
    stepTitle: "flights.selectFlight",
    step: 5,
    backRoute: { to: "/flights/list" },
    buttonLabel: "",
  };
};

export default useActiveStepData;
