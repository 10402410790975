import { useHotelDataProvider } from "@/app/lib/context/HotelDataProvider";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_hotel-offer/hotels/$id/images",
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { hotelData } = useHotelDataProvider();
  const navigate = Route.useNavigate();

  return (
    <>
      <div className="grid w-full grid-cols-2 gap-2 px-4 py-4">
        {hotelData.imagesUrls.map((url: string, index: number) => (
          <button
            type="button"
            key={url}
            className="relative h-32 overflow-hidden"
            onClick={() => {
              navigate({
                to: "/hotels/$id/fullscreen-gallery",
                search: { index },
                params: { id: `${hotelData.id}` },
              });
            }}
          >
            <img
              src={url}
              alt={hotelData.name}
              className="h-full w-full rounded-lg object-cover object-center"
            />
          </button>
        ))}
      </div>
    </>
  );
}
