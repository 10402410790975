/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as AuthenticatedTripsImport } from './routes/_authenticated/trips'
import { Route as AuthenticatedTravelersImport } from './routes/_authenticated/travelers'
import { Route as AuthenticatedHotelOfferImport } from './routes/_authenticated/_hotel-offer'
import { Route as AuthenticatedFlightOfferImport } from './routes/_authenticated/_flight-offer'
import { Route as AuthenticatedHotelsListImport } from './routes/_authenticated/hotels.list'
import { Route as AuthenticatedFlightsListImport } from './routes/_authenticated/flights.list'
import { Route as AuthenticatedTripsScopeIdImport } from './routes/_authenticated/trips.$scope.$id'
import { Route as AuthenticatedHotelOfferHotelsIdImagesImport } from './routes/_authenticated/_hotel-offer/hotels.$id.images'
import { Route as AuthenticatedHotelOfferHotelsIdFullscreenGalleryImport } from './routes/_authenticated/_hotel-offer/hotels.$id.fullscreen-gallery'
import { Route as AuthenticatedHotelOfferHotelsIdStepImport } from './routes/_authenticated/_hotel-offer/hotels.$id.$step'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStepFinalImport } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.final'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStep3Import } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.3'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStep2Import } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.2'
import { Route as AuthenticatedFlightOfferFlightsOfferIdStep1Import } from './routes/_authenticated/_flight-offer/flights.offer.$id.step.1'

// Create/Update Routes

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTripsRoute = AuthenticatedTripsImport.update({
  id: '/trips',
  path: '/trips',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTravelersRoute = AuthenticatedTravelersImport.update({
  id: '/travelers',
  path: '/travelers',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelOfferRoute = AuthenticatedHotelOfferImport.update({
  id: '/_hotel-offer',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedFlightOfferRoute = AuthenticatedFlightOfferImport.update({
  id: '/_flight-offer',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelsListRoute = AuthenticatedHotelsListImport.update({
  id: '/hotels/list',
  path: '/hotels/list',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedFlightsListRoute = AuthenticatedFlightsListImport.update({
  id: '/flights/list',
  path: '/flights/list',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTripsScopeIdRoute = AuthenticatedTripsScopeIdImport.update({
  id: '/$scope/$id',
  path: '/$scope/$id',
  getParentRoute: () => AuthenticatedTripsRoute,
} as any)

const AuthenticatedHotelOfferHotelsIdImagesRoute =
  AuthenticatedHotelOfferHotelsIdImagesImport.update({
    id: '/hotels/$id/images',
    path: '/hotels/$id/images',
    getParentRoute: () => AuthenticatedHotelOfferRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute =
  AuthenticatedHotelOfferHotelsIdFullscreenGalleryImport.update({
    id: '/hotels/$id/fullscreen-gallery',
    path: '/hotels/$id/fullscreen-gallery',
    getParentRoute: () => AuthenticatedHotelOfferRoute,
  } as any)

const AuthenticatedHotelOfferHotelsIdStepRoute =
  AuthenticatedHotelOfferHotelsIdStepImport.update({
    id: '/hotels/$id/$step',
    path: '/hotels/$id/$step',
    getParentRoute: () => AuthenticatedHotelOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute =
  AuthenticatedFlightOfferFlightsOfferIdStepFinalImport.update({
    id: '/flights/offer/$id/step/final',
    path: '/flights/offer/$id/step/final',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStep3Route =
  AuthenticatedFlightOfferFlightsOfferIdStep3Import.update({
    id: '/flights/offer/$id/step/3',
    path: '/flights/offer/$id/step/3',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStep2Route =
  AuthenticatedFlightOfferFlightsOfferIdStep2Import.update({
    id: '/flights/offer/$id/step/2',
    path: '/flights/offer/$id/step/2',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

const AuthenticatedFlightOfferFlightsOfferIdStep1Route =
  AuthenticatedFlightOfferFlightsOfferIdStep1Import.update({
    id: '/flights/offer/$id/step/1',
    path: '/flights/offer/$id/step/1',
    getParentRoute: () => AuthenticatedFlightOfferRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_flight-offer': {
      id: '/_authenticated/_flight-offer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedFlightOfferImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_hotel-offer': {
      id: '/_authenticated/_hotel-offer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedHotelOfferImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/travelers': {
      id: '/_authenticated/travelers'
      path: '/travelers'
      fullPath: '/travelers'
      preLoaderRoute: typeof AuthenticatedTravelersImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/trips': {
      id: '/_authenticated/trips'
      path: '/trips'
      fullPath: '/trips'
      preLoaderRoute: typeof AuthenticatedTripsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/flights/list': {
      id: '/_authenticated/flights/list'
      path: '/flights/list'
      fullPath: '/flights/list'
      preLoaderRoute: typeof AuthenticatedFlightsListImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/hotels/list': {
      id: '/_authenticated/hotels/list'
      path: '/hotels/list'
      fullPath: '/hotels/list'
      preLoaderRoute: typeof AuthenticatedHotelsListImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/trips/$scope/$id': {
      id: '/_authenticated/trips/$scope/$id'
      path: '/$scope/$id'
      fullPath: '/trips/$scope/$id'
      preLoaderRoute: typeof AuthenticatedTripsScopeIdImport
      parentRoute: typeof AuthenticatedTripsImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/$step': {
      id: '/_authenticated/_hotel-offer/hotels/$id/$step'
      path: '/hotels/$id/$step'
      fullPath: '/hotels/$id/$step'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdStepImport
      parentRoute: typeof AuthenticatedHotelOfferImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/fullscreen-gallery': {
      id: '/_authenticated/_hotel-offer/hotels/$id/fullscreen-gallery'
      path: '/hotels/$id/fullscreen-gallery'
      fullPath: '/hotels/$id/fullscreen-gallery'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdFullscreenGalleryImport
      parentRoute: typeof AuthenticatedHotelOfferImport
    }
    '/_authenticated/_hotel-offer/hotels/$id/images': {
      id: '/_authenticated/_hotel-offer/hotels/$id/images'
      path: '/hotels/$id/images'
      fullPath: '/hotels/$id/images'
      preLoaderRoute: typeof AuthenticatedHotelOfferHotelsIdImagesImport
      parentRoute: typeof AuthenticatedHotelOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/1': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/1'
      path: '/flights/offer/$id/step/1'
      fullPath: '/flights/offer/$id/step/1'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStep1Import
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/2': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/2'
      path: '/flights/offer/$id/step/2'
      fullPath: '/flights/offer/$id/step/2'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStep2Import
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/3': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/3'
      path: '/flights/offer/$id/step/3'
      fullPath: '/flights/offer/$id/step/3'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStep3Import
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
    '/_authenticated/_flight-offer/flights/offer/$id/step/final': {
      id: '/_authenticated/_flight-offer/flights/offer/$id/step/final'
      path: '/flights/offer/$id/step/final'
      fullPath: '/flights/offer/$id/step/final'
      preLoaderRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalImport
      parentRoute: typeof AuthenticatedFlightOfferImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedFlightOfferRouteChildren {
  AuthenticatedFlightOfferFlightsOfferIdStep1Route: typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  AuthenticatedFlightOfferFlightsOfferIdStep2Route: typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  AuthenticatedFlightOfferFlightsOfferIdStep3Route: typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute: typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
}

const AuthenticatedFlightOfferRouteChildren: AuthenticatedFlightOfferRouteChildren =
  {
    AuthenticatedFlightOfferFlightsOfferIdStep1Route:
      AuthenticatedFlightOfferFlightsOfferIdStep1Route,
    AuthenticatedFlightOfferFlightsOfferIdStep2Route:
      AuthenticatedFlightOfferFlightsOfferIdStep2Route,
    AuthenticatedFlightOfferFlightsOfferIdStep3Route:
      AuthenticatedFlightOfferFlightsOfferIdStep3Route,
    AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute:
      AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute,
  }

const AuthenticatedFlightOfferRouteWithChildren =
  AuthenticatedFlightOfferRoute._addFileChildren(
    AuthenticatedFlightOfferRouteChildren,
  )

interface AuthenticatedHotelOfferRouteChildren {
  AuthenticatedHotelOfferHotelsIdStepRoute: typeof AuthenticatedHotelOfferHotelsIdStepRoute
  AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute: typeof AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute
  AuthenticatedHotelOfferHotelsIdImagesRoute: typeof AuthenticatedHotelOfferHotelsIdImagesRoute
}

const AuthenticatedHotelOfferRouteChildren: AuthenticatedHotelOfferRouteChildren =
  {
    AuthenticatedHotelOfferHotelsIdStepRoute:
      AuthenticatedHotelOfferHotelsIdStepRoute,
    AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute:
      AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute,
    AuthenticatedHotelOfferHotelsIdImagesRoute:
      AuthenticatedHotelOfferHotelsIdImagesRoute,
  }

const AuthenticatedHotelOfferRouteWithChildren =
  AuthenticatedHotelOfferRoute._addFileChildren(
    AuthenticatedHotelOfferRouteChildren,
  )

interface AuthenticatedTripsRouteChildren {
  AuthenticatedTripsScopeIdRoute: typeof AuthenticatedTripsScopeIdRoute
}

const AuthenticatedTripsRouteChildren: AuthenticatedTripsRouteChildren = {
  AuthenticatedTripsScopeIdRoute: AuthenticatedTripsScopeIdRoute,
}

const AuthenticatedTripsRouteWithChildren =
  AuthenticatedTripsRoute._addFileChildren(AuthenticatedTripsRouteChildren)

interface AuthenticatedRouteChildren {
  AuthenticatedFlightOfferRoute: typeof AuthenticatedFlightOfferRouteWithChildren
  AuthenticatedHotelOfferRoute: typeof AuthenticatedHotelOfferRouteWithChildren
  AuthenticatedTravelersRoute: typeof AuthenticatedTravelersRoute
  AuthenticatedTripsRoute: typeof AuthenticatedTripsRouteWithChildren
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedFlightsListRoute: typeof AuthenticatedFlightsListRoute
  AuthenticatedHotelsListRoute: typeof AuthenticatedHotelsListRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedFlightOfferRoute: AuthenticatedFlightOfferRouteWithChildren,
  AuthenticatedHotelOfferRoute: AuthenticatedHotelOfferRouteWithChildren,
  AuthenticatedTravelersRoute: AuthenticatedTravelersRoute,
  AuthenticatedTripsRoute: AuthenticatedTripsRouteWithChildren,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedFlightsListRoute: AuthenticatedFlightsListRoute,
  AuthenticatedHotelsListRoute: AuthenticatedHotelsListRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedHotelOfferRouteWithChildren
  '/travelers': typeof AuthenticatedTravelersRoute
  '/trips': typeof AuthenticatedTripsRouteWithChildren
  '/': typeof AuthenticatedIndexRoute
  '/flights/list': typeof AuthenticatedFlightsListRoute
  '/hotels/list': typeof AuthenticatedHotelsListRoute
  '/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/hotels/$id/$step': typeof AuthenticatedHotelOfferHotelsIdStepRoute
  '/hotels/$id/fullscreen-gallery': typeof AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute
  '/hotels/$id/images': typeof AuthenticatedHotelOfferHotelsIdImagesRoute
  '/flights/offer/$id/step/1': typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  '/flights/offer/$id/step/2': typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  '/flights/offer/$id/step/3': typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  '/flights/offer/$id/step/final': typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedHotelOfferRouteWithChildren
  '/travelers': typeof AuthenticatedTravelersRoute
  '/trips': typeof AuthenticatedTripsRouteWithChildren
  '/': typeof AuthenticatedIndexRoute
  '/flights/list': typeof AuthenticatedFlightsListRoute
  '/hotels/list': typeof AuthenticatedHotelsListRoute
  '/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/hotels/$id/$step': typeof AuthenticatedHotelOfferHotelsIdStepRoute
  '/hotels/$id/fullscreen-gallery': typeof AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute
  '/hotels/$id/images': typeof AuthenticatedHotelOfferHotelsIdImagesRoute
  '/flights/offer/$id/step/1': typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  '/flights/offer/$id/step/2': typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  '/flights/offer/$id/step/3': typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  '/flights/offer/$id/step/final': typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_authenticated/_flight-offer': typeof AuthenticatedFlightOfferRouteWithChildren
  '/_authenticated/_hotel-offer': typeof AuthenticatedHotelOfferRouteWithChildren
  '/_authenticated/travelers': typeof AuthenticatedTravelersRoute
  '/_authenticated/trips': typeof AuthenticatedTripsRouteWithChildren
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/flights/list': typeof AuthenticatedFlightsListRoute
  '/_authenticated/hotels/list': typeof AuthenticatedHotelsListRoute
  '/_authenticated/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/_authenticated/_hotel-offer/hotels/$id/$step': typeof AuthenticatedHotelOfferHotelsIdStepRoute
  '/_authenticated/_hotel-offer/hotels/$id/fullscreen-gallery': typeof AuthenticatedHotelOfferHotelsIdFullscreenGalleryRoute
  '/_authenticated/_hotel-offer/hotels/$id/images': typeof AuthenticatedHotelOfferHotelsIdImagesRoute
  '/_authenticated/_flight-offer/flights/offer/$id/step/1': typeof AuthenticatedFlightOfferFlightsOfferIdStep1Route
  '/_authenticated/_flight-offer/flights/offer/$id/step/2': typeof AuthenticatedFlightOfferFlightsOfferIdStep2Route
  '/_authenticated/_flight-offer/flights/offer/$id/step/3': typeof AuthenticatedFlightOfferFlightsOfferIdStep3Route
  '/_authenticated/_flight-offer/flights/offer/$id/step/final': typeof AuthenticatedFlightOfferFlightsOfferIdStepFinalRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/travelers'
    | '/trips'
    | '/'
    | '/flights/list'
    | '/hotels/list'
    | '/trips/$scope/$id'
    | '/hotels/$id/$step'
    | '/hotels/$id/fullscreen-gallery'
    | '/hotels/$id/images'
    | '/flights/offer/$id/step/1'
    | '/flights/offer/$id/step/2'
    | '/flights/offer/$id/step/3'
    | '/flights/offer/$id/step/final'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/travelers'
    | '/trips'
    | '/'
    | '/flights/list'
    | '/hotels/list'
    | '/trips/$scope/$id'
    | '/hotels/$id/$step'
    | '/hotels/$id/fullscreen-gallery'
    | '/hotels/$id/images'
    | '/flights/offer/$id/step/1'
    | '/flights/offer/$id/step/2'
    | '/flights/offer/$id/step/3'
    | '/flights/offer/$id/step/final'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_authenticated/_flight-offer'
    | '/_authenticated/_hotel-offer'
    | '/_authenticated/travelers'
    | '/_authenticated/trips'
    | '/_authenticated/'
    | '/_authenticated/flights/list'
    | '/_authenticated/hotels/list'
    | '/_authenticated/trips/$scope/$id'
    | '/_authenticated/_hotel-offer/hotels/$id/$step'
    | '/_authenticated/_hotel-offer/hotels/$id/fullscreen-gallery'
    | '/_authenticated/_hotel-offer/hotels/$id/images'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/1'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/2'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/3'
    | '/_authenticated/_flight-offer/flights/offer/$id/step/final'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_flight-offer",
        "/_authenticated/_hotel-offer",
        "/_authenticated/travelers",
        "/_authenticated/trips",
        "/_authenticated/",
        "/_authenticated/flights/list",
        "/_authenticated/hotels/list"
      ]
    },
    "/_authenticated/_flight-offer": {
      "filePath": "_authenticated/_flight-offer.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_flight-offer/flights/offer/$id/step/1",
        "/_authenticated/_flight-offer/flights/offer/$id/step/2",
        "/_authenticated/_flight-offer/flights/offer/$id/step/3",
        "/_authenticated/_flight-offer/flights/offer/$id/step/final"
      ]
    },
    "/_authenticated/_hotel-offer": {
      "filePath": "_authenticated/_hotel-offer.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_hotel-offer/hotels/$id/$step",
        "/_authenticated/_hotel-offer/hotels/$id/fullscreen-gallery",
        "/_authenticated/_hotel-offer/hotels/$id/images"
      ]
    },
    "/_authenticated/travelers": {
      "filePath": "_authenticated/travelers.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/trips": {
      "filePath": "_authenticated/trips.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/trips/$scope/$id"
      ]
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/flights/list": {
      "filePath": "_authenticated/flights.list.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/hotels/list": {
      "filePath": "_authenticated/hotels.list.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/trips/$scope/$id": {
      "filePath": "_authenticated/trips.$scope.$id.tsx",
      "parent": "/_authenticated/trips"
    },
    "/_authenticated/_hotel-offer/hotels/$id/$step": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.$step.tsx",
      "parent": "/_authenticated/_hotel-offer"
    },
    "/_authenticated/_hotel-offer/hotels/$id/fullscreen-gallery": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.fullscreen-gallery.tsx",
      "parent": "/_authenticated/_hotel-offer"
    },
    "/_authenticated/_hotel-offer/hotels/$id/images": {
      "filePath": "_authenticated/_hotel-offer/hotels.$id.images.tsx",
      "parent": "/_authenticated/_hotel-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/1": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.1.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/2": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.2.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/3": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.3.tsx",
      "parent": "/_authenticated/_flight-offer"
    },
    "/_authenticated/_flight-offer/flights/offer/$id/step/final": {
      "filePath": "_authenticated/_flight-offer/flights.offer.$id.step.final.tsx",
      "parent": "/_authenticated/_flight-offer"
    }
  }
}
ROUTE_MANIFEST_END */
