import { ReactNode, useState } from "react";
import { IconButton, MobileStepper } from "@mui/material";
import { X, ArrowRight } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

type HotelBookingHeaderProps = {
  className?: string;
  onBackClick: () => void;
  onCloseClick?: () => void;
  title: ReactNode;
  showStepper?: boolean;
} & (
  | {
      showStepper: true;
      step: number;
    }
  | { showStepper?: false }
);

export const STEPS = [
  "Hotel Description",
  "Room Selection",
  "Travellers",
  "Summary",
  "Success",
];

export const HotelBookingHeader = ({
  title,
  onBackClick,
  onCloseClick,
  ...props
}: HotelBookingHeaderProps) => {
  const rtlProgress = [4, 3, 2, 1];
  const t = useTranslations();

  const stepNames = [
    t("hotels.title"),
    t("hotels.chooseRoom"),
    t("travellers.title"),
    t("summary"),
  ];

  return (
    <div className="mt-2 mb-4 flex w-full flex-col px-4">
      <div className="align-center flex justify-between">
        {onCloseClick ? (
          <IconButton onClick={onCloseClick}>
            <X size={24} color="text-title" />
          </IconButton>
        ) : (
          <div />
        )}
        {title}
        <IconButton onClick={onBackClick}>
          <ArrowRight size={24} color="text-title" />
        </IconButton>
      </div>
      {props.showStepper ? (
        <>
          <MobileStepper
            variant="progress"
            steps={5}
            position="static"
            activeStep={rtlProgress[props.step]}
            sx={{
              width: "100%",
              float: "right",
              backgroundColor: "transparent",
              "& .MuiLinearProgress-root": {
                width: "100%",
                backgroundColor: "#EF9C43",
              },
              "& .MuiLinearProgress-bar": {
                background: "#E7E9EE",
              },
            }}
            nextButton={null}
            backButton={null}
          />
          <div className="flex items-center justify-end gap-2">
            <h5 className="text-title text-xs font-semibold">
              <span className="text-tertiary text-xs">
                {t("hotels.step")}&nbsp;{STEPS.length - 1}/{props.step}{" "}
                {stepNames[props.step - 1]}
              </span>
            </h5>
          </div>
        </>
      ) : null}
    </div>
  );
};
