import { MyTrip } from "@/app/ui/my-trips/MyTrip";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_authenticated/trips/$scope/$id")({
  params: {
    parse: (
      rawParams: Record<string, string>,
    ): { scope: "flights" | "hotels"; id: string } => {
      if (!["flights", "hotels"].includes(rawParams.scope)) {
        throw new Error("Invalid scope");
      }
      return {
        scope: rawParams.scope as "flights" | "hotels",
        id: rawParams.id,
      };
    },
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { scope, id } = Route.useParams();
  return <MyTrip scope={scope} reservationId={id} />;
}
