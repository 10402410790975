import { createFileRoute, Outlet } from "@tanstack/react-router";
import { useAsyncFn, useEffectOnce } from "react-use";
import { authorize, my } from "@/app/lib/api/api";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { clearAppData } from "@/app/lib//clearData";
import Loader from "@/app/ui/AnimatedLoader";
import { motion } from "motion/react";
import clsx from "clsx";
import { Button, Typography } from "@mui/material";
import { useTranslations } from "use-intl";

export const Route = createFileRoute("/_authenticated")({
  component: RouteComponent,
});

function RouteComponent() {
  const t = useTranslations();
  const [state, auth] = useAsyncFn(async () => {
    try {
      return await authorize();
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      throw e;
    }
  }, []);

  useEffectOnce(() => {
    my?.setNavigationBar({
      backgroundColor: "003E2B",
      image: `${location.origin}/tzlogo.png`,
    });
  });

  useEffectOnce(() => {
    auth();
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        clearAppData();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (state.loading) {
    return (
      <>
        <motion.div
          layoutId="hero"
          className={clsx(
            "bg-hero-pattern absolute top-0 h-6 w-full rounded-b-2xl bg-cover bg-no-repeat",
          )}
        />
        <main className="flex h-screen w-full flex-col overflow-auto overscroll-none bg-slate-100 pb-14">
          <div className="mt-6 flex h-full w-full flex-col items-center justify-center bg-slate-100 p-4">
            <Loader />
          </div>
        </main>
      </>
    );
  }

  if (state.error) {
    return (
      <>
        <motion.div
          layoutId="hero"
          className={clsx(
            "bg-hero-pattern absolute top-0 h-6 w-full rounded-b-2xl bg-cover bg-no-repeat",
          )}
        />
        <main className="flex h-screen w-full flex-col overflow-auto bg-slate-100 pb-14">
          <div className="mt-6 flex h-full w-full flex-col items-center justify-center bg-slate-100 p-4">
            <div className="flex h-full w-full flex-col items-center justify-center text-black">
              <Typography variant="h4">{t("welcome.title")}</Typography>
              <Typography variant="body1">{t("welcome.body")}</Typography>

              <Button
                sx={{ mt: 2 }}
                onClick={() => auth()}
                variant="contained"
                color="primary"
              >
                {t("welcome.button")}
              </Button>
            </div>
          </div>
        </main>
      </>
    );
  }

  return <Outlet />;
}
