import FlightSummary from "@/app/ui/flight-purchase/FlightSummary";
import TravellersListSummary from "@/app/ui/flight-purchase/TravellersListSummary";
import { useTravellersContext } from "@/app/lib/context";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_flight-offer/flights/offer/$id/step/3",
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { singleFlightDetails } = useSingleFlightSelectionContext();
  const {
    getSelectedTravellers,
    handleLeadTraveller,
    leadTraveller,
    isReady: isTravelersReady,
  } = useTravellersContext();

  const baggage =
    singleFlightDetails?.optionalServices?.filter(
      (service) =>
        (service.included && service.service.categoryCode === "BG") ||
        service.service.categoryCode === "CY",
    ) || [];

  return (
    <div className="px-4">
      {singleFlightDetails && (
        <FlightSummary selectedFlight={singleFlightDetails} />
      )}
      {isTravelersReady && getSelectedTravellers.length > 0 && (
        <TravellersListSummary
          leadTraveller={leadTraveller}
          setLeadTraveller={handleLeadTraveller}
          selectedTravellers={getSelectedTravellers}
          baggage={baggage}
        />
      )}
    </div>
  );
}
