export enum TripType {
  ONE_WAY = "one-way",
  ROUND_TRIP = "round-trip",
}

export enum AirportType {
  FROM = "from",
  TO = "to",
}

export enum CabinClass {
  ECONOMY = "Y",
  PREMIUM_ECONOMY = "W",
  FIRST_CLASS = "F",
  BUSINESS = "C",
}

export enum SortOptions {
  PRICE = "price",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum HotelsSortOptions {
  PRICE = "price",
  RATING = "stars",
}

export enum FilterOptions {
  PRICE = "price",
  STOPS = "stops",
  AIRLINE = "airline",
}

export enum StopsOptions {
  ANY = "ANY",
  DIRECT = "DIRECT",
  ONE = "UP_TO_ONE",
}

export enum TravelPurpose {
  LEISURE = "leisure",
  BUSINESS = "business",
}

export enum DrawerComponent {
  HOTELS_FILTERS = "HOTELS_FILTERS",
  HOTELS_SORT = "HOTELS_SORT",
}
