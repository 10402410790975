import {
  mapFlightReservation,
  useFlightsReservations,
} from "@/app/lib/hooks/useFlightsReservations";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../AnimatedLoader";
import { useTranslations } from "use-intl";
import SingleAvailableFlight from "../SingleAvailableFlight";
import NoResultsError from "../NoResultsError";
import type { FindUserFlightsReservationsReservation } from "@/app/lib/types/codegen";

export const MyFlightsList = ({
  onReservationClick,
  page,
}: {
  onReservationClick: (flight: FindUserFlightsReservationsReservation) => void;
  page?: number;
}) => {
  const t = useTranslations();
  const { data, setSize, size, hasMore, isLoading } = useFlightsReservations(
    page || 1,
  );

  const isEmpty = data?.length === 0;

  if (isEmpty && !isLoading) return <NoResultsError tab="flights" />;

  return (
    <InfiniteScroll
      className="mt-2"
      dataLength={
        data
          ?.flatMap((page) => page.data.reservations.length)
          .reduce((prev, cur) => prev + cur, 0) || 0
      }
      scrollableTarget="scrollableDiv"
      next={() => {
        setSize(size + 1);
      }}
      hasMore={hasMore}
      loader={
        <div className="flex flex-col items-center justify-center">
          <Loader icon="airplane" />
          <h3 className="text-primary mt-3 font-normal">
            {t("flights.preparingOffers")}
          </h3>
        </div>
      }
      endMessage={
        <div className="text-tertiary flex flex-col items-center justify-center gap-3 p-8">
          {t("flights.noResults")}
        </div>
      }
    >
      {data?.map((page) =>
        page.data.reservations.map((reservation) => (
          <SingleAvailableFlight
            key={reservation.id}
            onClick={() => onReservationClick(reservation)}
            itinerary={{
              totalPrice: 0,
              flights: reservation.flights.map((flight) =>
                mapFlightReservation(flight),
              ),
            }}
          />
        )),
      )}
    </InfiniteScroll>
  );
};
