import { useTranslations } from "use-intl";

import { THotelRoom } from "@/app/lib/types/types";
import { displayTravellers } from "@/app/lib/displayTravellers";

type HotelRoomTitleProps = Pick<THotelRoom, "name" | "board" | "occupancy">;

export const HotelRoomTitle = ({
  name,
  board,
  occupancy,
}: HotelRoomTitleProps) => {
  const t = useTranslations();

  return (
    <>
      {`${name} - ${board} - ${displayTravellers(
        t,
        occupancy.numberOfAdults,
        occupancy.numberOfChildren,
      )}
    `}
    </>
  );
};
