import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { Calendar } from "../Calendar";
import type { FormikProps } from "formik";
import { useTranslations, useLocale } from "use-intl";
import type { TFlightFormValues } from "@/app/lib/types/types";
import { TripType } from "@/app/lib/types/enums";
import { Button } from "../StyledTz";

function DatesSelection({
  formProps,
  onDrawerClose,
}: {
  formProps: FormikProps<TFlightFormValues>;
  onDrawerClose: () => void;
}) {
  const t = useTranslations();

  const calandarProps = {
    startField: {
      label: t("flights.search.departureDate"),
      value: formProps.values.dates.departure,
      path: "dates.departure",
    },
    endField: {
      label: t("flights.search.returnDate"),
      value: formProps.values.dates.return,
      path: "dates.return",
    },
    setFieldValue: formProps.setFieldValue,
    disabledDates: {
      before: new Date(),
    },
  };

  return (
    <div className="flex h-full flex-col px-2">
      <RadioGroup
        row
        aria-labelledby="trip-type"
        name="tripType"
        dir="rtl"
        className="mb-5 flex justify-center"
        value={formProps.values.tripType}
        onChange={formProps.handleChange}
      >
        <FormControlLabel
          value="one-way"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
              }}
            />
          }
          label={t("flights.search.oneWay")}
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
            },
          }}
        />
        <FormControlLabel
          value="round-trip"
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
              }}
            />
          }
          sx={{
            "& .MuiTypography-root": {
              fontSize: 14,
            },
          }}
          label={t("flights.search.roundTrip")}
        />
      </RadioGroup>
      <Calendar
        hasRange={formProps.values.tripType === TripType.ROUND_TRIP}
        {...calandarProps}
      />
      <Button
        fullWidth
        variant="contained"
        sx={{
          borderRadius: "16px",
          marginTop: "auto",
          marginBottom: "16px",
        }}
        onClick={onDrawerClose}
      >
        {t("flights.confirm")}
      </Button>
    </div>
  );
}

export default DatesSelection;
