import type { ChangeEvent } from "react";
import { useLocale, useTranslations } from "use-intl";
import clsx from "clsx";
import { type FormikProps, FieldArray, FormikProvider } from "formik";
import { User, Person, Minus, Plus } from "@phosphor-icons/react";

import type { THotelFormValues, TRoom } from "@/app/lib/types/types";

import PlusMinusButton from "../CounterButton";
import { Button, TextField } from "../StyledTz";

function VisitorsSelection({
  onDrawerClose,
  formProps,
}: {
  onDrawerClose: () => void;
  formProps: FormikProps<THotelFormValues>;
}) {
  const locale = useLocale();
  const t = useTranslations();
  const { values } = formProps;

  const newRoom: TRoom = {
    adults: 1,
    children: 0,
    childrenAges: [],
  };

  const roomsError = formProps?.errors?.rooms as TRoom[] | undefined;

  const handleDrawerClose = async () => {
    const errors = await formProps.validateForm();

    if (!errors.rooms) {
      onDrawerClose();
    }
  };

  return (
    <FormikProvider value={formProps}>
      <div className="flex h-full w-full flex-col p-4">
        <FieldArray
          name="rooms"
          render={(arrayHelpers) => (
            <div>
              <div
                className={clsx(
                  "mb-3 flex items-center justify-between border-b py-2",
                  {
                    "flex-row-reverse text-right": locale === "ar",
                  },
                )}
              >
                <div className="flex">
                  <h4 className="text-primary font-medium">
                    {t("hotels.search.rooms")}
                  </h4>
                </div>
                <div className="flex w-fit items-center gap-4 rounded-2xl border border-solid p-3">
                  <button
                    className="h-5 w-5"
                    onClick={() => arrayHelpers.remove(values.rooms.length - 1)}
                    disabled={values.rooms.length < 2}
                    type="button"
                  >
                    <Minus size={20} color="#475162" weight="bold" />
                  </button>
                  <span className="flex w-3 justify-center">
                    {values.rooms.length}
                  </span>
                  <button
                    className="h-5 w-5"
                    onClick={() =>
                      arrayHelpers.insert(values.rooms.length - 1, newRoom)
                    }
                    disabled={values.rooms.length >= 5}
                    type="button"
                  >
                    <Plus size={20} color="#475162" weight="bold" />
                  </button>
                </div>
              </div>

              {values.rooms &&
                values.rooms.length > 0 &&
                values.rooms.map((room, roomIndex) => (
                  <div key={roomIndex} className="mb-3 w-full">
                    <div className="flex w-full justify-center pb-4">
                      <h2 className="font-medium">
                        {t("hotels.search.room")} #{roomIndex + 1}
                      </h2>
                    </div>
                    <span
                      className={clsx(
                        "text-tertiary mb-4 inline-block w-full text-xs",
                        {
                          "text-right": locale === "ar",
                        },
                      )}
                    >
                      {t("hotels.search.visitors")}
                    </span>
                    <div
                      className={clsx(
                        "flex items-center justify-between border-b py-2",
                        {
                          "flex-row-reverse text-right": locale === "ar",
                        },
                      )}
                    >
                      <div className="flex">
                        <h4 className="text-primary font-medium">
                          {t("hotels.search.adult")}
                        </h4>
                        <User color="black" size={24} className="ml-6" />
                      </div>
                      <PlusMinusButton
                        value={room.adults}
                        onChange={(value) =>
                          arrayHelpers.replace(roomIndex, {
                            ...room,
                            adults: value,
                          })
                        }
                        minValue={1}
                        maxValue={9}
                      />
                    </div>
                    <div className="border-b">
                      <div
                        className={clsx(
                          "flex items-center justify-between py-2",
                          {
                            "flex-row-reverse text-right": locale === "ar",
                          },
                        )}
                      >
                        <div className="flex">
                          <h4 className="text-primary font-medium">
                            {t("hotels.search.child")}
                          </h4>
                          <Person color="black" size={24} className="ml-6" />
                        </div>
                        <PlusMinusButton
                          value={room.children}
                          onChange={(value) => {
                            arrayHelpers.replace(roomIndex, {
                              ...room,
                              children: value,
                              childrenAges: Array.from(Array(value).keys()).map(
                                (age, i) => room.childrenAges[i],
                              ),
                            });
                          }}
                          maxValue={2}
                        />
                      </div>
                      {room.children > 0 &&
                        Array.from(Array(room.children).keys()).map(
                          (childIndex) => (
                            <div
                              key={childIndex}
                              className={clsx(
                                "flex items-center justify-between py-2",
                                {
                                  "flex-row-reverse text-right":
                                    locale === "ar",
                                },
                              )}
                            >
                              <div className="mb-4 flex items-center">
                                <h4 className="text-primary font-medium">
                                  {t("hotels.search.child")} {childIndex + 1}
                                </h4>
                              </div>
                              <TextField
                                variant="filled"
                                size="small"
                                sx={{
                                  maxWidth: "164px !important",
                                  "& .MuiInputBase-input": {
                                    borderColor:
                                      !!roomsError?.length &&
                                      !!roomsError[roomIndex]?.childrenAges[
                                        childIndex
                                      ]
                                        ? "red"
                                        : "",
                                  },
                                }}
                                label={t("hotels.search.childsAge")}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                  arrayHelpers.replace(roomIndex, {
                                    ...room,
                                    childrenAges: room.childrenAges.map(
                                      (age, i) =>
                                        i === childIndex
                                          ? e.target.value
                                            ? Number(e.target.value)
                                            : ""
                                          : age,
                                    ),
                                  });
                                }}
                                type="text"
                                inputProps={{
                                  inputMode: "numeric",
                                  pattern: "d*",
                                }}
                                value={room.childrenAges[childIndex]}
                              />
                            </div>
                          ),
                        )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        />

        <div className="pb-4">
          <Button
            variant="contained"
            className="mt-auto w-full rounded-xl"
            onClick={handleDrawerClose}
          >
            {t("hotels.confirm")}
          </Button>
        </div>
      </div>
    </FormikProvider>
  );
}

export default VisitorsSelection;
