import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import clsx from "clsx";
import dayjs from "dayjs";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import { Button, MenuItem, SwipeableDrawer } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react/dist/ssr";
import { string, object, date } from "yup";
import { useTranslations } from "use-intl";

import { TextField, TelInput, Puller } from "@/app/ui/StyledTz";
import { Calendar, type CalendarProps } from "@/app/ui/Calendar";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import type { FindUserTravelersOutputData } from "@/app/lib/types/codegen";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

const titleOptions = [
  {
    value: "mr",
    label: "السيد",
  },
  {
    value: "mrs",
    label: "السيدة",
  },
  {
    value: "miss",
    label: "الآنسة",
  },
  {
    value: "mstr",
    label: "السيد",
  },
];

const telInputCountries: MuiTelInputCountry[] = ["IQ"];

function TravellerForm({
  traveller,
  addTraveller,
  editTraveller,
  closeDrawer,
}: {
  traveller: FindUserTravelersOutputData | null;
  addTraveller: (traveller: FindUserTravelersOutputData) => void;
  editTraveller: (traveller: FindUserTravelersOutputData) => void;
  closeDrawer: () => void;
}) {
  const t = useTranslations();
  const showSnackbar = useSnackbar();
  const { dir } = useLocaleInfo();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [calendarProps, setCalendarProps] = useState<CalendarProps>();

  const formik = useFormik<FindUserTravelersOutputData>({
    enableReinitialize: true,
    initialValues: traveller
      ? { ...traveller }
      : {
          id: "",
          title: "mr",
          firstName: "",
          lastName: "",
          birthDate: "",
          passportNumber: "",
          phonePrefix: "+964",
          passportIssuingDate: "",
          passportExpiryDate: "",
          emailAddress: undefined,
          phoneNumber: "",
          passportIssuingCountry: "IQ",
          passportNationality: "IQ",
        },
    validationSchema: object().shape({
      firstName: string()
        .required(t("travellers.validation.name"))
        .matches(/^[a-zA-Z\s]*$/, t("travellers.validation.name")),
      lastName: string()
        .required(t("travellers.validation.surname"))
        .matches(/^[a-zA-Z\s]*$/, t("travellers.validation.surname")),
      birthDate: date().required(t("travellers.validation.birthDate")),
      passportNumber: string()
        .required(t("travellers.validation.passportId"))
        .matches(/^[a-zA-Z0-9]{7,9}$/, t("travellers.validation.passportId")),
      emailAddress: string().email(t("travellers.validation.emailNotValid")),
      passportIssuingDate: date().required(
        t("travellers.validation.issueDate"),
      ),
      passportExpiryDate: date().required(
        t("travellers.validation.expiryDate"),
      ),
      title: string().required(t("travellers.validation.title")),
      phoneNumber: string()
        .required(t("travellers.validation.phone"))
        .test("is-phone-number", t("travellers.validation.phone"), (value) =>
          matchIsValidTel(value, {
            onlyCountries: telInputCountries,
          }),
        ),
    }),
    onSubmit: (values) => {
      if (traveller) {
        editTraveller({
          ...values,
          phoneNumber: values.phoneNumber.replace(values.phonePrefix, ""),
          emailAddress: values.emailAddress || undefined,
        });
      } else {
        addTraveller({
          ...values,
          phoneNumber: values.phoneNumber.replace(values.phonePrefix, ""),
          emailAddress: values.emailAddress || undefined,
        });
      }

      closeDrawer();
    },
  });

  const handleDrawerOpen = (
    type: "issueDate" | "dateOfBirth" | "expiryDate",
  ) => {
    setDrawerOpen(true);
    if (type == "issueDate") {
      setCalendarProps({
        startField: {
          label: t("travellers.passportIssueDate"),
          value: formik.values.passportIssuingDate,
          path: "passportIssuingDate",
        },
        disabledDates: {
          after: new Date(),
        },
        setFieldValue: formik.setFieldValue,
      });
    } else if (type == "dateOfBirth") {
      setCalendarProps({
        startField: {
          label: t("travellers.dateOfBirth"),
          value: formik.values.birthDate,
          path: "birthDate",
        },
        disabledDates: {
          after: new Date(),
        },
        setFieldValue: formik.setFieldValue,
      });
    } else if (type == "expiryDate") {
      setCalendarProps({
        startField: {
          label: t("travellers.passportExpiryDate"),
          value: formik.values.passportExpiryDate,
          path: "passportExpiryDate",
        },
        disabledDates: {
          before: new Date(),
        },
        setFieldValue: formik.setFieldValue,
      });
    }
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res)[0], 90);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const infoLabel = traveller ? t("travellers.edit") : t("travellers.add");
  const infoDescription = traveller
    ? t("travellers.editDescription")
    : t("travellers.addDescription");

  return (
    <>
      <div className="mt-5 mb-3 flex w-full flex-col items-center justify-center px-4 text-center">
        <h5 className="text-title text-center text-base font-medium">
          {infoLabel}
        </h5>
        <h5 className="text-center text-xs text-gray-400">
          {t("travellers.info")}
        </h5>
      </div>
      <form
        className="relative flex h-full flex-col gap-2 p-4 pb-10"
        noValidate
        onSubmit={onSubmit}
        acceptCharset="ISO-8859-1"
      >
        <span className="text-tertiary ml-auto text-xs font-medium">
          {infoDescription}
        </span>
        <div className="flex gap-2">
          <TextField
            fullWidth
            style={{ minWidth: "70%" }}
            name="firstName"
            label={t("travellers.name")}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            autoComplete="off"
            variant="filled"
            dir={dir}
          />
          <TextField
            fullWidth
            id="title"
            name="title"
            label={t("travellers.passengerTitle")}
            className="w-full p-0"
            dir="ltr"
            variant="filled"
            value={formik.values.title}
            aria-label="title"
            InputProps={{
              endAdornment: (
                <CaretDown
                  className={clsx("absolute right-5")}
                  color="black"
                  size={24}
                />
              ),
            }}
            select
            onChange={(e) => formik.setFieldValue("title", e.target.value)}
            sx={{
              "& .MuiInputLabel-root": {
                left: "0",
              },
              "& .MuiSelect-select": {
                borderRadius: "16px !important",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
          >
            {titleOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <TextField
          fullWidth
          name="lastName"
          label={t("travellers.lastName")}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="dateOfBirth"
          label={t("travellers.dateOfBirth")}
          value={
            formik.values.birthDate
              ? dayjs(formik.values.birthDate).format("YYYY-MM-DD")
              : ""
          }
          onMouseDown={() => {
            handleDrawerOpen("dateOfBirth");
          }}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="passportNumber"
          label={t("travellers.passportId")}
          value={formik.values.passportNumber}
          onChange={formik.handleChange}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="passportIssuingDate"
          label={t("travellers.passportIssueDate")}
          value={
            formik.values.passportIssuingDate
              ? dayjs(formik.values.passportIssuingDate).format("YYYY-MM-DD")
              : ""
          }
          onMouseDown={() => {
            handleDrawerOpen("issueDate");
          }}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="passportExpiryDate"
          label={t("travellers.passportExpiryDate")}
          value={
            formik.values.passportExpiryDate
              ? dayjs(formik.values.passportExpiryDate).format("YYYY-MM-DD")
              : ""
          }
          onMouseDown={() => {
            handleDrawerOpen("expiryDate");
          }}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TextField
          fullWidth
          name="emailAddress"
          label={t("travellers.email")}
          value={formik.values.emailAddress}
          onChange={formik.handleChange}
          autoComplete="off"
          variant="filled"
          dir={dir}
        />
        <TelInput
          fullWidth
          value={formik.values.phoneNumber}
          onChange={(event) => {
            let phoneNumber = event.replace(/\D/g, "");
            if (!phoneNumber.startsWith("964")) {
              phoneNumber = "964";
            }
            phoneNumber = "+" + phoneNumber;
            formik.setFieldValue("phoneNumber", phoneNumber);
          }}
          onlyCountries={telInputCountries}
          disableFormatting
          disableDropdown
          sx={{
            paddingBottom: "12px",
          }}
          name="phoneNumber"
          label={t("travellers.phoneNumber")}
          autoComplete="off"
          variant="filled"
          defaultCountry={telInputCountries[0]}
          dir={dir}
        />
        <Button
          fullWidth
          variant="contained"
          aria-label="save-travellers"
          type="submit"
          sx={{
            marginTop: "auto",
            textTransform: "none",
            position: "sticky",
            bottom: "12px",
            borderRadius: "16px",
            background: "#0966D7",
            padding: "12px 24px",
          }}
        >
          {t("saveChanges")}
        </Button>
      </form>
      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            paddingBottom: "env(safe-area-inset-bottom)",
            borderRadius: "32px 32px 0 0",
          },
        }}
      >
        {drawerOpen && calendarProps && (
          <div className="flex h-full flex-col p-4 pt-0">
            <div className="flex items-center justify-center py-4">
              <Puller />
            </div>
            <Calendar dropdown hasRange={false} {...calendarProps} />
            <Button
              fullWidth
              variant="contained"
              aria-label="search-flight"
              type="submit"
              sx={{
                marginTop: "auto",
                textTransform: "none",
                borderRadius: "16px",
                position: "sticky",
                bottom: "12px",
                background: "#0966D7",
                padding: "12px 24px",
              }}
              onClick={() => setDrawerOpen(false)}
            >
              {t("flights.done")}
            </Button>
          </div>
        )}
      </SwipeableDrawer>
    </>
  );
}

export default TravellerForm;
