import useActiveStepData from "@/app/ui/flight-purchase/activeStepData";
import BottomDrawer from "@/app/ui/flight-purchase/BottomDrawer";
import MoreDetailsDrawer from "@/app/ui/flight-purchase/MoreDetailsDrawer";
import Stepper from "@/app/ui/flight-purchase/Stepper";
import Summary from "@/app/ui/flight-purchase/Summary";
import { WhatsAppButton } from "@/app/ui/WhatsAppButton";
import {
  useFlightSelectionPayloadContext,
  useFlightDetailsProvider,
  useTravellersContext,
  SingleFlightDetailsProvider,
} from "@/app/lib/context";
import useDrawerStates from "@/app/lib/hooks/useDrawerStates";
import {
  flighAvailabilityQueryOptions,
  transformFlightAvailabilityData,
} from "@/app/lib/hooks/useFlightAvailability";
import CancelFlightDrawer from "@/app/ui/flight-purchase/CancelFlightPurchase";
import {
  createFileRoute,
  Outlet,
  useMatchRoute,
  useNavigate,
} from "@tanstack/react-router";
import { useState } from "react";
import Header from "@/app/ui/flight-purchase/Header";
import Footer from "@/app/ui/Footer";
import { motion } from "motion/react";
import clsx from "clsx";
import { TravellersProvider } from "@/app/lib/context/TravellersContext";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FlightOfferError } from "@/app/ui/flight-purchase/FlightOfferError";

export const Route = createFileRoute("/_authenticated/_flight-offer")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <TravellersProvider>
      <Content />
    </TravellersProvider>
  );
}

function Content() {
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const { selectedFlightPayload, selectedFlightFromOfferList } =
    useFlightSelectionPayloadContext();
  const { searchData } = useFlightDetailsProvider();
  const { selectedTravellers, leadTraveller } = useTravellersContext();
  const { data, isError, error, isLoading } = useQuery(
    flighAvailabilityQueryOptions(selectedFlightPayload!),
  );

  const navigate = useNavigate();

  const { stepNumber, stepTitle, step, backRoute, nextRoute, buttonLabel } =
    useActiveStepData();
  const {
    drawerOpen,
    moreDetailsOpen,
    cancelFlightOpen,
    setMoreDetailsOpen,
    setCancelFlightOpen,
  } = useDrawerStates();

  const matches = useMatchRoute();

  const travellerCount =
    searchData!.adult + searchData!.child + searchData!.infantWithoutSeat;

  const continueEnabled = travellerCount === selectedTravellers.length;

  const isTicketPurchasedRoute = matches({
    to: "/flights/offer/$id/step/final",
    from: "/",
  });

  const offerExpirationTime = data?.timeLimits
    ? dayjs(data.timeLimits?.offerExpiration).unix() - dayjs().unix()
    : null;

  const flightOffer = {
    ...selectedFlightFromOfferList,
    ...data,
    flights:
      selectedFlightFromOfferList?.flights.map((flight) =>
        transformFlightAvailabilityData(flight),
      ) || data?.flights,
    index: selectedFlightFromOfferList?.index,
    offerExpirationTime: offerExpirationTime,
    totalPrice: `${data ? data.totalPrice.toLocaleString() : selectedFlightFromOfferList?.totalPrice.toLocaleString()} IQD`,
  };

  if (isError) return <FlightOfferError error={error} />;

  return (
    <>
      <motion.div
        layoutId="hero"
        className={clsx(
          "bg-hero-pattern absolute top-0 z-20 h-6 w-full rounded-b-2xl bg-cover bg-no-repeat",
        )}
      />
      <main className="main with-drawer">
        <div className="mt-6 bg-slate-100">
          {isTicketPurchasedRoute ? (
            <Stepper
              step={step}
              stepNumber={stepNumber}
              stepTitle={stepTitle}
              offerExpirationTime={flightOffer.offerExpirationTime}
            />
          ) : (
            <Header
              searchData={searchData!}
              onCancel={() => setCancelFlightOpen(true)}
              onBack={() => backRoute && navigate(backRoute)}
            />
          )}

          <div className="flex flex-1 flex-col gap-4 pb-4">
            <SingleFlightDetailsProvider
              data={flightOffer}
              isAdditionalDataLoading={isLoading}
            >
              <Outlet />
            </SingleFlightDetailsProvider>

            {stepNumber === 3 && (
              <Summary
                totalPrice={flightOffer.totalPrice}
                termsAndConditions={termsAndConditions}
                onTermsChange={setTermsAndConditions}
                onMoreDetails={() => {
                  setMoreDetailsOpen(true);
                }}
              />
            )}
          </div>
          <CancelFlightDrawer
            open={cancelFlightOpen}
            closeDrawer={() => setCancelFlightOpen(false)}
          />

          {!isTicketPurchasedRoute && (
            <>
              <WhatsAppButton bottomPosition={"20vh"} />
              <MoreDetailsDrawer
                open={moreDetailsOpen}
                onClose={() => setMoreDetailsOpen(false)}
              />

              <BottomDrawer
                open={drawerOpen}
                totalPrice={flightOffer?.totalPrice}
                buttonLabel={buttonLabel}
                onButtonClick={() => (nextRoute ? navigate(nextRoute) : null)}
                disabled={
                  (stepNumber === 2 && !continueEnabled) ||
                  (stepNumber === 3 &&
                    (!termsAndConditions || !leadTraveller?.id)) ||
                  isLoading
                }
              />
            </>
          )}
        </div>
      </main>

      <Footer />
    </>
  );
}
