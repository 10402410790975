import useSWR from "swr";
import { BASE_API_URL } from "../routes";
import { hotelsFetcher } from "./useHotelsSearch";

export const useHotelOffer = (
  hotelId: string,
  searchResultKey: string,
  searchResultToken: string,
) => {
  const { data, error, isLoading } = useSWR(
    () => {
      return hotelId
        ? {
            url: `${BASE_API_URL}/hotels/${hotelId}/offers`,
            params: { searchResultKey, searchResultToken },
          }
        : null;
    },
    hotelsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  return {
    data,
    isLoading,
    isError: error,
  };
};
