import { BASE_API_URL } from "../routes";
import useSWRInfinite from "swr/infinite";
import { FindUserHotelsReservationsOutputData } from "../types/codegen";
import { hotelsFetcher } from "./useHotelsSearch";

const PAGE_SIZE = 5;

export const useHotelsReservations = () => {
  const { data, error, isLoading, setSize, size } = useSWRInfinite<{
    data: FindUserHotelsReservationsOutputData;
  }>(
    (page) => ({
      url: `${BASE_API_URL}/hotels/reservations`,
      params: { page: page + 1, perPage: PAGE_SIZE },
    }),
    hotelsFetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return {
    data,
    isLoading,
    setSize,
    size,
    error,
    hasMore:
      (data?.[data.length - 1].data.reservations.length || 0) === PAGE_SIZE,
    count: data?.[0].data.count,
  };
};
