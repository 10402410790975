"use client";

import React, {
  createContext,
  useState,
  useContext,
  FC,
  ReactNode,
} from "react";

export interface Tokens {
  progressToken: string;
  searchResultKey: string;
  searchResultToken: string;
  availabilityToken: string;
}

interface HotelsTokensContextProps {
  tokens: Tokens;
  setTokens: React.Dispatch<React.SetStateAction<Tokens>>;
}

const HotelsContextContext = createContext<HotelsTokensContextProps | null>(
  null,
);

export const HotelsTokenProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [tokens, setTokens] = useState<Tokens>({
    progressToken: "",
    searchResultKey: "",
    searchResultToken: "",
    availabilityToken: "",
  });

  const value: HotelsTokensContextProps = { tokens, setTokens };

  return (
    <HotelsContextContext.Provider value={value}>
      {children}
    </HotelsContextContext.Provider>
  );
};

export const useHotelsTokensContext = () => {
  const context = useContext(HotelsContextContext);
  if (!context) {
    throw new Error(
      "useHotelsTokensContext must be used within a HotelsContextProvider",
    );
  }
  return context;
};
