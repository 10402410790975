import type { ItieraryFlightSegment } from "../types/types";

export function extractAirlineCarriers(segments: ItieraryFlightSegment[]) {
  const carriers = segments?.map((segment) => ({
    ...segment.marketingCarrier.airline,
    flightNumber: segment.marketingCarrier.flightNumber,
  }));

  // Remove duplicates based on airline code and flight number
  const uniqueCarriers = carriers?.filter(
    (carrier, index, self) =>
      index === self.findIndex((t) => t.code === carrier.code),
  );

  return uniqueCarriers || null;
}
