"use client";
import { ArrowRight } from "@phosphor-icons/react/dist/ssr";
import { IconButton } from "@mui/material";
import {
  FindUserFlightsReservationsReservation,
  FindUserHotelsReservationsReservation,
} from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";

export function MyTripsHeader({
  reservation,
  onBackClick,
}: {
  reservation:
    | FindUserFlightsReservationsReservation
    | FindUserHotelsReservationsReservation;
  onBackClick?: () => void;
}) {
  const t = useTranslations();
  return (
    <div className="mb-4 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div />
        <h3 className="text-title font-medium">
          {t("reservations.headerBooking", {
            bookingNumber: reservation.reference.confirmation
              ? `#${reservation.reference.confirmation}`
              : "",
          })}
        </h3>
        <IconButton onClick={onBackClick}>
          <ArrowRight size={24} color="text-title" />
        </IconButton>
      </div>
    </div>
  );
}
