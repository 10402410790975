import api from "./api/api";

// Fetcher Function
export const fetcher = ({ url, params }: { url: string; params: any }) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => res.data);
};

export const postFetcher = ({ url, params }: { url: string; params: any }) => {
  return api
    .post(url, params, {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.data);
};
