"use client";

import { useState } from "react";
import { SwipeableDrawer, MenuItem } from "@mui/material";
import { MapPinLine, CaretDown, X } from "@phosphor-icons/react";
import { Button, TextField, Puller } from "../StyledTz";
import clsx from "clsx";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { string, object, ref, date, number, array } from "yup";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

import DestinationSearch from "./DestinationSearch";
import CheckInSelection from "./CheckInSelection";
import VisitorsSelection from "./VisitorsSelections";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import { TravelPurpose } from "@/app/lib/types/enums";
import { initialHotelsValues } from "@/app/lib/initialHotelsValues";
import type { THotelFormValues, TRoom } from "@/app/lib/types/types";
import { useHotelsDetailsProvider } from "@/app/lib/context/HotelDetailsProvider";
import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

enum EDrawerComponent {
  DestinationSearch = "DestinationSearch",
  CheckInSelection = "CheckInSelection",
  VisitorsSelection = "VisitorsSelection",
}

const getVisitorsCount = (rooms: TRoom[]) => {
  return (
    rooms?.length &&
    rooms.reduce(
      (sum, { adults, children }: TRoom) => sum + adults + children,
      0,
    )
  );
};

function HotelsCalculator() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] = useState<string>("");
  const { searchData, updateSearchData, isReady } = useHotelsDetailsProvider();

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const { locale, dir } = useLocaleInfo();
  const t = useTranslations();

  const handleDrawerOpen = (component: EDrawerComponent) => {
    setDrawerOpen(true);
    setDrawerComponent(component);
  };

  const formik = useFormik({
    initialValues: isReady
      ? searchData!
      : (initialHotelsValues as THotelFormValues),
    validationSchema: object().shape({
      destination: object().shape({
        name: string()
          .required(t("hotels.validation.destination"))
          .max(30, t("hotels.validation.destination")),
        id: number().required(),
      }),
      dates: object().shape({
        checkIn: date().required(t("hotels.validation.dates.checkIn")),
        checkOut: date()
          .min(ref("checkIn"), t("hotels.validation.dates.min"))
          .required(t("hotels.validation.dates.checkOut")),
      }),
      rooms: array()
        .of(
          object().shape({
            adults: number().integer().min(1),
            children: number().integer(),
            childrenAges: array()
              .of(
                number()
                  .integer()
                  .min(0, t("hotels.validation.childrenAge"))
                  .max(12, t("hotels.validation.childrenAge"))
                  .required(),
              )
              .length(ref("children"), t("hotels.validation.childrenAge")),
          }),
        )
        .min(1, t("hotels.validation.roomsMin"))
        .max(5, t("hotels.validation.roomsMax"))
        .required(),
      travelPurpose: string().required(),
    }),
    onSubmit: (values) => {
      updateSearchData({
        ...values,
        visitors: getVisitorsCount(formik.values.rooms),
      });
      navigate({ to: "/hotels/list" });
    },
  });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res)[0], 90);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const checkInValue = dayjs(formik.values.dates.checkIn)
    .format("ddd, D MMM")
    .toString();

  const checkOutValue = dayjs(formik.values.dates.checkOut)
    .format("ddd, D MMM")
    .toString();

  const travelPurposeOptions = [
    {
      value: TravelPurpose.LEISURE,
      label: t("hotels.search.leisure"),
    },
    {
      value: TravelPurpose.BUSINESS,
      label: t("hotels.search.business"),
    },
  ];

  return (
    <div className="w-full rounded-3xl p-4">
      <form noValidate onSubmit={onSubmit} acceptCharset="ISO-8859-1">
        <div className="mb-4 flex flex-col gap-2">
          <div className="relative flex flex-col gap-2">
            <TextField
              fullWidth
              value={formik.values.destination.name?.toLowerCase()}
              variant="filled"
              dir={dir}
              label={
                <div className="flex items-center gap-1">
                  <MapPinLine size={24} />
                  <span className="ml-2">{t("hotels.search.destination")}</span>
                </div>
              }
              onMouseDown={(e) => {
                e.preventDefault();
                handleDrawerOpen(EDrawerComponent.DestinationSearch);
              }}
              InputProps={{
                endAdornment: formik.values.destination.name && (
                  <button className="absolute left-3" type="button">
                    <X size={20} color="#6A778A" />
                  </button>
                ),
              }}
              sx={{
                "& input": {
                  textTransform: "capitalize !important",
                },
              }}
            />
          </div>
          <TextField
            fullWidth
            variant="filled"
            label={t("hotels.search.checkIn")}
            aria-label="check-in-date"
            dir={dir}
            value={
              locale === "ar"
                ? `${checkInValue} - ${checkOutValue}`
                : `${checkOutValue} - ${checkInValue}`
            }
            onMouseDown={(e) => {
              e.preventDefault();
              handleDrawerOpen(EDrawerComponent.CheckInSelection);
            }}
          />
          <TextField
            fullWidth
            label={t("hotels.search.visitors")}
            className="w-full p-0"
            dir={dir}
            variant="filled"
            value={t("hotels.search.visitorsSum", {
              visitorsCount: getVisitorsCount(formik.values.rooms),
              rooms: formik.values.rooms.length,
            })}
            aria-label="visitors"
            InputProps={{
              endAdornment: (
                <CaretDown
                  className={clsx(
                    "absolute",
                    dir === "ltr" ? "right-5" : "left-5",
                  )}
                  color="black"
                  size={24}
                />
              ),
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              handleDrawerOpen(EDrawerComponent.VisitorsSelection);
            }}
          />
          <TextField
            fullWidth
            id="travelPurpose"
            name="travelPurpose"
            label={t("hotels.search.travelPurpose")}
            className="w-full p-0"
            dir={dir}
            variant="filled"
            value={formik.values.travelPurpose}
            aria-label="travel-purpose"
            InputProps={{
              endAdornment: (
                <CaretDown
                  className={clsx(
                    "absolute",
                    dir === "ltr" ? "right-5" : "left-5",
                  )}
                  color="black"
                  size={24}
                />
              ),
            }}
            select
            onChange={(e) =>
              formik.setFieldValue("travelPurpose", e.target.value)
            }
            sx={{
              "& .MuiSelect-select": {
                borderRadius: "16px !important",
              },
              "& #travelPurpose": {
                paddingRight: "20px !important",
              },
              "& .MuiSvgIcon-root": {
                display: "none",
              },
            }}
          >
            {travelPurposeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button
          fullWidth
          variant="contained"
          aria-label="search-flight"
          type="submit"
          sx={{
            borderRadius: "16px",
          }}
        >
          {t("hotels.search.searchHotel")}
        </Button>
        <SwipeableDrawer
          anchor="bottom"
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          open={drawerOpen}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "32px 32px 0 0",
              height: "80vh",
              paddingBottom: "env(safe-area-inset-bottom)",
            },
          }}
        >
          <Puller />
          {drawerComponent === EDrawerComponent.DestinationSearch && (
            <DestinationSearch
              formProps={formik}
              onDrawerClose={() => setDrawerOpen(false)}
            />
          )}
          {drawerComponent === EDrawerComponent.CheckInSelection && (
            <CheckInSelection
              formProps={formik}
              onDrawerClose={() => setDrawerOpen(false)}
            />
          )}
          {drawerComponent === EDrawerComponent.VisitorsSelection && (
            <VisitorsSelection
              formProps={formik}
              onDrawerClose={() => setDrawerOpen(false)}
            />
          )}
        </SwipeableDrawer>
      </form>
    </div>
  );
}

export default HotelsCalculator;
