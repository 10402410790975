import { useState, useEffect } from "react";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

const BackwardTimer = ({ timeStamp }: { timeStamp: number }) => {
  const [timeLeft, setTimeLeft] = useState(timeStamp);
  const navigate = useNavigate();
  const t = useTranslations();

  useEffect(() => {
    if (timeLeft <= 0) {
      navigate({ to: "/flights/list" });
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, navigate]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  return (
    <p className="text-tertiary mr-auto text-xs">
      {t("flights.offerExpires")} {formatTime(timeLeft)}
    </p>
  );
};

export default BackwardTimer;
