import { Button, CircularProgress, Divider } from "@mui/material";
import type { THotelSearchResult, TVoucherDoc } from "@/app/lib/types/types";
import { useTranslations } from "use-intl";
import { HotelCard } from "../hotels-list/HotelCard";
import { ReservationNextSteps } from "../my-trips/ReservationNextSteps";
import { DownloadVoucher } from "../DownloadVoucher";
import type { TSelectedHotelRoom } from "./HotelBookingStepper";
import { HotelRoom } from "./HotelRoom";
import { useReservation } from "@/app/lib/hooks/useReservation";
import { useReservationDocuments } from "@/app/lib/hooks/useReservationDocuments";
import { useNavigate } from "@tanstack/react-router";

export const HotelBookingSuccess = ({
  hotel,
  orderId,
  rooms,
  visitors,
}: {
  hotel: THotelSearchResult;
  orderId?: number;
  rooms: TSelectedHotelRoom[];
  visitors: number;
}) => {
  const navigate = useNavigate();
  const t = useTranslations();
  const { data, isLoading } = useReservation("hotels", orderId?.toString());
  const { data: documentsData } = useReservationDocuments(
    orderId?.toString(),
    2000,
  );

  return (
    <div className="px-4">
      <div className="text-primary mb-2 p-2 text-center text-xl font-semibold">
        {t("hotels.overview.booked")}
      </div>
      <div className="text-tertiary mb-2 flex flex-row-reverse text-sm">
        {t("hotels.overview.hotelDetails")}
      </div>

      <HotelCard hotel={hotel} isSuccessScreen />

      <div className="text-primary mb-4 flex flex-col justify-end gap-4 rounded-2xl bg-white p-4 text-end">
        {rooms.map((room, i) => (
          <div className="flex w-full flex-col" key={room.roomToken}>
            <HotelRoom room={room} hotel={hotel} visitorsCount={visitors} />
            {i + 1 < rooms.length && <Divider />}
          </div>
        ))}
      </div>

      <section className="mt-3 flex w-full flex-row-reverse items-center justify-between gap-4 rounded-2xl bg-white p-4">
        <span className="text-tertiary text-xs">
          {t("reservations.bookingNumber")}
        </span>
        <span className="text-title text-base font-semibold">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            data?.reference?.external
          )}
        </span>
      </section>

      {data?.id && !documentsData?.documents.length && (
        <div className="flex flex-col items-center justify-center">
          <CircularProgress size={24} className="mb-2" />
          ...{t("preparingTickets")}
        </div>
      )}

      {data?.id &&
        documentsData?.documents?.map((doc: TVoucherDoc, index: number) => (
          <DownloadVoucher
            key={doc.link}
            doc={doc}
            dataId={data.id}
            index={index}
          />
        ))}

      <ReservationNextSteps type="hotel" />

      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        <Button
          sx={{
            textTransform: "none",
            fontSize: "14px",
            width: "240px",
            borderRadius: "8px",
            height: "40px",
          }}
          variant="contained"
          onClick={() => {
            navigate({ to: "/", search: { category: "hotels" } });
          }}
        >
          <span>{t("backToHome")}</span>
        </Button>
        <Button
          sx={{
            textTransform: "none",
            fontSize: "14px",
            width: "240px",
            borderRadius: "8px",
            height: "40px",
          }}
          onClick={() => {
            navigate({ to: "/trips" });
          }}
        >
          <span>{t("openMyTrips")}</span>
        </Button>
      </div>
    </div>
  );
};
