import { TSelectedHotelRoom } from "./HotelBookingStepper";

export const HotelRoom = ({
  room,
  hotel,
  visitorsCount,
}: {
  room: TSelectedHotelRoom;
  hotel: {
    name: string;
    mainImageUrl: string;
    imagesUrls: string[];
  };
  visitorsCount: number;
}) => {
  return (
    <div className="mb-2 flex w-full justify-between gap-4 rounded-2xl bg-[#F3F4F7] py-4 pr-1 pl-2">
      <div className="relative h-[48px] min-h-[48px] w-[60px]">
        {hotel.mainImageUrl && (
          <img
            className="z-10 min-h-12 min-w-16 rounded-lg"
            src={hotel.mainImageUrl}
            alt={hotel.name}
            width={60}
            height={48}
          />
        )}
        <div className="absolute bottom-0 left-0 z-1 h-[17px] w-[36px] rounded-bl-lg bg-black">
          <div className="flex items-center justify-center">
            <div className="text-xs text-white">{hotel.imagesUrls.length}+</div>
            <img
              className="mr-px"
              src={"/image.svg"}
              width={9}
              height={9}
              alt="image"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col px-2 text-[14px]">
        <div className="flex flex-row-reverse justify-end">{room.name}</div>
        <div className="mb-2 flex justify-end">
          {room.board} - {visitorsCount} Visitors
        </div>
        <div className="flex justify-end font-semibold">
          {room.price.currency} {room.price.value}
        </div>
      </div>
    </div>
  );
};
