"use client";

import { createContext, useContext, ReactNode } from "react";
import {
  FindFlightOffersDestination,
  GetFlightAvailabilityTokenFlight,
  GetFlightAvailabilityTokenOutputData,
  GetFlightAvailabilityTokenSegment,
} from "../types/codegen";

interface SingleFlightSelectionContextValue {
  singleFlightDetails: SingleFightDetailsData | null;
  departureFlight: GetFlightAvailabilityTokenFlight | null;
  returnFlight: GetFlightAvailabilityTokenFlight | null;
  departureSegments: GetFlightAvailabilityTokenSegment[] | null;
  returnSegments: GetFlightAvailabilityTokenSegment[] | null;
  origin: FindFlightOffersDestination | null;
  destination: FindFlightOffersDestination | null;
  isAdditionalDataLoading: boolean;
}

export interface SingleFightDetailsData
  extends Omit<
    Partial<GetFlightAvailabilityTokenOutputData>,
    "totalPrice" | "flights"
  > {
  totalPrice: string;
  flights: GetFlightAvailabilityTokenFlight[] | undefined;
}

const defaultContextValue: SingleFlightSelectionContextValue = {
  singleFlightDetails: null,
  departureFlight: null,
  returnFlight: null,
  departureSegments: null,
  returnSegments: null,
  origin: null,
  destination: null,
  isAdditionalDataLoading: false,
};

const SingleFlightSelectionContext =
  createContext<SingleFlightSelectionContextValue>(defaultContextValue);

export const SingleFlightDetailsProvider = ({
  data,
  isAdditionalDataLoading,
  children,
}: {
  data: SingleFightDetailsData;
  isAdditionalDataLoading?: boolean;
  children: ReactNode;
}) => {
  const departureFlight = data?.flights?.[0] ?? null;
  const returnFlight =
    ((data?.flights?.length || 0) > 1 &&
      data?.flights?.[data?.flights.length - 1]) ||
    null;
  const departureSegments = departureFlight?.segments || null;
  const returnSegments = returnFlight?.segments || null;
  const origin = departureFlight?.segments[0]?.departure || null;
  const destination =
    departureFlight?.segments[departureFlight?.segments.length - 1]?.arrival ||
    null;

  return (
    <>
      <SingleFlightSelectionContext.Provider
        value={{
          singleFlightDetails: data,
          departureFlight,
          departureSegments,
          returnFlight,
          returnSegments,
          origin,
          destination,
          isAdditionalDataLoading: isAdditionalDataLoading || false,
        }}
      >
        {children}
      </SingleFlightSelectionContext.Provider>
    </>
  );
};

export const useSingleFlightSelectionContext = () =>
  useContext(SingleFlightSelectionContext);
