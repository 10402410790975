import { Card, CircularProgress } from "@mui/material";
import { baggageIcons } from "@/app/lib/utils/baggageIcons";
import { useTranslations } from "use-intl";

import { processServices } from "@/app/lib/utils/flightServicesProcessor";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";

function BaggageAllowance({
  singleFlightDetails,
  isAdditionalDataLoading,
}: {
  singleFlightDetails: SingleFightDetailsData | null;
  isAdditionalDataLoading: boolean;
}) {
  const t = useTranslations();
  const processedServices = singleFlightDetails?.optionalServices
    ? processServices(singleFlightDetails)
    : null;

  const baggageExists = processedServices?.baggageServicesData?.baggageInfo;
  return (
    <div className="my-4">
      <span className="text-tertiary mb-2 block w-full text-end text-xs">
        {t("flights.baggageDetails")}
      </span>
      <Card
        dir="rtl"
        className="flex flex-col gap-4 rounded-2xl bg-white p-4"
        sx={{ boxShadow: "none" }}
      >
        {isAdditionalDataLoading && (
          <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
            {t("flights.retrievingBaggageDetails")}
            <CircularProgress size={20} />
          </div>
        )}
        {!isAdditionalDataLoading && !baggageExists && (
          <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
            {t("flights.noBaggage")}
          </div>
        )}
        {baggageExists &&
          processedServices.baggageServicesData
            .isBaggageIdenticalForAllRoutes && (
            <div className="flex w-full flex-col gap-2">
              <h3 className="text-primary text-sm font-semibold">
                {t("flights.totalBaggageAllowance")}
              </h3>
              {Object.values(
                processedServices.baggageServicesData.baggageInfo,
              ).map((service) => (
                <div
                  key={
                    service[0].service.name + service[0].service.categoryCode
                  }
                  className="text-primary flex items-center gap-2 text-sm font-normal"
                >
                  {service.length} {service[0].service.name} x
                  {baggageIcons[service[0].service.categoryCode]}
                </div>
              ))}
            </div>
          )}
      </Card>
    </div>
  );
}

export default BaggageAllowance;
