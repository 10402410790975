"use client";

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import type { IFlightsSearchContext } from "@/app/lib/types/interfaces";
import { initialFlightsValues } from "../initialFlightsValues";
import { TFlightFormValues } from "@/app/lib/types/types";

const defaultContextValue: IFlightsSearchContext = {
  searchData: initialFlightsValues,
  updateSearchData: () => {},
  isReady: false,
};

export const FlightsSearchContext =
  createContext<IFlightsSearchContext>(defaultContextValue);

export const FlightDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchData, setSearchData] =
    useState<TFlightFormValues>(initialFlightsValues);
  const [isReady, setIsReady] = useState<boolean>(false);

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("@flights-search3");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setSearchData(parsedData);
      } catch (error) {
        console.error("Failed to parse search data from localStorage:", error);
      }
    }
    setIsReady(true);
  }, []);

  // Save data to localStorage whenever searchData changes
  useEffect(() => {
    if (isReady) {
      localStorage.setItem("@flights-search3", JSON.stringify(searchData));
    }
  }, [searchData, isReady]);

  const updateSearchData = (savedData: TFlightFormValues) => {
    setSearchData(savedData);
  };

  return (
    <FlightsSearchContext.Provider
      value={{ searchData, updateSearchData, isReady }}
    >
      {children}
    </FlightsSearchContext.Provider>
  );
};

export const useFlightDetailsProvider = () => useContext(FlightsSearchContext);
