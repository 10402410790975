import useSWR from "swr";
import { postFetcher } from "@/app/lib/fetcher";
import { BASE_API_URL } from "../routes";
import { useHotelsTokensContext } from "../context/HotelsTokenProvider";
import { hotelsPostFetcher } from "./useHotelsSearch";

export const useHotelRoomAvailaility = (
  offerId: string,
  packageToken: string,
  roomTokens: string[],
  hotelId: string,
) => {
  const { tokens, setTokens } = useHotelsTokensContext();
  const { data, error, isLoading } = useSWR(
    () => {
      return offerId && roomTokens.length > 0
        ? {
            url: `${BASE_API_URL}/hotels/${offerId}/availability`,
            params: {
              searchResultKey: tokens.searchResultKey,
              searchResultToken: tokens.searchResultToken,
              packageToken,
              roomTokens,
              hotelId,
            },
          }
        : null;
    },
    hotelsPostFetcher,
    {
      onSuccess: (data) => {
        setTokens((prev) => ({
          ...prev,
          availabilityToken: data.data.availabilityToken,
        }));
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    },
  );

  return {
    data,
    isLoading,
    isError: error,
  };
};
