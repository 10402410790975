import { Devtools } from "@/app/ui/Devtools";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { IntlProvider } from "use-intl";
import dayjs from "dayjs";
import enMessages from "../../messages/en.json";
import arMessages from "../../messages/ar.json";
import { SnackbarProvider } from "@/app/lib/context/SnackbarContext";
import { queryClient } from "@/app/lib/query";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { FlightDetailsProvider } from "@/app/lib/context/FlightDetailsContext";
import { FlightSelectionPayloadProvider } from "@/app/lib/context/FlightSelectionPayloadContext";
import { HotelDataProvider } from "@/app/lib/context/HotelDataProvider";
import { HotelsDetailsProvider } from "@/app/lib/context/HotelDetailsProvider";
import { HotelsTokenProvider } from "@/app/lib/context/HotelsTokenProvider";
import { SWRConfig } from "swr";
import { isStaging } from "@/app/lib/constants";

const locale = "ar";
const messages = {
  en: enMessages,
  ar: arMessages,
};

import "dayjs/locale/ar";
dayjs.locale(locale);

export const Route = createRootRoute({
  component: () => (
    <>
      <QueryClientProvider client={queryClient}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnReconnect: true,
            revalidateOnMount: true,
            revalidateIfStale: true,
          }}
        >
          <IntlProvider locale={locale} messages={messages[locale]}>
            <SnackbarProvider>
              {/* TODO: Move this to a specific route */}
              <FlightDetailsProvider>
                <FlightSelectionPayloadProvider>
                  <HotelsDetailsProvider>
                    <HotelsTokenProvider>
                      <HotelDataProvider>
                        <Outlet />
                      </HotelDataProvider>
                    </HotelsTokenProvider>
                  </HotelsDetailsProvider>
                </FlightSelectionPayloadProvider>
              </FlightDetailsProvider>
            </SnackbarProvider>
          </IntlProvider>

          <ReactQueryDevtools initialIsOpen={false} />
        </SWRConfig>
      </QueryClientProvider>
      {isStaging ? (
        <>
          <TanStackRouterDevtools />
          <Devtools />
        </>
      ) : null}
    </>
  ),
});
