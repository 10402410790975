import type { FindUserHotelsReservationsReservation } from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";
import { ReservationPaymentDetails } from "./ReservationPaymentDetails";
import { ReservationNextSteps } from "./ReservationNextSteps";
import { DownloadVoucher } from "../DownloadVoucher";
import { CircularProgress, Divider } from "@mui/material";
import type { TVoucherDoc } from "@/app/lib/types/types";
import { MyHotelCard } from "./MyHotelCard";
import { useReservationDocuments } from "@/app/lib/hooks/useReservationDocuments";

export const MyHotel = ({
  reservation,
}: {
  reservation: FindUserHotelsReservationsReservation;
}) => {
  const t = useTranslations();

  const { data, isLoading } = useReservationDocuments(
    reservation.id?.toString(),
  );

  return (
    <>
      <MyHotelCard reservation={reservation} />

      <section className="flex w-full flex-row-reverse items-center justify-between gap-4 rounded-2xl bg-white p-4">
        <span className="text-tertiary text-xs">
          {t("reservations.bookingNumber")}
        </span>
        <span className="text-title text-base font-semibold">
          {reservation.reference.external}
        </span>
      </section>

      {isLoading ? (
        <div className="mt-6 flex w-full justify-center">
          <CircularProgress size={20} />
        </div>
      ) : (
        data?.documents?.map((doc: TVoucherDoc, index: number) => (
          <DownloadVoucher
            key={doc.link}
            doc={doc}
            dataId={reservation.id?.toString()}
            index={index}
          />
        ))
      )}

      <section>
        <h3 className="text-tertiary mt-4 mb-1 text-end text-xs">
          {t("reservations.roomDetails")}
        </h3>
        <ul className="flex w-full flex-col gap-4 rounded-2xl bg-white p-4">
          {reservation.rooms.map((room) => (
            <li
              key={room.id}
              className="text-title flex justify-between rounded-xl bg-[#F3F4F7] p-3 text-end"
            >
              <div>
                {reservation.hotel.mainImageUrl && (
                  <img
                    key={reservation.hotel.mainImageUrl}
                    src={reservation.hotel.mainImageUrl}
                    alt={reservation.hotel.name}
                    width={60}
                    height={48}
                    style={{
                      minHeight: "48px",
                      minWidth: "60px",
                      borderRadius: "8px",
                    }}
                  />
                )}
              </div>
              <div>
                {room.name} - {room.board} - {room.info}
              </div>
            </li>
          ))}
        </ul>
      </section>

      <ReservationPaymentDetails reservation={reservation} />
      <ReservationNextSteps type="hotel" />

      <div className="mb-20">
        <Divider />
      </div>
    </>
  );
};
