import { OfferIsNotAvailableAnymoreOutputDto } from "@/app/lib/types/codegen";
import { AxiosError } from "axios";
import PageError from "../PageError";
import { FlightOfferNotAvailableError } from "./FlightNotAvailableError";

function isOfferIsNotAvailableAnymoreError(
  error: Error,
): error is AxiosError<OfferIsNotAvailableAnymoreOutputDto> {
  return (
    error instanceof AxiosError &&
    !!error.response?.data &&
    typeof error.response?.data === "object" &&
    "code" in error.response.data &&
    error.response?.data?.code === "OFFER_IS_NOT_AVAILABLE_ANYMORE"
  );
}

interface FlightOfferErrorProps {
  error: Error | AxiosError<OfferIsNotAvailableAnymoreOutputDto>;
}

export const FlightOfferError = ({ error }: FlightOfferErrorProps) => {
  if (isOfferIsNotAvailableAnymoreError(error)) {
    return <FlightOfferNotAvailableError />;
  }
  return <PageError />;
};
