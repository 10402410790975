import { useState } from "react";
import useSWR from "swr";
import { BASE_API_URL } from "../routes";
import { hotelsFetcher } from "./useHotelsSearch";

export const useHotelSearchProgress = (
  searchProgressToken: string,
  searchResultKey: string,
  searchResultToken: string,
) => {
  const [searchProgressInterval, setSearchProgressInterval] =
    useState<number>(100);
  const { data, error, isLoading, isValidating } = useSWR(
    () => {
      return searchProgressToken
        ? {
            url: `${BASE_API_URL}/hotels/search/progress`,
            params: { searchProgressToken, searchResultKey, searchResultToken },
          }
        : null;
    },
    hotelsFetcher,
    {
      onSuccess: (data) => {
        if (data.data.status === "COMPLETED") {
          setSearchProgressInterval(0);
        }
        if (data.data.status === "IN_PROGRESS") {
          setSearchProgressInterval(100);
        }
      },
      revalidateIfStale: false,
      revalidateOnFocus: false,
      refreshInterval: searchProgressInterval,
    },
  );

  return {
    data,
    isLoading,
    isValidating,
    isError: error,
  };
};
