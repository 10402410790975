"use client";

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import type { IHotelsSearchContext } from "@/app/lib/types/interfaces";
import { THotelFormValues } from "../types/types";
import { initialHotelsValues } from "../initialHotelsValues";

const defaultContextValue: IHotelsSearchContext = {
  searchData: initialHotelsValues,
  updateSearchData: () => {},
  isReady: false,
};

export const HotelsSearchContext =
  createContext<IHotelsSearchContext>(defaultContextValue);

export const HotelsDetailsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchData, setSearchData] =
    useState<THotelFormValues>(initialHotelsValues);
  const [isReady, setIsReady] = useState<boolean>(false);

  // Load data from localStorage when the component mounts
  useEffect(() => {
    const storedData = localStorage.getItem("@hotels-search3");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setSearchData(parsedData);
      } catch (error) {
        console.error("Failed to parse search data from localStorage:", error);
      }
    }
    setIsReady(true);
  }, []);

  // Save data to localStorage whenever searchData changes
  useEffect(() => {
    if (isReady) {
      localStorage.setItem("@hotels-search3", JSON.stringify(searchData));
    }
  }, [searchData, isReady]);

  const updateSearchData = (savedData: THotelFormValues) => {
    setSearchData(savedData);
  };

  return (
    <HotelsSearchContext.Provider
      value={{ searchData, updateSearchData, isReady }}
    >
      {children}
    </HotelsSearchContext.Provider>
  );
};

export const useHotelsDetailsProvider = () => useContext(HotelsSearchContext);
