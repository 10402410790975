import React from "react";
import { IconButton } from "@mui/material";
import {
  X,
  ArrowRight,
  ArrowsLeftRight,
  ArrowLeft,
} from "@phosphor-icons/react/dist/ssr";
import { TripType } from "@/app/lib/types/enums";
import type { TFlightFormValues } from "@/app/lib/types/interfaces";

const Header = ({
  searchData,
  onCancel,
  onBack,
}: {
  searchData: TFlightFormValues;
  onCancel: () => void;
  onBack: () => void;
}) => (
  <div className="align-center flex justify-between p-2">
    <IconButton onClick={onCancel}>
      <X size={24} color="text-title" />
    </IconButton>
    <div className="flex items-center justify-center gap-2">
      <h3 className="text-title font-medium">{searchData.to.city}</h3>
      {searchData.tripType === TripType.ROUND_TRIP ? (
        <ArrowsLeftRight size={16} color="#0966D7" />
      ) : (
        <ArrowLeft size={16} color="#0966D7" />
      )}
      <h3 className="text-title font-medium">{searchData.from.city}</h3>
    </div>
    <IconButton onClick={onBack}>
      <ArrowRight size={24} color="text-title" />
    </IconButton>
  </div>
);

export default Header;
