import { MobileStepper } from "@mui/material";
import { useTranslations } from "use-intl";
import BackwardTimer from "@/app/ui/BackwardTimer";

const Stepper = ({
  step,
  stepNumber,
  stepTitle,
  offerExpirationTime,
}: {
  step: number;
  stepNumber: number;
  stepTitle: string;
  offerExpirationTime: number | null;
}) => {
  const t = useTranslations();

  return (
    <div className="px-4">
      <MobileStepper
        variant="progress"
        steps={4}
        position="static"
        activeStep={step}
        sx={{
          width: "100%",
          float: "right",
          backgroundColor: "transparent",
          "& .MuiLinearProgress-root": {
            width: "100%",
            background: "#E7E9EE",
          },
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#EF9C43",
          },
        }}
        nextButton={null}
        backButton={null}
      />
      <div className="mb-4 flex w-full items-center justify-end gap-2">
        {offerExpirationTime && (
          <BackwardTimer timeStamp={offerExpirationTime} />
        )}
        <span className="text-tertiary text-xs">
          {t("flights.step")} {stepNumber}/3
        </span>
        <h5 className="text-title text-xs font-semibold">{t(stepTitle)}</h5>
      </div>
    </div>
  );
};

export default Stepper;
