import { FindUserHotelsReservationsReservation } from "@/app/lib/types/codegen";
import { HotelCard } from "../hotels-list/HotelCard";
import { Bed, CalendarBlank, User } from "@phosphor-icons/react";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";

export function MyHotelCard({
  reservation,
  onClick,
}: {
  reservation: FindUserHotelsReservationsReservation;
  onClick?: () => void;
}) {
  const t = useTranslations();
  const children = reservation.travelers.filter(
    (traveler) => traveler.type === "child",
  );

  const adults = reservation.travelers.filter(
    (traveler) => traveler.type === "adult",
  );

  return (
    <HotelCard
      onClick={onClick}
      hotel={{
        ...reservation.hotel,
        boardBasis: reservation.rooms
          .map(({ boardBasis }) => boardBasis)
          .flat(),
      }}
      additionalInfo={
        <>
          <ul className="flex flex-col gap-2">
            <li className="text-tertiary flex flex-row-reverse items-center justify-start gap-2 text-sm">
              <Bed className="fill-tertiary" size={18} />
              <span>
                {t("reservations.hotelRooms", {
                  count: reservation.rooms.length,
                })}
              </span>
            </li>
            <li className="text-tertiary flex flex-row-reverse items-center justify-start gap-2 text-sm">
              <CalendarBlank className="fill-tertiary" size={18} />
              <span>
                {dayjs(reservation.serviceDates.startDate).format(
                  "ddd, DD MMMM",
                )}{" "}
                -{" "}
                {dayjs(reservation.serviceDates.endDate).format("ddd, DD MMMM")}
              </span>
            </li>
            <li className="text-tertiary flex flex-row-reverse items-center justify-start gap-2 text-sm">
              <User className="fill-tertiary" size={18} />
              <span>
                {children.length > 0 ? (
                  <>{t("reservations.children", { count: children.length })} </>
                ) : null}
                {t("reservations.adults", { count: adults.length })}
              </span>
            </li>
          </ul>
        </>
      }
    />
  );
}
