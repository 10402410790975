import clsx from "clsx";
import { useState } from "react";
import { SortOrder } from "@/app/lib/types/enums";
import { ChartBar, Check } from "@phosphor-icons/react/dist/ssr";
import { ToggleButton, ToggleButtonGroup, Button } from "@mui/material";
import { useTranslations } from "use-intl";

function FlightsSort({
  sortValue,
  setSortValue,
}: {
  sortValue: SortOrder;
  setSortValue: (value: SortOrder) => void;
}) {
  const [localSortValue, setLocalSortValue] = useState<SortOrder>(sortValue);
  const t = useTranslations();

  const sortOptionsLabels = {
    [SortOrder.ASC]: (
      <span className="text-primary inline-flex items-center gap-4 text-base">
        {t("flights.cheapFirst")} <ChartBar size={16} className="rotate-90" />
      </span>
    ),
    [SortOrder.DESC]: (
      <span className="text-primary inline-flex items-center gap-4 text-base">
        {t("flights.expensiveFirst")}
        <ChartBar size={16} style={{ transform: "rotate(90deg) scaleX(-1)" }} />
      </span>
    ),
  };

  return (
    <>
      <div className="relative w-full">
        <ToggleButtonGroup
          className="flex w-full flex-col"
          exclusive
          size="small"
          color="primary"
          aria-label="sort options"
        >
          {Object.values(SortOrder).map((option) => (
            <ToggleButton
              onChange={() => setLocalSortValue(option)}
              sx={{
                borderTop: "none",
                justifyContent: "flex-end",
                textTransform: "unset",
                padding: "16px",
              }}
              key={option}
              value={option}
              aria-label={option}
            >
              <div
                className={clsx(
                  "flex w-full",
                  option === localSortValue ? "justify-between" : "justify-end",
                )}
              >
                {option === localSortValue && (
                  <Check size={20} color="#0966D7" />
                )}
                {sortOptionsLabels[option]}
              </div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <div className="mt-auto flex flex-col items-center p-4">
        <Button
          onClick={() => {
            setSortValue(localSortValue);
          }}
          sx={{
            padding: "12px",
            borderRadius: "16px",
            textTransform: "unset",
            backgroundColor: "#0966D7",
          }}
          variant="contained"
          className="w-full rounded-2xl"
        >
          {t("flights.done")}
        </Button>
      </div>
    </>
  );
}

export default FlightsSort;
