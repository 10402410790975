import { CabinClass, TripType } from "./types/enums";
import { TFlightFormValues } from "./types/types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const initialFlightsValues: TFlightFormValues = {
  tripType: TripType.ONE_WAY,
  from: {
    name: "",
    code: "",
    id: 0,
    city: "",
  },
  to: {
    name: "",
    code: "",
    id: 0,
    city: "",
  },
  dates: {
    departure: dayjs(
      new Date().toISOString(),
      "YYYY-MM-DDTHH:mm:ss.000ZZ",
    ).format(),
    return: dayjs(
      new Date().toISOString(),
      "YYYY-MM-DDTHH:mm:ss.000ZZ",
    ).format(),
  },
  cabinClass: CabinClass.ECONOMY,
  adult: 1,
  child: 0,
  infantWithoutSeat: 0,
  travellers: "1 Traveller",
};
