"use client";

import { Button, Drawer } from "@mui/material";
import { useState, useEffect } from "react";
import { HotelOverview } from "../hotel/HotelOverview";
import { TravellerList } from "../hotel/TravellerList";

import { HotelRoomSelection } from "../hotel/HotelRoomSelection";
import { HotelBookingSummary } from "../hotel/HotelBookingSummary";
import { useHotelsTokensContext } from "@/app/lib/context/HotelsTokenProvider";
import { useHotelOffer } from "@/app/lib/hooks/useHotelOffer";
import { useLocalStorage } from "@/app/lib/hooks/useLocalStorage";
import { useHotelDataProvider } from "@/app/lib/context/HotelDataProvider";
import Loader from "../AnimatedLoader";
import { HotelBookingSuccess } from "./HotelBookingSuccess";
import clsx from "clsx";
import { HotelBookingHeader } from "./HotelBookingHeader";
import type { THotelRoom } from "@/app/lib/types/types";
import { HotelRoomTitle } from "./HotelRoomTitle";
import { HotelBookingRoomGallery } from "./HotelBookingRoomGallery";
import { useTranslations } from "use-intl";
import dayjs from "dayjs";
import { useHotelsDetailsProvider } from "@/app/lib/context/HotelDetailsProvider";
import { useTravellersContext } from "@/app/lib/context";
import type { Traveller } from "@/app/lib/types/interfaces";
import CancelHotelBooking from "./CancelHotelBooking";
import { WhatsAppButton } from "../WhatsAppButton";
import { CaretLeft } from "@phosphor-icons/react";
import { useNavigate } from "@tanstack/react-router";

const STEPS = [
  "Hotel Description",
  "Room Selection",
  "Travellers",
  "Summary",
  "Success",
];

export type TSelectedHotelRoom = {
  board: string;
  boardBasis: string;
  name: string;
  price: { value: number; currency: string };
  roomToken: string;
  travellers: Traveller[];
};

export type THotelBookingData = {
  offerId: string;
  userId: string;
  offerPrice: number;
  hotelId: string;
  rooms: TSelectedHotelRoom[];
  packageToken: string;
};

export const HotelBookingStepper = ({ step = 1 }: { step?: number }) => {
  const {
    tokens: { searchResultKey, searchResultToken },
  } = useHotelsTokensContext();
  const t = useTranslations();
  const { selectedTravellers } = useTravellersContext();

  const navigate = useNavigate();
  const { hotelData } = useHotelDataProvider();

  const [hotelBookingData, setHotelBookingData] =
    useLocalStorage<THotelBookingData>("hotelBookingData", {
      offerId: "",
      offerPrice: 0,
      userId: "12345678",
      hotelId: hotelData.id,
      rooms: [],
      packageToken: "",
    });

  const { data: offerData } = useHotelOffer(
    hotelData.id,
    searchResultKey,
    searchResultToken,
  );
  const { searchData } = useHotelsDetailsProvider();
  const [orderId, setOrderId] = useState<number | undefined>();
  const [isCancelBookingOpen, setIsCancelBookingOpen] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  const handleCancelClick = () => {
    setIsCancelBookingOpen(true);
  };

  useEffect(() => {
    if (step === 1) {
      setHotelBookingData({
        offerId: "",
        offerPrice: 0,
        userId: "12345678",
        hotelId: hotelData.id,
        rooms: [],
        packageToken: "",
      });
    }
  }, [step, setHotelBookingData, hotelData.id]);
  const [roomGallery, setRoomGallery] = useState<THotelRoom | null>(null);
  const isRoomGallery = !!roomGallery;

  const navigateToStep = (newStep: number) => {
    navigate({
      to: "/hotels/$id/$step",
      params: { id: hotelData.id, step: `${newStep}` },
    });
  };

  const handleBackClick = () => {
    if (step <= 1) {
      navigate({
        to: "/hotels/list",
      });
      return;
    }
    navigate({
      to: "/hotels/$id/$step",
      params: { id: hotelData.id, step: `${step - 1}` },
    });
  };

  const stepNames = [
    t("hotels.title"),
    t("hotels.chooseRoom"),
    t("travellers.title"),
    t("summary"),
  ];

  if (isRoomGallery) {
    return (
      <div className="h-full w-full overflow-scroll overscroll-none">
        <div className="sticky top-0 z-10 flex w-full flex-row-reverse rounded-b-2xl bg-slate-100">
          <HotelBookingHeader
            onBackClick={() => {
              setRoomGallery(null);
            }}
            title={
              <div className="flex items-center justify-center gap-2">
                <h3 className="text-title font-medium">
                  <HotelRoomTitle {...roomGallery} />
                </h3>
              </div>
            }
          />
        </div>
        <HotelBookingRoomGallery {...roomGallery} />
      </div>
    );
  }

  return (
    <div className="w-full" style={{ direction: "rtl" }}>
      <CancelHotelBooking
        open={isCancelBookingOpen}
        closeDrawer={() => setIsCancelBookingOpen(false)}
      />
      <div className="flex w-full flex-row-reverse">
        {step >= 5 || isBooking ? null : (
          <HotelBookingHeader
            onBackClick={handleBackClick}
            onCloseClick={handleCancelClick}
            step={step}
            title={
              <div className="flex flex-col items-center justify-center gap-2">
                <div className="flex w-full items-center justify-center gap-2">
                  <h3 className="text-title font-medium">
                    {stepNames[step - 1]}
                  </h3>
                </div>
                <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
                  {dayjs(searchData!.dates.checkIn).format("ddd, DD MMM")} -
                  {dayjs(searchData!.dates.checkOut).format("ddd, DD MMM")},
                  <span>
                    {searchData!.rooms.length > 1
                      ? t("hotels.search.rooms")
                      : t("hotels.search.room")}{" "}
                    {searchData!.rooms.length}
                  </span>
                </span>
              </div>
            }
            showStepper
          />
        )}
      </div>
      <div className={clsx("flex w-full flex-col")}>
        <WhatsAppButton bottomPosition={step > 2 ? "40vh" : "30vh"} />
        {step === 1 &&
          (offerData?.status === "success" ? (
            <HotelOverview
              hotel={hotelData}
              canBook={!!offerData?.data?.length}
            />
          ) : (
            <div className="flex h-full flex-col items-center justify-center gap-3">
              <Loader icon="buildings" />
            </div>
          ))}
        {step === 2 && (
          <HotelRoomSelection
            hotel={hotelData}
            offerData={offerData}
            setHotelBookingData={setHotelBookingData}
            hotelBookingData={hotelBookingData}
            onRoomGalleryClick={setRoomGallery}
          />
        )}
        {step === 3 && <TravellerList />}
        {step === 4 && (
          <HotelBookingSummary
            hotel={hotelData}
            hotelBookingData={hotelBookingData}
            setStep={navigateToStep}
            setOrderId={setOrderId}
            setIsBooking={setIsBooking}
          />
        )}
        {step === 5 && (
          <HotelBookingSuccess
            hotel={hotelData}
            rooms={hotelBookingData.rooms}
            orderId={orderId}
            visitors={selectedTravellers.length}
          />
        )}
      </div>
      {step === 2 && (
        <Drawer
          anchor="bottom"
          variant="persistent"
          open={true}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "32px 32px 0 0",
              paddingBottom: "10px",
              minHeight: "20vh",
            },
          }}
        >
          <div className="flex h-full flex-col gap-4 p-4">
            <div className="flex items-center justify-between">
              <span className="text-primary text-md flex items-center font-semibold">
                <CaretLeft color="#6A778A" size={16} />
                {hotelBookingData?.offerPrice} {hotelData.minPrice?.currency}
              </span>
              <h5 className="text-primary text-sm font-semibold">
                {t("hotels.totalPrice")}
              </h5>
            </div>
            <Button
              disabled={
                !offerData?.data[0]?.offerId ||
                hotelBookingData.offerId !== offerData?.data[0]?.offerId ||
                searchData!.rooms.length !== hotelBookingData.rooms.length
              }
              variant="contained"
              className="mt-auto w-full font-semibold text-white"
              onClick={() => {
                navigateToStep(step < STEPS.length ? step + 1 : step);
              }}
              sx={{
                background: "#0966D7",
                textTransform: "none",
                padding: "12px",
                borderRadius: "12px",
                marginBottom: "8px",
                "&:disabled": {
                  color: "white",
                },
              }}
            >
              {t("hotels.search.bookNow")}
            </Button>
          </div>
        </Drawer>
      )}
      {(step === 1 || step === 3) && (
        <Drawer
          anchor="bottom"
          variant="persistent"
          open={true}
          sx={{
            "& .MuiDrawer-paper": {
              borderRadius: "32px 32px 0 0",
              paddingBottom: "10px",
              minHeight: "20vh",
            },
          }}
        >
          <div className="flex h-full flex-col gap-4 p-4">
            {step === 3 && (
              <div className="flex items-center justify-between">
                <span className="text-primary text-md flex items-center font-semibold">
                  <CaretLeft color="#6A778A" size={16} />
                  {hotelBookingData?.offerPrice}{" "}
                  {offerData?.data[0]?.price?.currency}
                </span>
                <h5 className="text-primary text-sm font-semibold">
                  {t("hotels.totalPrice")}
                </h5>
              </div>
            )}
            <Button
              disabled={
                step === 3
                  ? selectedTravellers.length !== searchData!.visitors
                  : offerData?.data?.length === 0
              }
              variant="contained"
              className="mt-auto w-full font-semibold text-white"
              onClick={() => {
                navigateToStep(step < STEPS.length ? step + 1 : step);
              }}
              sx={{
                background: "#0966D7",
                textTransform: "none",
                padding: "12px",
                borderRadius: "12px",
                marginBottom: "8px",
                "&:disabled": {
                  color: "white",
                },
              }}
            >
              {step === 1 ? t("hotels.chooseRoom") : t("hotels.continue")}
            </Button>
          </div>
        </Drawer>
      )}
    </div>
  );
};
