import { useTranslations } from "use-intl";

export function getFormikErrorsRecursive(
  formikErrors: any,
  t?: ReturnType<typeof useTranslations>,
) {
  const errors: string[] = [];
  Object.keys(formikErrors).forEach((key) => {
    if (typeof formikErrors[key] === "object") {
      errors.push(...getFormikErrorsRecursive(formikErrors[key], t));
    } else {
      errors.push(t ? t(formikErrors[key]) : formikErrors[key]);
    }
  });
  return errors;
}
