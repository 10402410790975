import useSWR from "swr";
import useDebounce from "./useDebounce";
import api from "../api/api";

const fetcher = ({ url, params }: any) => {
  return api
    .get(url, {
      params,
    })
    .then((res) => {
      return res.data;
    });
};

// Custom hook for airport search
export function useAirportSearch(searchQuery: string) {
  const debouncedSearch = useDebounce(searchQuery, 400);
  // Use SWR with an object as the key
  const { data, error, isLoading, mutate } = useSWR(
    () => {
      return debouncedSearch.length > 2 && debouncedSearch
        ? {
            url: `/mappings/cities/airports`,
            params: { searchQuery: debouncedSearch },
          }
        : null;
    },
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  return {
    data: data?.data || [],
    mutate,
    isLoading,
    isError: error,
  };
}
