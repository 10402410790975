import { Bug } from "@phosphor-icons/react";
import clsx from "clsx";
import { useState, useSyncExternalStore } from "react";
import { LogEntry, LogType, store } from "../lib/console";

const titleVariants = {
  log: "text-slate-500",
  error: "text-red-700",
  warn: "text-yellow-700",
  info: "text-sky-700",
} satisfies Record<LogType, string>;

export function Devtools() {
  const stackSize = useSyncExternalStore(
    (cb) => store.subscribe(cb),
    () => store.getStackSize(),
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen ? (
        <div className="fixed inset-x-0 top-1/2 bottom-0 z-10000 overflow-auto bg-slate-900 pb-10">
          <Console />
        </div>
      ) : null}
      <button
        type="button"
        onClick={() => setIsOpen((old) => !old)}
        className="fixed right-17 bottom-3 z-10000 cursor-pointer rounded-full border-2 border-slate-600 bg-slate-900 p-1 text-4xl text-white"
      >
        <Bug />
        {stackSize > 0 ? (
          <span className="absolute -right-1.5 -bottom-1.5 flex size-5 items-center justify-center rounded-full bg-red-800 text-xs">
            {stackSize}
          </span>
        ) : null}
      </button>
    </>
  );
}

function Console() {
  const stack = useSyncExternalStore(
    (cb) => store.subscribe(cb),
    () => store.getStack(),
  );
  return (
    <>
      {stack.map((entry, index) => {
        return <ConsoleRow key={index} {...entry} />;
      })}
    </>
  );
}

function ConsoleRow({ type, args, trace, timestamp }: LogEntry) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <button
      type="button"
      className="block w-full cursor-pointer border-b border-slate-800 p-2 text-left"
      onClick={() => setIsOpen((old) => !old)}
    >
      <div className={clsx("text-xs font-bold", titleVariants[type])}>
        {new Date(timestamp).toISOString()} {type}
      </div>
      <div className="font-mono text-sm text-slate-200">
        &gt; {args.map((arg) => JSON.stringify(arg)).join(" ")}
      </div>
      {isOpen ? (
        <pre className="font-mono text-xs text-slate-500">{trace}</pre>
      ) : null}
    </button>
  );
}
