"use client";
import { useHotelDataProvider } from "@/app/lib/context/HotelDataProvider";
import { THotelRoom } from "@/app/lib/types/types";
import { IconButton } from "@mui/material";
import { ArrowRight } from "@phosphor-icons/react";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

interface GalleryOverlayProps {
  imagesUrls: string[];
  hotelName: string;
  initialIndex: number;
  onClose: () => void;
}

export const GalleryOverlay = ({
  hotelName,
  imagesUrls,
  initialIndex,
  onClose,
}: GalleryOverlayProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(initialIndex + 1);

  useLayoutEffect(() => {
    if (ref.current === null) return;

    ref.current.scrollLeft = ref.current.clientWidth * initialIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = () => {
    if (ref.current === null) return;

    const scrollLeft = ref.current.scrollLeft;
    const index = Math.round(scrollLeft / ref.current.clientWidth) + 1;

    setIndex(index);
  };

  return (
    <div className="absolute inset-0 z-50 flex flex-col bg-black">
      <div className="flex items-center justify-between gap-2 p-4">
        <div />
        <div>
          {imagesUrls.length}/{index}
        </div>
        <IconButton sx={{ color: "white" }} onClick={onClose}>
          <ArrowRight size={24} color="currentColor" />
        </IconButton>
      </div>
      <div
        onScroll={handleScroll}
        ref={ref}
        className="flex h-full w-full snap-x snap-mandatory flex-nowrap overflow-scroll"
      >
        {imagesUrls.map((url: string) => (
          <div
            key={url}
            className="relative flex h-full w-full flex-shrink-0 flex-grow-0 snap-center items-center justify-center"
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={url}
              alt={hotelName}
              loading="lazy"
              className="mx-4 w-full rounded-lg object-contain object-center"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

interface HotelBookingRoomGalleryProps extends THotelRoom {}

export const HotelBookingRoomGallery = ({}: HotelBookingRoomGalleryProps) => {
  const { hotelData } = useHotelDataProvider();
  const navigate = useNavigate();

  return (
    <>
      <div className="grid w-full grid-cols-2 gap-2">
        {hotelData.imagesUrls.map((url: string, index: number) => (
          <button
            key={url}
            type="button"
            className="relative h-32"
            onClick={() => {
              navigate({
                from: "/hotels/$id/images",
                to: "/hotels/$id/fullscreen-gallery",
                search: { index },
              });
            }}
          >
            <img
              src={url}
              alt={hotelData.name}
              loading="lazy"
              className="absolute inset-0 rounded-lg object-cover object-center"
            />
          </button>
        ))}
      </div>
    </>
  );
};
